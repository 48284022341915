import { PropaneSharp } from '@mui/icons-material'
import React from 'react'

const DownloadIcon = (props) => {
    const {colour="#006FBE",width="24px",height="24px"} = props
  return (
   
    <svg style={{verticalAlign:"middle",...props?.customStyle}} width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>web/dd/svg/download</title>
        <g id="web/dd/svg/download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(3.1, 4.1)" fill={colour} fill-rule="nonzero">
                <path 
                    d="M17.000045,8.88888889 C17.442123,8.88888889 17.8,9.24236537 17.8,9.67777778 L17.8,13.2333333 C17.8,14.6499396 16.6356346,15.8 15.200135,15.8 L2.60076496,15.8 C1.16542453,15.8 0.000899965846,14.6500077 0.000900242988,13.2340485 L0,9.67777778 C0,9.24236535 0.357877012,8.88888889 0.799955002,8.88888889 C1.24203299,8.88888889 1.59991,9.24236535 1.59991,9.67777778 L1.59998453,10.5169516 C1.59999632,10.5962608 1.60000934,10.677188 1.60002348,10.7591886 L1.60080999,13.2334147 C1.60080996,13.7790765 2.04947262,14.2222222 2.60076496,14.2222222 L15.200135,14.2222222 C15.7514747,14.2222222 16.20009,13.7791185 16.20009,13.2333333 L16.20009,9.67777778 C16.20009,9.24236537 16.557967,8.88888889 17.000045,8.88888889 Z M9.11886906,0 C9.56094702,0 9.91882406,0.353476478 9.91882406,0.788888889 L9.91882406,9.30958795 L10.089096,9.38073469 L12.3709258,7.12696294 C12.6834301,6.81830124 13.1906062,6.81830124 13.5031105,7.12696294 C13.8149274,7.43494558 13.8149274,7.93376553 13.5031105,8.24174817 L9.68496146,12.0129482 C9.37245966,12.3216073 8.86528878,12.3216102 8.55278338,12.0129547 L4.73454425,8.24175475 C4.42272374,7.93377574 4.42271779,7.43495578 4.73453093,7.12696952 C5.04703157,6.8183042 5.55420765,6.81829829 5.86671569,7.12695637 L8.14864299,9.38077128 L8.31891405,9.30962371 L8.31891405,0.788888889 C8.31891405,0.353476478 8.67679109,0 9.11886906,0 Z"
                    id="Combined-Shape">

                </path>
            </g>
        </g>
    </svg>
  )
}

export default DownloadIcon