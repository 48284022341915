// import React from "react"

// const CustomeSlecter = ({ data, title, width, value, onChange }) => {
//   return (
//     <div>
//       <label>{title}</label>
//       <select style={{ width }} value={value} onChange={onChange}>
//         <option value="">Select {title}</option>
//         {data.map(option => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   )
// }

// export default CustomeSlecter

import React from "react"
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import arrow from "../../../assets/image/svg/dropdown.svg"

const CustomeSlecter = ({ data, title, lableTitle, selectSx = {}, formSx = {}, width, value, onChange }) => {
  return (
    <FormControl sx={{ minWidth: width, boxShadow: "none", ...formSx }}>
      {lableTitle && (
        <InputLabel shrink sx={{ backgroundColor: "white" }}>{lableTitle}</InputLabel>

      )}
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        sx={{
          width,
          fontSize: "14px",
          colors: "#363636",
          fontFamily: "var(--font-Medium)",
          // "& .MuiOutlinedInput-notchedOutline": {
          //   border: "none", // Remove border
          // },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   border: "none", // Remove border when focused
          // },
          // "&:hover .MuiOutlinedInput-notchedOutline": {
          //   border: "none", // Remove border on hover
          // },
          boxShadow: "none", // Remove box-shadow
          ...selectSx
        }}
        IconComponent={props => (
          <img
            src={arrow}
            alt="arrow"
            style={{ width: "12px", marginRight: "1rem" }}
          />
        )}
      >
        <MenuItem value="">
          <span>{title}</span>
        </MenuItem>
        {data.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomeSlecter
