import React from "react"
import RaiseComplaintMain from "../../features/RaiseComplaint/RaiseComplaint"

const RaiseComplaint = () => {
  return (
    <div>
      <RaiseComplaintMain />
    </div>
  )
}

export default RaiseComplaint
