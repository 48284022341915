import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const DealerProductOrdersServices = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();


    const FetchAvailableDealers = (type) =>
        useApiHttp({
            url: `marketing/dealer-dropdown`,
            method: "GET",
        });

    const FetchAvailableVerticals = (type) =>
        useApiHttp({
            url: `marketing/vertical-list`,
            method: "GET",
        });

    const FetchProductStatus = (type) =>
        useApiHttp({
            url: `marketing/order-status-list`,
            method: "GET",
        });
    const FetchProductWiseLists = (type) =>
        useApiHttp({
            url: `marketing/dealer-order-products-list`,
            method: "POST",
        });



    const DownloadTable = () =>
        useApiHttp({
            url: `marketing/dealer-order-products-download`,
            method: "POST",
            responseType: "binary",

        });



    return {

        FetchAvailableDealers,
        FetchAvailableVerticals,
        FetchProductStatus,
        FetchProductWiseLists,
        DownloadTable
    }

}

export default DealerProductOrdersServices