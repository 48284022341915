import './globalStyles/index.css'
import { Provider } from 'react-redux';
import store from './modules/dealer/store/reduxStore';
import Dealers from './routes/Dealers';
import { ToastContainer, toast } from "react-toastify"
import Marketing from './routes/Marketing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react';
import RsizewindowAlert from './globalcomponents/RsizewindowAlert';

const queryClient = new QueryClient()

// const routes = createBrowserRouter([...dealerRoutes,...marketRoutes]);

function App() {
  const pathName = window.location.pathname;
  const isOperationsPath = pathName.includes('operations')
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 995)

  useEffect(() => {
    // Function to handle screen resize
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 995)
    }

    // Add resize event listener
    window.addEventListener("resize", handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <ToastContainer stacked autoClose={3000} />
      {isScreenSmall ? (
        <RsizewindowAlert/>
      ) : (
        isOperationsPath?<Marketing /> : <QueryClientProvider client={queryClient}><Dealers /></QueryClientProvider>
      )}
      
    
    </>
  )
}

export default App;
