import React, { useState, useEffect } from 'react'
import classes from './complaintManagement.module.css'
import ComplaintServices from './complaints.service'
import { Button, Divider, Paper, Stack } from '@mui/material';
import CustomeSlecter from '../../components/UI/Dropdown/CustomeSlecter';
import CustomButton from '../../components/UI/Button/Button';
import FromToDatePicker from '../../../marketing/components/UI/DatePicker/FromToDatePicker';
import dayjs from "dayjs"
import CustomDataGrid from '../../components/UI/customDataGrid/CustomDataGrid';
import styled from "styled-components"
import { useNavigate } from 'react-router-dom';


const StatusWidgetLable = styled.div.attrs(props => ({ className: classes[props.class] }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-regular);
color:#FFFFFF;
line-height:14px;
font-size:14px;
padding: 4px 10px;
border-radius: 3px;
`

const ComplaintManagement = () => {
    const {
        FetchOngoingComplaints,
        FetchClosedComplaints,
        DownloadTable
    } = ComplaintServices();
    const [activeOrders, setActiveOrders] = useState("ongoing");
    const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [hasValueChanged, sethasValueChanged] = useState(false);
    const [generateTable, setgenerateTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState({
        selectedVal: "",
        status_list: [
            // { label: "Created", value: "0" },
            { label: "Received", value: "1" },
            { label: "Under Investigation", value: "2" }
        ]
    });
    const navigate = useNavigate();

    // useEffect(() => {
    //     sethasValueChanged(true);
    //   }, [selectedStatus.selectedVal, ]);
    useEffect(() => {
        setgenerateTable((prev) => !prev)


    }, [activeOrders])


    const handleFromDateChange = value => {
        const newFromDate = dayjs(value).format("YYYY-MM-DD")
        setFromDate(newFromDate)

        // Automatically set "toDate" to be the same or after "fromDate"
        if (dayjs(newFromDate).isAfter(toDate)) {
            setToDate(newFromDate)
        }
    }

    const handleToDateChange = value => {
        const newToDate = dayjs(value).format("YYYY-MM-DD")
        setToDate(newToDate)
    }

    const handleGenerateClick = () => {
        // if (hasValueChanged) {
        setgenerateTable((prev) => !prev)
        // sethasValueChanged(false);
        // }
    }

    let onGoingTypoGraphy = [
        { type: "title", children: "Ongoing Complaints List" },
        { type: "caption", children: "complaints" }
    ];
    let closedComplaintsTypoGraphy = [
        { type: "title", children: "Closed Complaints List" },
        { type: "caption", children: "Complaints" }
    ]

    const moveToDetails = (complaintTok) => {
        navigate(`/operations/complaints/${complaintTok}`);
    }

    const columnData = [
        {
            field: "slno",
            headerName: "S.No",
            sortable: false,
            width: 70,
        },
        {
            field: "complaint_id",
            headerName: "Complaint ID",
            sortable: true,
            width: 150,
            renderCell: (params) => (
                <span onClick={() => moveToDetails(params?.row?.token)} className={classes.product_code_text}>
                    {params.formattedValue}
                </span>
            )
        },
        {
            field: "dealer_id",
            headerName: "Dealer ID",
            sortable: true,
            width: 350,
        },
        {
            field: "dealer_name",
            headerName: "Dealer Name",
            sortable: false,
            width: 150,
        },
        {
            field: "contact_number",
            headerName: "Contact Number",
            sortable: false,
            width: 200,
        },
        {
            field: "date_time",
            headerName: "Date and Time",
            sortable: true,
            width: 200,
            // renderCell: (params) => {
            //   const parsedDate = new Date(params?.row.applied_date);
            //   const day = parsedDate.getDate().toString().padStart(2, "0");
            //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
            //   const year = parsedDate.getFullYear();
            //   return `${day}/${month}/${year}`;
            // },
            // renderCell: (params) => dateConvert(params?.row.applied_date),
        },
        {
            field: "status",
            headerName: "Status",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                let status = params?.value === 'Under Investigation' ? 'status_under_investigation' : `status_${params?.value?.toLowerCase()}`;
                return (
                    <StatusWidgetLable class={status}>
                        {params.value}
                    </StatusWidgetLable>
                )
            }
        },
    ]


    return (
        <>
            <Stack>
                <h6 className={classes.header_text_class}>Complaint Management</h6>
                <Divider sx={{ marginTop: "16px", marginBottom: "35px" }} />
            </Stack>
            <div className={classes.button_container}>
                <Button
                    onClick={() => {
                        if (activeOrders !== "ongoing") {
                            setSelectedStatus((curr) => ({ ...curr, selectedVal: "" }))
                            setFromDate(dayjs().format("YYYY-MM-DD"))
                            setToDate(dayjs().format("YYYY-MM-DD"))
                            setActiveOrders("ongoing")
                        }
                    }}
                    className={activeOrders === "ongoing" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Ongoing Complaints
                </Button>
                <Button
                    onClick={() => {
                        if (activeOrders !== "closed") {
                            setFromDate(dayjs().format("YYYY-MM-DD"))
                            setToDate(dayjs().format("YYYY-MM-DD"))
                            setActiveOrders("closed")
                        }
                    }}
                    className={activeOrders === "closed" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Closed Complaints
                </Button>
            </div>
            <Stack direction={"row"} gap={"10px"} width={"70%"} mt={5} justifyContent={"flex-start"} flexWrap={"wrap"}>
                <FromToDatePicker
                    label={"From Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(fromDate) || null}
                    height={"50px"}
                    onChange={handleFromDateChange}
                />
                <FromToDatePicker
                    label={"To Date"}
                    format="DD-MM-YYYY"
                    height={"50px"}
                    value={dayjs(toDate)}
                    minDate={dayjs(fromDate)}
                    onChange={handleToDateChange}
                    disabled={!fromDate}
                />
                {activeOrders === 'ongoing' && (
                    <CustomeSlecter
                        data={selectedStatus.status_list}
                        title={"Choose Status"}
                        width={180}
                        value={selectedStatus.selectedVal}
                        selectSx={{
                            borderRadius: "10px"
                        }}
                        onChange={e => setSelectedStatus((curr) => ({ ...curr, selectedVal: e.target.value }))}

                    />
                )}

                <CustomButton
                    customColor="white"
                    customBgColor="#376FD0"
                    custmstyle={{
                        width: "170px",
                        minWidth: "20%",
                        borderRadius: "8px"
                    }}
                    onClick={handleGenerateClick}
                >
                    Generate
                </CustomButton>
            </Stack>
            <Paper sx={{ width: '100%', mt: 3 }}>
                {activeOrders === 'ongoing' && (
                    <CustomDataGrid
                        adornmentType={["search", "download_menu"]}
                        // {...(activeOrders === 'ongoing' && { otherApiRequests: () => DownloadTable() })}
                        otherApiRequests={() => DownloadTable()}
                        postAPI={true}
                        pagination
                        ApiRequestBody={{
                            "from_date": fromDate,
                            "to_date": toDate,
                            "status": selectedStatus.selectedVal
                        }}
                        columns={columnData}
                        ApiRequest={() => FetchOngoingComplaints()}
                        typography={onGoingTypoGraphy}
                        isStatusChanged={generateTable}
                        selectedTab={activeOrders}
                    />
                )}
                {activeOrders === 'closed' && (
                    <>
                        <CustomDataGrid
                            adornmentType={["search"]}
                            postAPI={true}
                            pagination
                            ApiRequestBody={{
                                "from_date": fromDate,
                                "to_date": toDate,
                            }}
                            columns={columnData}
                            ApiRequest={() => FetchClosedComplaints()}
                            typography={closedComplaintsTypoGraphy}
                            isStatusChanged={generateTable}
                            selectedTab={activeOrders}
                        />
                    </>

                )}

            </Paper>
        </>
    )
}

export default ComplaintManagement