import React, { useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import classes from "./OrderSummary.module.css"
import { Stack } from "@mui/material"

export default function DynamicAccordion({ panelsData }) {
  const [expanded, setExpanded] = useState(
    panelsData.length > 0 ? panelsData[0].order_id : false
  )

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div>
      {panelsData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === item.order_id}
          onChange={handleChange(item.order_id)}
          style={{
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            padding: "10px",
            alignSelf: "start",
            marginBottom: "1rem",
            // boxShadow: "none",
          }}
          className={`${classes.noPseudo}`}
        >
          <AccordionSummary
            expandIcon={
              expanded === item.order_id ? (
                ""
              ) : (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#32349b",
                    fontFamily: "var(--font-semibold)",
                  }}
                >
                  View Order Details
                </p>
              )
            }
            aria-controls={`${item.order_id}-content`}
            id={`${item.id}-header`}
            style={{ border: "none" }}
          >
            <div className={classes.main_box}>
              <div className={classes.lable_text}>
                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                  {item.order_id}
                </Typography>
                <p className="vertical color_bule">{item.vertical}</p>

              </div>
              {/* <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px" }}>
                SO No.- {item.so_number}
              </Typography> */}
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000000",
                }}
              >
                {item.items.length} Product Details
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {item?.items?.map((product, index) => (
              <Stack
                key={index}
                direction={"row"}
                style={{
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E8E8E8",
                  paddingBlock: "24px",
                }}
              >
                <Stack direction={"column"} gap={"10px"}>
                  <Stack>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      {product?.product_code}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {product?.name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={"8px"}>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Material: {product?.material}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Finish: {product?.coating} COATING
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={"45px"}
                    style={{ marginTop: "6px" }}
                  >
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Qty:{" "}
                      <span style={{ color: "#000000" }}>
                        {product?.quantity}
                      </span>{" "}
                      pcs
                    </Typography>
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Needed Date:{" "}
                      <span style={{ color: "#000000" }}>
                        {product?.needed_date}
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"column"} style={{ alignItems: "end" }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {product?.value ? product?.value : 0}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#979797",
                      textDecoration: "line-through",
                    }}
                  >
                    {product?.mrp ? product?.mrp : 0}
                  </Typography>
                  {/* <p className="discount_percentage">
                    (
                    {(
                      ((parseFloat(item.mrp) - parseFloat(item.value)) /
                        parseFloat(item.mrp)) *
                      100
                    ).toFixed(2)}
                    )
                  </p> */}
                </Stack>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
