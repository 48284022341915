import React from "react"
import styled from "styled-components"
import { NavLink, useNavigate } from "react-router-dom"

const TabContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  border-radius: 1px;
  overflow-x: scroll;
  margin-top: 23.5px;
  gap: 0.9rem;
  border-bottom: 1px solid #e8e8e8;
  &::-webkit-scrollbar {
    display: none;
  }
`

const TabButton = styled.button`
  height: 100%;
  outline: none;
  cursor: pointer;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  border: none;
  font-size: 14px;
  align-items: center;
  position: relative;
  background: transparent;
  padding: 0;
  border-bottom: ${props =>
    props.active ? "3px solid var(--primary-blue)" : "3px solid transparent"};
  &:focus {
    outline: none;
  }
`

const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  margin: 0px 7px;
  font-size: 14px;
  font-family: ${props =>
    props.active ? "var(--font-semibold)" : "var(--font-regular)"};
  color: ${props => (props.active ? "var(--primary-blue)" : "#000")};
  transition: 0.2s;
  gap: 1rem;
`

export default function SubHeader({ tabs }) {
  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => (
          <NavLink to={`${tab.path}`} key={index} exact={true}>
            {({ isActive }) => (
              <TabButton key={index} active={isActive}>
                <Title active={isActive}>{tab.title}</Title>
              </TabButton>
            )}
          </NavLink>
        ))}
      </TabContainer>
    </>
  )
}
