import React from 'react';

const Image = ({ src, alt, width, height, borderRadius, className, onClick }) => {
  const imageStyle = {
    width: width,
    height: height,
    borderRadius: borderRadius,
  };

  return <img onClick={onClick} src={src} alt={alt} style={imageStyle} className={className}/>;
};

export default Image;
