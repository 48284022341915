import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const ProfileServices = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const FetchProfileDetails = () =>
        useApiHttp({
            url: `marketing/view-profile`,
            method: "POST",
            // onSuccess: (data) => {
            //     toast.success((data.message || 'Success!'), {
            //         position: "top-center"
            //     });
            //     dispatch(login(data.data));
            //     navigate("/marketing/product");
            // }
        });



    return {
        FetchProfileDetails
    }

}

export default ProfileServices