import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import downloadIcon from '../../../assets/image/svg/download.svg'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const MenuPopup = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (type) => {
        handleClose();
        props?.onClick?.(type);
    }
    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <img src={downloadIcon} height={24} width={24} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleMenuItemClick('pdf')}>PDF</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('csv')}>CSV</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('xls')}>XLS</MenuItem>
            </Menu>
        </>

    )
}

export default MenuPopup

