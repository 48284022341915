import React, { useEffect, useState } from "react"
import classes from "./RecentOrders.module.css"
import CarouselContainer from "../../components/UI/Carousel/Carousel"
import OrderCard from "../../components/UI/Card/OrderCard"
import useApiHttp from "../../hooks/ues-http"
import { fetchList } from "../../services/storeSlice/shopNowSlice"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const OnGoingOrdersCard = ({ setValue }) => {
  const [orderData, setOrderData] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    isLoading: OrderCardLoading,
    success: OrderCardSucces,
    error: OrderCardError,
    sendRequest: OrderCardRequest,
  } = useApiHttp()
  const OrderCardHandleSelect = () => {
    OrderCardRequest(
      {
        url: `dealer/my-order/ongoing-orders`,
      },
      data => {
        setOrderData(data.data)
        setValue(data.data)
      }
    )
  }
  useEffect(() => {
    OrderCardHandleSelect()
  }, [])

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const repeadOrderRequestHandle = token => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          dispatch(fetchList())
          navigate("/shopnow")
        }
      }
    )
  }

  return (
    <div>
      <CarouselContainer
        responsive={responsive}
        className="recenOrder"
        itemClass={"carouselItem"}
        showArrows={true}
        data={orderData}
      >
        {orderData.map((item, index) => (
          <OrderCard
            status={item.order_status}
            order_date={item.order_date}
            etd={item.delivered_date}
            id={item.order_id}
            items={item.total_items}
            rate={item.total_amount}
            data={item}
            key={index}
            cta={() => repeadOrderRequestHandle(item.token)}
          />
        ))}
      </CarouselContainer>
    </div>
  )
}

export default OnGoingOrdersCard
