const PendingIcon = () => (
    <svg width="18px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>web/dd/svg/pening</title>
        <g id="web/dd/svg/pening" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group" transform="translate(3, 3)" fill="#EC7B0A" fill-rule="nonzero">
                <path d="M9,0 C4.0300075,0 0,4.02975 0,9 C0,13.97025 4.0300075,18 9,18 C13.9707427,18 18,13.97025 18,9 C18,4.02975 13.9707427,0 9,0 Z M12.3098275,12.53175 L12.0472618,12.79425 C11.8289572,13.0125 11.4606152,13.023 11.2310578,12.816 L7.84021005,9.84975 C7.60915229,9.6435 7.43435859,9.22125 7.44936234,8.91225 L7.76369092,3.56175 C7.78019505,3.252 8.04651163,3 8.35633908,3 L8.72618155,3 C9.036009,3 9.30157539,3.252 9.31657914,3.561 L9.57389347,8.0115 C9.58964741,8.32125 9.77044261,8.7615 9.97524381,8.99325 L12.3338335,11.71425 C12.5386347,11.946 12.5288822,12.3135 12.3098275,12.53175 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
)

export default PendingIcon