import react, { useEffect, useState } from "react"
import classes from "./OrderManagement.module.css"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EnhancedTable from "./Components/OrderTable";
import useApiCall from "../../hooks/useApiCall";
import secureLocalStorage from "react-secure-storage";
import EnhancedTableClosed from "./Components/OrderTableClosed";
import { useLocation, useParams } from "react-router-dom";

export const OrderManagement = () => {
    const location = useLocation();
    const [activeOrders, setActiveOrders] = useState("ongoing");
    const [dealerData, setDealerData] = useState(null);
    const [statusData, setStatusData] = useState(null);
    const [data, setData] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);

    const [selectedDealer, setSelectedDealer] = useState("Select")
    const [selectedStatus, setSelectedStatus] = useState("Select")
    const [fromDate, setFromDate] = useState(dayjs())
    const [toDate, setToDate] = useState(dayjs())
    const [currentPageNumber, setCurrentPageNumber] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        const performanceData = performance.getEntriesByType("navigation")[0];

        if (performanceData) {
            switch (performanceData.type) {
                case 'reload':
                    if (localStorage.getItem('from_navigation') === "true") {
                        // console.log('from_navigation');
                        getStoredData()
                    } else {
                        // console.log('reload');
                        localStorage.setItem('fromDate', dayjs().format('YYYY-MM-DD'));
                        localStorage.setItem('toDate', dayjs().format('YYYY-MM-DD'));
                        localStorage.setItem('searchTerm', "");
                        localStorage.setItem('selectedDealer', "Select");
                        localStorage.setItem('selectedStatus', "Select");
                        localStorage.setItem('activeOrders', "ongoing");
                    }
                    break;
                case 'navigate':
                    getStoredData()
                    break;
                case 'back_forward':
                    getStoredData()
                    break;
                // Note: `prerender` is not commonly used.
                default:
                    console.log('unknown');
            }
        }
    }, [location]);

    const getStoredData = () => {
        const storedFromDate = localStorage.getItem('fromDate');
        const storedToDate = localStorage.getItem('toDate');
        const storedSearchTerm = localStorage.getItem('searchTerm');
        const storedSelectedDealer = localStorage.getItem('selectedDealer');
        const storedSelectedStatus = localStorage.getItem('selectedStatus');
        const active_order = localStorage.getItem('activeOrders');
        setFromDate(storedFromDate ? dayjs(storedFromDate) : dayjs());
        setToDate(storedToDate ? dayjs(storedToDate) : dayjs());
        setSearchTerm(storedSearchTerm ? storedSearchTerm : "");
        setSelectedDealer(storedSelectedDealer || "Select");
        setSelectedStatus(storedSelectedStatus || "Select");
        setActiveOrders(active_order || "ongoing");
    }

    useEffect(() => {
        getDetails({
            url: `marketing/dealer-dropdown-list`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
            }
        }, (responseData) => {
            setDealerData(responseData?.data)
        });
    }, []);

    useEffect(() => {
        getDetails({
            url: `marketing/order-status-list`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
            }
        }, (responseData) => {
            setStatusData(responseData?.data)
        });
    }, []);

    const { isLoading: isGenerateLoading, success: generateSuccess, error: generateError, code: generateStatusCode, errorData: generateErrorData, sendRequest: generate } = useApiCall();

    const handleGenerate = () => {
        const storedFromDate = localStorage.getItem('fromDate');
        const storedToDate = localStorage.getItem('toDate');
        // const storedSearchTerm = localStorage.getItem('searchTerm');
        const storedSearchTerm = searchTerm;
        const storedSelectedDealer = localStorage.getItem('selectedDealer');
        const storedSelectedStatus = localStorage.getItem('selectedStatus');
        const active_order = localStorage.getItem('activeOrders') || 'ongoing';
        generate({
            url: "marketing/ongoing-closed-order-list",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
            },
            body: {
                "to_date": storedToDate === null ? dayjs().format('YYYY-MM-DD') : storedToDate,
                "from_date": storedFromDate === null ? dayjs().format('YYYY-MM-DD') : storedFromDate,
                "dealer_token": active_order === "ongoing" ? storedSelectedDealer === "Select" ? "" : storedSelectedDealer : "",
                "status": storedSelectedStatus === "Select" ? "" : storedSelectedStatus,
                "type": active_order === "ongoing" ? "1" : "2",
                "draw": 2,
                "start": currentPageNumber * 10,
                "length": rowsPerPage,
                "page": currentPageNumber,
                "per_page": rowsPerPage,
                "order": [
                    {
                        "column": 0,
                        "dir": "desc"
                    }
                ],
                "columns": [
                    { "data": "quantity" },
                    { "data": "order_token" },
                    { "data": "order_id" }
                ],
                "search": {
                    "value": storedSearchTerm
                }
            },
        }, (response) => {
            setData(response.aaData)
            setTotalOrders(response.iTotalRecords)
            localStorage.setItem('from_navigation', false)
        });
    }

    useEffect(() => {
        handleGenerate()
    }, [currentPageNumber, rowsPerPage, activeOrders, searchTerm])

    return (
        <div className={classes.container}>
            <p className={classes.title}>Order Management</p>
            <div className={classes.divider}></div>
            <div className={classes.button_container}>
                <Button
                    onClick={() => {
                        localStorage.setItem('activeOrders', "ongoing");
                        setActiveOrders("ongoing")
                        setSelectedDealer("Select")
                        setSelectedStatus("Select")
                        localStorage.setItem('selectedStatus', "Select");
                        setCurrentPageNumber(0)
                        setSearchTerm("")
                    }}
                    className={activeOrders === "ongoing" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Ongoing Orders
                </Button>
                <Button
                    onClick={() => {
                        localStorage.setItem('activeOrders', "closed");
                        setActiveOrders("closed")
                        setSelectedDealer("Select")
                        setSelectedStatus("Select")
                        localStorage.setItem('selectedStatus', "Select");
                        setCurrentPageNumber(0)
                        setSearchTerm("")
                    }}
                    className={activeOrders === "closed" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Closed Orders
                </Button>
            </div>
            <div className={classes.form_container}>
                {
                    activeOrders === "ongoing" &&
                    <div className={classes.form_element}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ fontFamily: 'Proxima-Regular' }}
                            >Choose Dealer</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDealer}
                                label="Choose Dealer"
                                onChange={(item) => {
                                    localStorage.setItem('selectedDealer', item.target.value);
                                    setSelectedDealer(item.target.value)
                                }}
                                sx={{
                                    borderRadius: '8px', // Set the desired border radius
                                    '& .MuiSelect-select': {
                                        borderRadius: '8px', // Ensure the inner select has the same border radius
                                        fontFamily: 'Proxima-Regular', // Change to your desired font family for the value
                                    },
                                    '& .MuiSelect-icon': {
                                        fontFamily: 'Proxima-Regular', // Change font family for the dropdown icon if needed
                                    },
                                }}
                            >
                                <MenuItem value={"Select"}>Select</MenuItem>
                                {
                                    dealerData?.map((item, index) => (
                                        <MenuItem key={index} value={item?.token}>{item?.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                }
                <div className={classes.form_element}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                format="DD-MM-YYYY"
                                label="From Date"
                                value={fromDate}
                                onChange={(newValue) => {
                                    localStorage.setItem('fromDate', newValue.format('YYYY-MM-DD'));
                                    setFromDate(newValue)
                                }}
                                sx={{
                                    borderRadius: '8px', // Set desired border radius
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px', // Ensure the inner input has the same border radius
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            borderRadius: '8px', // Set desired border radius
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px', // Ensure the inner input has the same border radius
                                                fontFamily: 'Proxima-Regular', // Font family for the value
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontFamily: 'Proxima-Regular', // Font family for the label
                                            },
                                        }}
                                    />
                                )}
                                InputLabelProps={{
                                    sx: {
                                        fontFamily: 'Proxima-Regular', // Ensure the label uses the correct font family
                                    },
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className={classes.form_element}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                format="DD-MM-YYYY"
                                label="From Date"
                                value={toDate}
                                onChange={(newValue) => {
                                    localStorage.setItem('toDate', newValue.format('YYYY-MM-DD'));
                                    setToDate(newValue)
                                }}
                                sx={{
                                    borderRadius: '8px', // Set desired border radius
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px', // Ensure the inner input has the same border radius
                                        maxWidth: 200
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            borderRadius: '8px', // Set desired border radius
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px', // Ensure the inner input has the same border radius
                                            },
                                        }}
                                    />
                                )}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                {
                    activeOrders === "ongoing" &&
                    <div className={classes.form_element}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Choose Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStatus}
                                label="Choose Status"

                                onChange={(item) => {
                                    localStorage.setItem('selectedStatus', item.target.value);
                                    setSelectedStatus(item.target.value)
                                }}
                                sx={{
                                    borderRadius: '8px', // Set the desired border radius
                                    '& .MuiSelect-select': {
                                        borderRadius: '8px', // Ensure the inner select has the same border radius
                                        fontFamily: 'Proxima-Regular', // Change to your desired font family for the value
                                    },
                                    '& .MuiSelect-icon': {
                                        fontFamily: 'Proxima-Regular', // Change font family for the dropdown icon if needed
                                    },
                                }}
                            >
                                <MenuItem value={"Select"}>Select</MenuItem>
                                {
                                    statusData?.map((item, index) => (
                                        <MenuItem key={index} value={item?.id}>{item?.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                }
                <div className={classes.form_element}>
                    <Button onClick={handleGenerate} className={classes.buttonOngoing} variant="contained">Generate</Button>
                </div>
            </div>
            <div className={classes.table_container}>
                {
                    activeOrders === "ongoing" ?
                        <EnhancedTable
                            currentPageNumber={currentPageNumber}
                            setCurrentPageNumber={setCurrentPageNumber}
                            data={data}
                            totalOrders={totalOrders}
                            activeOrders={activeOrders}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            selectedDealer={selectedDealer}
                            selectedStatus={selectedStatus}
                            fromDate={fromDate}
                            toDate={toDate}
                            statusData={statusData}
                            handleGenerate={handleGenerate}
                        />
                        :
                        <EnhancedTableClosed
                            currentPageNumber={currentPageNumber}
                            setCurrentPageNumber={setCurrentPageNumber}
                            data={data}
                            totalOrders={totalOrders}
                            activeOrders={activeOrders}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            selectedDealer={selectedDealer}
                            selectedStatus={selectedStatus}
                            fromDate={fromDate}
                            statusData={statusData}
                            toDate={toDate}
                        />

                }
            </div>
        </div>
    )
}