import React from "react"
import classes from "./Header.module.css"
import CustomizedMenus from "../../components/UI/ProfileMenu/ProfileMenu"
import logo from "./../../assets/image/png/logo@2x.png"
import Notification from "../../components/UI/NotificationBadge/NotificationBadge"
import { Link } from "react-router-dom"

const Header = () => {
  return (
    <header className={classes.header}>
      <div className={classes.header_chil}>
      <Link to={"/"}>

        <div className={classes.header_log}>
          <img src={logo} alt="logo" width={"71.19px"} />
        </div>
      </Link>
        <div className={classes.header_menu}>
          {/* <Notification /> */}
          <div className={classes.header_menu_nav}>
            <CustomizedMenus />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
