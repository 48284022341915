import { Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CustomButton from "../components/UI/Button/Button"
import { setSteperVlue } from "../services/storeSlice/shopNowSlice"
import { useNavigate } from "react-router-dom"
import acknowlegment from "../assets/image/svg/download_2.svg"
import classes from "./OrderSummary.module.css"
import { jsPDF } from "jspdf"
import * as XLSX from "xlsx"
import CustomTable from "../components/UI/Table/Table"
import DynamicAccordion from "./DynamicAccordion"

const OrderSummary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const arr = ["", "", ""]
  const data = useSelector(state => state.summaryData)
  const panelsData = [
    {
      id: "panel1",
      title: "General settings",
      secondaryText: "I am an accordion",
      content:
        "Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id dignissim quam.",
    },
    {
      id: "panel2",
      title: "Users",
      secondaryText: "You are currently not an owner",
      content:
        "Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.",
    },
    {
      id: "panel3",
      title: "Advanced settings",
      secondaryText:
        "Filtering has been entirely disabled for whole web server",
      content:
        "Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.",
    },
    {
      id: "panel4",
      title: "Personal data",
      secondaryText: null,
      content:
        "Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.",
    },
  ]
  const summaryData1 = data.updatesummarydata
  const updatesummarydata = data.updatesummarydata
  const order_items = updatesummarydata.order_items
  const order_overview = updatesummarydata.order_overview
  const total_orders = updatesummarydata.total_orders
  console.log(total_orders)

  const downloadPDFOrder = () => {
    const doc = new jsPDF("p", "mm", "a4")

    // Set font styles and sizes
    doc.setFont("helvetica", "normal") // Helvetica supports ₹ symbol

    // Header section
    doc.setFontSize(20)
    doc.setTextColor(55, 55, 55) // Dark color for headings

    // Company name/logo
    doc.text("Addison & Co. Ltd", 70, 20)

    // Set line color to gray
    doc.setDrawColor(169, 169, 169) // Gray color for lines
    doc.line(10, 25, 200, 25) // Horizontal line separating header

    // Adjust Y-coordinate for subsequent lines
    let yPosition = 35 // Initial Y position for content

    // First line: "Order Details:"
    doc.setFontSize(20)
    doc.text("Order Details:", 10, yPosition)

    // Second line: "Martha Pearson"
    yPosition += 10 // Move Y-coordinate down
    doc.setFontSize(24) // Adjust font size for name
    doc.text(`${localStorage.getItem("contactName")}`, 10, yPosition)

    // Third line: Contact details
    yPosition += 10 // Move Y-coordinate down
    doc.setFontSize(12) // Adjust font size for contact info
    doc.text(
      ` ${summaryData1?.shipping_address?.mobile_number
      } | ${localStorage.getItem("userEmail")}`,
      10,
      yPosition
    )

    // Column structure for Order, Shipping, and Billing details
    const col1 = 10,
      col2 = 70,
      col3 = 140
    yPosition += 15 // Increase Y-position for the next section

    // Change highlight color for headings to dark color
    doc.setFontSize(14)
    doc.setTextColor(55, 55, 55) // Dark color for headings
    doc.text("Order Details:", col1, yPosition)
    doc.text("Shipping Address:", col2, yPosition)
    doc.text("Billing Address :", col3, yPosition)

    // Column Values
    doc.setFontSize(12)
    doc.setTextColor(128, 128, 128) // Gray color for normal text

    yPosition += 7 // Adjust Y-position for the order details

    // Dynamic Order Details
    doc.text(`Addison Reference No: ${summaryData1?.order_id}`, col1, yPosition)
    doc.text(`Order Date: ${summaryData1?.created_at}`, col1, yPosition + 6)
    doc.text(
      `Delivery Date: ${summaryData1?.estimate_delivery_date}`,
      col1,
      yPosition + 12
    )

    // Shipping Address
    doc.text(
      `${summaryData1?.shipping_address?.address_line_1},\n${summaryData1?.shipping_address?.address_line_2},\n${summaryData1?.shipping_address?.city}, ${summaryData1?.shipping_address?.state} ${summaryData1?.shipping_address?.pincode},\n${summaryData1?.shipping_address?.country}.`,
      col2,
      yPosition
    )

    // Billing Address
    doc.text(
      `${summaryData1?.billing_address?.address_line_1},\n${summaryData1?.billing_address?.address_line_2},\n${summaryData1?.billing_address?.city}, ${summaryData1?.billing_address?.state} ${summaryData1?.billing_address?.pincode},\n${summaryData1?.billing_address?.country}.`,
      col3,
      yPosition
    )

    // Separator line
    yPosition += 30 // Increase Y-coordinate for separator line
    doc.line(10, yPosition, 200, yPosition) // Gray line (since drawColor is already set to gray)

    // Table Header
    yPosition += 10 // Adjust Y-position for table header
    doc.setFontSize(12)
    doc.setTextColor(55, 55, 55) // Dark color for table headers
    doc.text("DESCRIPTION", 10, yPosition)
    doc.text("QTY", 105, yPosition)
    doc.text("UNIT PRICE", 120, yPosition)
    doc.text("DATE", 150, yPosition)
    doc.text("AMOUNT", 180, yPosition)

    // Dynamic Table Rows
    yPosition += 10 // Start the first row after the header
    summaryData1?.products?.forEach(item => {
      doc.setTextColor(128, 128, 128) // Gray for table rows

      // Split product name into multiple lines if it exceeds 32 characters
      const productNameLines = doc.splitTextToSize(item.product_name, 90) // 90 is the width you want to allow for the product name

      // Draw the product name lines
      productNameLines.forEach((line, index) => {
        doc.text(line, 10, yPosition + index * 10) // Adjust yPosition for each line
      })

      // Draw other details in the same row
      doc.text(`${item.quantity}`, 105, yPosition)
      doc.text(
        `${Number(item.product_rate_mrp).toLocaleString("en-IN")}`,
        120,
        yPosition
      )
      doc.text(`${item.requested_date}`, 150, yPosition)
      doc.text(
        `${Number(item.product_total_amount).toLocaleString("en-IN")}`,
        180,
        yPosition
      )

      // Move the yPosition down for the next row
      yPosition += Math.max(10, productNameLines.length * 10) // Move down according to the number of lines
    })

    // Summary Section
    yPosition += 10
    doc.setTextColor(55, 55, 55) // Dark color for summary labels
    doc.text("Order Value", 150, yPosition)
    doc.setTextColor(128, 128, 128) // Gray for summary values
    doc.text(
      `${Number(summaryData1?.order_value).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    yPosition += 10
    doc.setTextColor(55, 55, 55)
    doc.text("Total Tax", 150, yPosition)
    doc.setTextColor(128, 128, 128)
    doc.text(
      `${Number(summaryData1?.order_tax_amount).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    yPosition += 10
    doc.setTextColor(55, 55, 55)
    doc.text("Grand Total", 150, yPosition)
    doc.setTextColor(128, 128, 128)
    doc.text(
      `${Number(summaryData1?.item_total_value).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    // Save PDF
    doc.save("Order_Summary.pdf")
  }

  const columns = [
    {
      name: "S.No",
      selector: row => row.sl_no,
      cell: row => (
        <div className={classes.textCenter} style={{ width: " 100px" }}>
          {row.sl_no}
        </div>
      ),
      maxWidth: "100px",
      minWidth: "100px",
    },
    {
      name: "Addison Reference No",
      selector: row => row.order_id,
      cell: row => <div className={classes.textLeft}>{row.order_id}</div>,
    },
    // {
    //   name: "SO No.",
    //   selector: row => row.so_number,
    //   cell: row => <div className={classes.textLeft}>{row.so_number}</div>,
    // },

    {
      name: "Vertical Type",
      selector: row => row.vertical,
      cell: row => (
        <div className={classes.textCenter} style={{ width: " 200px" }}>
          {row.vertical}
        </div>
      ),
    },
    {
      name: "Item Cost",
      selector: row => (
        <div className={classes.textend} style={{ textAlign: "right" }}>
          {row.item_value}
        </div>
      ),
    },
  ]

  return (
    <div style={{ paddingTop: "24px", paddingBottom: "100px" }}>
      <Stack direction={"row"} gap={"20px"}>
        <Stack style={{ width: "100%" }} direction={"column"}>
          {/* div order */}
          <Stack>
            <div className={classes.alert_box}>
              <div className={classes.alert_box_logo}>
                <SuccessSvg />
                <Stack>
                  <Typography
                    style={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#000000",
                    }}
                  >
                    Order Placed Successfully!
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                    }}
                  >
                    Your order has been successful placed
                  </Typography>
                </Stack>
              </div>
            </div>
          </Stack>
          {/* div detail */}
          <Stack
            direction={"column"}
            style={{
              // borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              padding: "24px",
              paddingBottom: "42px",
              alignContent: "start",
              borderBottom: "2px dashed #E8E8E8",
            }}
            gap={"8px"}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "2px",
              }}
            >
              Delivery Details
            </Typography>

            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                order date{summaryData1?.order_id}
              </Typography>
              {/* <div
                className={classes.downlode_compo}
                onClick={() => downloadPDFOrder()}
              >
                <img src={acknowlegment} alt="acknowlegment" />
                <p>Download Order Details</p>
              </div> */}
            </Stack>
            <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
              Order date:{" "}
              <span style={{ fontWeight: "600" }}>
                {" "}
                {order_overview?.order_date}
              </span>
            </Typography>
            <Stack direction={"row"}>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.shipping_address?.address_line_1},<br />
                    {order_overview?.shipping_address?.address_line_2},<br />
                    {order_overview?.shipping_address?.city},{" "}
                    {order_overview?.shipping_address?.state}{" "}
                    {order_overview?.shipping_address?.pincode},{" "}
                    {order_overview?.shipping_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {order_overview?.shipping_address?.country_code}-
                  {order_overview?.shipping_address?.mobile_number}
                </Typography>
                {/* <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Estimated Delivered Date
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.estimatesDate}
                  </Typography>
                </Stack> */}
                {/* <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Dealer Reference Number
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.dealer_reference_number}
                  </Typography>
                </Stack> */}
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Item Total Value
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.item_total_value}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Billing Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.billing_address?.address_line_1},<br />
                    {order_overview?.billing_address?.address_line_2},<br />
                    {order_overview?.billing_address?.city},{" "}
                    {order_overview?.billing_address?.state}{" "}
                    {order_overview?.billing_address?.pincode},{" "}
                    {order_overview?.billing_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {order_overview?.billing_address?.country_code}-
                  {order_overview?.billing_address?.mobile_number}
                </Typography>
                {/* <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Item Total Value
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {order_overview?.item_total_value}
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>

            {/* <Stack direction={"row"} justifyContent={"space-between"}>
              <div
                className={classes.downlode_compo}
                onClick={() => downloadPDFOrder()}
              >
                <img src={acknowlegment} alt="acknowlegment" />
                <p>Download Order Details</p>
              </div>
            </Stack> */}
          </Stack>
          {/* div summary */}
          <Stack
            direction={"column"}
            style={{
              // borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              paddingBottom: "10px",
              alignContent: "start",
            }}
          >
            <Stack gap={"10px"} direction={"column"}>
              <CustomTable
                className={classes.table}
                data={total_orders?.orders}
                columns={columns}
                children={
                  <>


                    <div className={classes.header_table}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Order Summary
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Dealer Ref. No : {order_overview?.dealer_reference_number}
                      </Typography>
                    </div>

                    <div></div>
                  </>
                }
                subFooterContent={
                  <div className={classes.card_box_total}>
                    <div className={classes.total_card}>
                      <div className={classes.total}>
                        <div className={classes.flex}>
                          <p>Total Amount</p>
                          <p>
                            {total_orders?.item_total_value
                              ? total_orders?.item_total_value
                              : 0}
                          </p>
                        </div>
                        <div className={classes.flex_end}>
                          <span>{"(Excluding Taxes)"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            </Stack>
            {/* <Stack
              direction={"row"}
              style={{ justifyContent: "space-between", marginTop: "24px" }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Order Value
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#65A547",
                }}
              >
                ₹{" "}
                {summaryData1?.item_total_value
                  ? Number(summaryData1?.item_total_value).toLocaleString(
                      "en-IN"
                    )
                  : 0}
              </Typography>
            </Stack>
            <div className={classes.flex_end}>
              <span>{"(excluding applicable taxes)"}</span>
            </div> */}
          </Stack>
        </Stack>
        <Stack
          style={{
            width: "100%",
          }}
        >
          <DynamicAccordion panelsData={order_items} />
        </Stack>
      </Stack>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{
            padding: "10px 7px",
            width: "400px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
          onClick={() => {
            navigate("/myorders")
            dispatch(setSteperVlue(0))
            localStorage.removeItem("shop_status")
          }}
        >
          Go to My Orders
        </CustomButton>
      </div>
    </div>
  )
}
export default OrderSummary

const SuccessSvg = () => {
  return (
    <svg
      width="58px"
      height="58px"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>web/md/svg/success popup</title>
      <g
        id="web/md/svg/success-popup"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(10, 10)"
          fill="#65A547"
          fill-rule="nonzero"
        >
          <path
            d="M50,0 C22.5,0 0,22.5 0,50 C0,77.5 22.5,100 50,100 C77.5,100 100,77.5 100,50 C100,22.5 77.5,0 50,0 L50,0 Z M40,75 L15,50 L22,43 L40,61 L78,23 L85,30 L40,75 L40,75 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
