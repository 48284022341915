import React, { useState, useEffect } from 'react'
import classes from './complaintdetails.module.css'
import ComplaintServices from '../complaints.service'
import { Breadcrumbs, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import styled from "styled-components"
import PersonalDetailsField from '../../../components/UI/PersonalDetailsViewField/PersonalDetailsField';
import CustomeSlecter from '../../../components/UI/Dropdown/CustomeSlecter';
import FileViewer from '../../../components/UI/ImagePdfPreviewer/ImagePdfPreview';
import FileViewerLightbox from '../../../components/UI/ImagePdfPreviewer/ImagePdfViewer';
import MuiTextarea from '../../../../marketing/components/UI/Inputs/TextArea/TextAreaInput';
import { toast } from "react-toastify";



const StatusWidgetLable = styled.div.attrs(props => ({ className: classes[props.class] }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-regular);
color:#FFFFFF;
line-height:14px;
font-size:14px;
padding: 4px 10px;
border-radius: 3px;
`

const ComplaintDetails = () => {
    const { id } = useParams();

    const { ViewComplaints, ComplaintStatusUpdate } = ComplaintServices();
    const [complaintDetails, setComplaintDetails] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({
        selectedVal: "",
        status_list: [
            // { label: "Created", value: "0" },
            { label: "Received", value: "1" },
            { label: "Under Investigation", value: "2" },
            { label: "Resolved", value: "3" }
        ]
    });
    const [textAreaVal, setTextAreaVal] = useState("");
    const [triggerDetailsRead, setTriggerDetailsRead] = useState(false);
    const [showUpdateBtnSection, setShowUpdateBtnSection] = useState(false);

    const { sendRequest: viewComplaintDetails } = ViewComplaints();
    const { sendRequest: updateStatus } = ComplaintStatusUpdate();

    useEffect(() => {
        viewComplaintDetails({
            body: {
                "complaint_token": id
            }
        }, (data) => {
            setComplaintDetails(data.data);
            const index = selectedStatus.status_list.findIndex(x => x.label === data?.data?.status);

            setSelectedStatus((curr) => ({ ...curr, selectedVal: selectedStatus.status_list[index]?.value }))

        })


    }, [id, triggerDetailsRead])

    const handleStatusUpdate = () => {
        let bodyData = {
            "token": id,
            "status": selectedStatus.selectedVal, //`0-Created,`1-Yet to be picked`,`2-Under Investigation`,`3-Resolved`
            "comment": textAreaVal
        }
        updateStatus({
            body: bodyData
        }, (data) => {

            setShowUpdateBtnSection(false);
            setTextAreaVal('');

            setTriggerDetailsRead((prev) => !prev)
        }, () => {
            handleCancelUpdate();
        })
    }

    const handleCancelUpdate = () => {
        const index = selectedStatus.status_list.findIndex(x => x.label === complaintDetails?.status);
        setSelectedStatus((curr) => ({ ...curr, selectedVal: selectedStatus.status_list[index].value }))
        setShowUpdateBtnSection(false);
    }

    const handleDropDownChange = (e) => {
        const currentValidStatusIndex = selectedStatus.status_list.findIndex(x => x.label === complaintDetails?.status);
        const changedStatusIndex = selectedStatus.status_list.findIndex(x => x.value === e.target.value);
        if (changedStatusIndex > currentValidStatusIndex) {
            setSelectedStatus((curr) => ({ ...curr, selectedVal: e.target.value }));
            setShowUpdateBtnSection(true);
            setTextAreaVal('')
        } else {
            setShowUpdateBtnSection(false);
            // setTextAreaVal('')
        }
    }



    const personalDataFieldStyle = {
        color: "#212121",
        fontFamily: "var(--font-regular)",
        fontWeight: "400"
    };

    const handleTextAreaChange = (e) => {
        setTextAreaVal(e.target.value)
    }
    return (
        <>
            <Stack>
                <h6 className={classes.header_text_class}>{complaintDetails?.complaint_id}</h6>
                {/* <span style={{ color: "var(--light-gray)", fontFamily: "var(--font-regular)", fontSize: "12px", fontWeight: "400" }}><Link style={{ color: "#376FD0" }} to={"/marketing/"}>Home</Link> / My Profile</span> */}
                <Breadcrumbs sx={{ color: "var(--light-gray)", fontFamily: "var(--font-regular)", fontSize: "12px", fontWeight: "400" }} aria-label="breadcrumb">
                    <Link style={{ color: "#376FD0" }} to={"/operations/complaints"}>Complaint Management</Link>
                    <Typography fontSize={"12px"} >{complaintDetails?.complaint_id}</Typography>
                </Breadcrumbs>
                <Divider sx={{ marginTop: "16px" }} />
            </Stack>
            <Paper sx={{ padding: "20px", marginTop: "25px" }}>
                <StatusWidgetLable class={complaintDetails?.status == "Under Investigation" ? "status_under_investigation" : `status_${complaintDetails?.status?.toLowerCase()}`}>
                    {complaintDetails?.status}
                </StatusWidgetLable>
                <h6 style={{ marginTop: "8px" }} className={classes.header_text_class}>
                    {complaintDetails?.complaint_id}
                </h6>
                <p style={{ color: "#B2B2B2", fontFamily: "var(--font-regular)", fontSize: "12px" }}>
                    {complaintDetails?.dateTime}
                </p>
                <Divider sx={{ borderColor: "#D8DBDD", marginTop: "16px", borderStyle: 'dashed', borderBottomWidth: "0.12rem", borderDashArray: '8, 4', }} />
                <Stack sx={{ marginTop: "20px" }}>
                    <h6 className={classes.prof_det_header_text}>Inquiry Details</h6>
                    <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr", marginTop: "inherit" }}>
                        <div>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", rowGap: "25px" }}>
                                <PersonalDetailsField
                                    fieldHeader={{ name: "Dealer ID", style: personalDataFieldStyle }}
                                    fieldDet={{ name: complaintDetails?.dealer_details?.dealer_id, style: personalDataFieldStyle }}
                                />
                                <PersonalDetailsField
                                    fieldHeader={{ name: "Dealer Name", style: personalDataFieldStyle }}
                                    fieldDet={{ name: complaintDetails?.dealer_details?.dealer_name, style: personalDataFieldStyle }}
                                />
                                <PersonalDetailsField
                                    fieldHeader={{ name: "Contact Number", style: personalDataFieldStyle }}
                                    fieldDet={{ name: complaintDetails?.dealer_details?.mobile_number, style: personalDataFieldStyle }}
                                />
                                <div>
                                    <p style={{ fontSize: "12px", marginBottom: "15px", ...personalDataFieldStyle }}>Product Details - {complaintDetails?.product_details?.count} Products</p>

                                    <p style={{ fontSize: "12px", ...personalDataFieldStyle }}>Addison Reference No</p>
                                    <p style={{ fontSize: "14px", ...personalDataFieldStyle }}>{complaintDetails?.product_details?.order_id}</p>
                                </div>

                            </div>
                            <div className={classes.order_items_cont}>
                                {complaintDetails?.product_details?.products.map((item, index) => (
                                    <div key={index} className={classes.order_item_cont_prod_cont}>
                                        <p className={classes.order_item_product_code}>{item.product_code} <span style={{ fontFamily: "var(--font-regular)" }}>(Variant: {item.variant})</span></p>
                                        <p className={classes.order_item_product_desc}>{item.product_name} </p>
                                    </div>
                                ))}
                                {/* <div className={classes.order_item_cont_prod_cont}>
                                    <p className={classes.order_item_product_code}>{"103110019"}</p>
                                    <p className={classes.order_item_product_desc}>{"M2 HSS SFC 100mm D x 10mm W x 27mm  B"} </p>
                                </div>
                                <div style={{ margin: "1rem 0" }}>
                                    <p style={{ fontFamily: "var(--font-semibold)", fontWeight: "600", fontSize: "14px", color: "#000000" }}>{"103110019"}</p>
                                    <p style={{ fontFamily: "var(--font-regular)", fontWeight: "400", fontSize: "13px", color: "#000000" }}>{"M2 HSS SFC 100mm D x 10mm W x 27mm  B"} </p>
                                </div>
                                <div style={{ margin: "1rem 0" }}>
                                    <p style={{ fontFamily: "var(--font-semibold)", fontWeight: "600", fontSize: "14px", color: "#000000" }}>{"103110019"}</p>
                                    <p style={{ fontFamily: "var(--font-regular)", fontWeight: "400", fontSize: "13px", color: "#000000" }}>{"M2 HSS SFC 100mm D x 10mm W x 27mm  B"} </p>
                                </div>
                                <div style={{ margin: "1rem 0" }}>
                                    <p style={{ fontFamily: "var(--font-semibold)", fontWeight: "600", fontSize: "14px", color: "#000000" }}>{"103110019"}</p>
                                    <p style={{ fontFamily: "var(--font-regular)", fontWeight: "400", fontSize: "13px", color: "#000000" }}>{"M2 HSS SFC 100mm D x 10mm W x 27mm  B"} </p>
                                </div> */}
                            </div>
                            <div className={classes.prod_cutomer_comments_cont}>
                                <p className={classes.prod_cust_comment_header}>Complaint Details</p>
                                <p className={classes.prod_cust_comment_text}>
                                    {complaintDetails?.description}
                                </p>
                            </div>
                            {complaintDetails?.remarks && (
                                <div className={classes.prod_cutomer_comments_cont}>
                                    <p className={classes.prod_cust_comment_header}>Customer Specific Remarks</p>
                                    <p className={classes.prod_cust_comment_text}>
                                        {complaintDetails?.remarks}
                                    </p>
                                </div>
                            )}

                            {complaintDetails?.attachments?.length && (
                                <div className={classes.prod_attachment_cont}>
                                    <h6 className={classes.prof_det_header_text}>Attachments</h6>
                                    <div className={classes.prod_attachment_media_cont}>
                                        {complaintDetails?.attachments?.map((item, index) => (
                                            <div key={item?.url} className={classes.prod_single_attachment_cont} >
                                                <div className={classes.prod_single_attach_preview} >
                                                    <FileViewer fileUrl={item.url} />
                                                </div>
                                                <div>
                                                    <p>{item?.name || `Attachment - ${index + 1}`}</p>
                                                    <FileViewerLightbox fileUrl={item.url} />
                                                </div>
                                            </div>
                                        ))}

                                        {/* <div className={classes.prod_single_attachment_cont} >
                                            <div className={classes.prod_single_attach_preview} >
                                                <FileViewer fileUrl={"https://d2avncdkk2ba4a.cloudfront.net/dealer/complaint/66f3e45b585f7-macappstudio2018_logo-removebg-preview.png"} />
                                            </div>
                                            <div>
                                                <p>File Namesssssssssssss</p>
                                                <FileViewerLightbox fileUrl={"https://d2avncdkk2ba4a.cloudfront.net/dealer/complaint/66f3e45b585f7-macappstudio2018_logo-removebg-preview.png"} />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                            {complaintDetails?.status?.toLowerCase() !== "resolved" && (
                                <div className={classes.prod_actions_cont}>
                                    <h6 className={classes.prof_det_header_text}>Action</h6>
                                    <CustomeSlecter
                                        data={selectedStatus.status_list}
                                        lableTitle="Approval Status"
                                        title={"Choose Status"}
                                        width={180}
                                        value={selectedStatus.selectedVal}
                                        formSx={{
                                            marginTop: "15px"
                                        }}
                                        selectSx={{
                                            borderRadius: "10px",
                                        }}
                                        // onChange={e => setSelectedStatus((curr) => ({ ...curr, selectedVal: e.target.value }))}
                                        onChange={handleDropDownChange}

                                    />
                                    {showUpdateBtnSection && (
                                        <div className={classes.comment_action_cont}>
                                            <MuiTextarea
                                                sx={{ width: "100%" }}
                                                textAreaSx={{ borderRadius: "10px" }}
                                                label="Complaint Details"
                                                placeholder="Type your Comments"
                                                value={textAreaVal}
                                                onChange={handleTextAreaChange}
                                            />
                                            <Stack direction={"row"} mt={4} mb={3} gap={2}>
                                                <Button
                                                    sx={{
                                                        width: "170px",
                                                        backgroundColor: "#376fd0",
                                                        color: "#fff",
                                                        fontSize: "12px",
                                                        textTransform: "capitalize",
                                                        padding: "13px 20px",
                                                        cursor: "pointer"
                                                    }}
                                                    // className={classes.buttonOngoing}
                                                    className={classes.button_ongoing}
                                                    variant="outlined"
                                                    onClick={handleStatusUpdate}
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    sx={{
                                                        width: "170px",
                                                        backgroundColor: "transparent",
                                                        color: "#376fd0",
                                                        fontSize: "12px",
                                                        textTransform: "capitalize",
                                                        padding: "13px 20px",
                                                        cursor: "pointer"

                                                    }}
                                                    // className={classes.button_outlined}
                                                    className={classes.button_outlined}

                                                    variant="outlined"
                                                    onClick={handleCancelUpdate}

                                                >
                                                    Cancel
                                                </Button>
                                            </Stack>
                                        </div>
                                    )}
                                    {/* <div className={classes.comment_action_cont}>
                                        <MuiTextarea
                                            sx={{ width: "100%" }}
                                            textAreaSx={{ borderRadius: "10px" }}
                                            label="Complaint Details"
                                            placeholder="Type your Comments"
                                            value={textAreaVal}
                                            onChange={handleTextAreaChange}
                                        />
                                        <Stack direction={"row"} mt={4} mb={3} gap={2}>
                                            <Button
                                                sx={{ width: "170px" }}
                                                // className={classes.buttonOngoing}
                                                className={classes.button_ongoing}
                                                variant="outlined"
                                                onClick={handleStatusUpdate}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                sx={{ width: "170px" }}
                                                // className={classes.button_outlined}
                                                className={classes.button_outlined}

                                                variant="outlined"
                                                onClick={handleCancelUpdate}

                                            >
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </div> */}

                                </div>
                            )}

                        </div>
                    </div>
                </Stack>
            </Paper>
        </>
    )
}

export default ComplaintDetails