import React, { useEffect, useState } from "react"
import classes from "./RecentOrders.module.css"
import CarouselContainer from "../../components/UI/Carousel/Carousel"
import OrderCard from "../../components/UI/Card/OrderCard"
import useApiHttp from "../../hooks/ues-http"
import ComplaintCard from "../../components/UI/Card/ComplaintCard"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1600 }, // Adjust breakpoint for large screens (like Mac)
    items: 5, // Show 6 items on large desktop screens
  },
  largeDesktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 4, // Show 5 items on slightly smaller screens
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 3, // Show 4 items on standard desktop screens
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3, // Show 3 items on tablet screens
  },
  smallTablet: {
    breakpoint: { max: 768, min: 576 },
    items: 2, // Show 2 items on smaller tablets or large phones
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1, // Show 1 item on small mobile devices
  },
}

const RecentOrders = ({ setValue, trigger }) => {
  const [orderData, setOrderData] = useState([])

  const {
    isLoading: OrderCardLoading,
    success: OrderCardSucces,
    error: OrderCardError,
    sendRequest: OrderCardRequest,
  } = useApiHttp()
  const OrderCardHandleSelect = () => {
    OrderCardRequest(
      {
        url: `dealer/raise-complaint/list/open`,
      },
      data => {
        setOrderData(data.data)
        setValue(data.data)
      }
    )
  }
  useEffect(() => {
    OrderCardHandleSelect()
  }, [trigger])

  return (
    <div>
      <CarouselContainer
        responsive={responsive}
        className="recenOrder"
        itemClass={"carouselItem"}
        showArrows={true}
        data={orderData}
      >
        {orderData.map((item, index) => (
          <ComplaintCard
            status={item.status}
            compId={item.complaint_id}
            compDate={item.date}
            token={item.token}
            attachment_url={item.attachment_url}
            key={index}
          />
        ))}
      </CarouselContainer>
    </div>
  )
}

export default RecentOrders
