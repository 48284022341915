import React, { useEffect, useMemo, useState } from "react";
import OtpInputs from "../../../components/UI/Otp/OtpInput";
import useCounter from "../../../utils/use-counter";
import { Button, Stack, TextField, Typography } from "@mui/material";
import CustomButton from "../../../components/UI/Button/Button";
import classes from "./ForgotPswForm.module.css";

import timerIcon from "../../../assets/image/svg/otp timer.svg";
import CustomTextField from "../../../components/UI/TextField/TextField";
import logo from "../../../assets/image/png/logo@2x.png";
import { useNavigate } from "react-router-dom";
import useInput from "../../../utils/use-input";
import { validateEmail } from "../../../utils/validation";
import LoginServices from "../../../pages/Login/login.service";
import validator from 'validator'

const ForgotPswForm = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("email");
  const [otp, setOtp] = useState([]);
  const [reset, setReset] = useState(false);
  const [otpCounter, startCounter, stopCounter, restartCounter] = useCounter(
    30,
    0,
    false
  );
  const { SendOtpService, VerifyOtpService, UpdatePasswordService } = LoginServices();
  const { sendRequest: sendOtp } = SendOtpService();
  const { sendRequest: verifyOtp } = VerifyOtpService();
  const { sendRequest: updatePassword } = UpdatePasswordService();



  const otpInputChangeHandler = (otp) => {
    setOtp(otp);
  };

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput(validateEmail);

  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPasswordInput,
  } = useInput((e) => {
    return e !== "" && validator.isStrongPassword(e, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    });
  });

  const {
    value: confirmPassword,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: resetConfirmPasswordInput,
  } = useInput((e) => {
    return e === password;
  });

  const handleEmail = () => {
    let body = {
      email: email
    }
    if (emailIsValid) {
      sendOtp({
        body: body
      }, (data) => {
        setStatus("otp");
        startCounter();

      })
    }

  };
  const handleOtp = () => {
    let body = {
      email: email,
      otp: otp.join('')
    }
    verifyOtp({
      body: body
    }, (data) => {
      setStatus("password");

    })

  };
  const handlePassword = () => {
    let body = {
      email: email,
      password: password,
      password_confirmation: confirmPassword
    }
    updatePassword({
      body: body
    }, (data) => {
      setStatus("success");
    })


  };
  const handleLogin = () => {
    navigate("/operations/login");
  };


  return (
    <>
      <div className={classes.login_container_heder}>
        {status === "success" ? (
          <SuccessSvg />
        ) : (
          <img src={logo} alt="logo" style={{ width: "156px" }} />
        )}
      </div>
      <div className={classes.loginFrom}>
        <div className={classes.loginHeader}>
          <h3>
            {status === "email" && "Forgot Password?"}
            {status === "otp" && "Enter OTP"}
            {status === "password" && "New Password"}
            {status === "success" && "Password Changed!"}
          </h3>

          <Typography
            variant="p"
            sx={{ color: "#646465", fontSize: "14px", fontWeight: "400" }}
          >
            {status === "email" &&
              " Enter your registered email address to get OTP"}
            {status === "otp" &&
              "We sent a one time password to your registered email address"}
            {status === "password" &&
              "Enter your new password. It must be different from the previously used password"}

            {status === "success" &&
              "Your password was changed successfully. Login to access your account."}
          </Typography>
        </div>
        {status === "email" && (
          <>
            <CustomTextField
              id="email"
              label="Email Address"
              variant="outlined"
              sx={{
                marginTop: "30px",
                width: "100%",
              }}
              value={email}
              onChange={emailChangeHandler}
              error={emailHasError}
              onBlur={emailBlurHandler}
              helperText={emailHasError ? "Enter the email" : null}
            />
            <CustomButton
              variant="contained"
              customColor="white"
              customBgColor="#376FD0"
              custmstyle={{
                padding: "16px 7px",
                width: "100%",
                marginTop: "40px",
              }}
              type="submit"
              disabled={!emailIsValid}
              onClick={() => {
                handleEmail();
              }}
            >
              Send OTP
            </CustomButton>
          </>
        )}
        {status == "otp" && (
          <>
            <div style={{ textAlign: "center" }}>
              <Typography variant="p">{email}</Typography>
              <Typography
                variant="span"
                sx={{
                  color: "#376FD0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStatus("email");
                }}
              >
                {" "}
                Change
              </Typography>
            </div>
            <OtpInputs
              mt="20px"
              onChange={otpInputChangeHandler}
              boxCount={6}
            //   onSuccess={isSuccess || isError || reset ? "true" : "false"}
            />
            <Stack direction="row" justifyContent="center" mt="10px">
              {otpCounter > 0 && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ display: "flex", gap: "3px", alignItems: "center" }}
                >
                  <img src={timerIcon} alt="time icon" width={"16px"} />
                  <span className={classes.timer}>{otpCounter} sec</span>
                </Typography>
              )}
              {otpCounter === 0 && (
                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      restartCounter();
                      setReset(true);
                      setOtp([]);
                      let body = {
                        email: email
                      }
                      if (emailIsValid) {
                        sendOtp({
                          body: body
                        })
                      }
                    }}
                    sx={{
                      ml: "-8px",
                      fontSize: "15px",
                      fontFamily: "var(--font-bold)",
                      color: "var(--primary-blue)",
                      textTransform: "capitalize",
                      padding: 0,
                    }}
                  >
                    Resend OTP
                  </Button>
                </div>
              )}
            </Stack>

            <CustomButton
              variant="contained"
              customColor="white"
              customBgColor="#376FD0"
              custmstyle={{
                padding: "16px 7px",
                width: "100%",
                marginTop: "40px",
              }}
              type="submit"
              disabled={otp.some((item) => item === "") || otp.length === 0}
              onClick={() => {
                handleOtp();
              }}
            >
              Continue
            </CustomButton>
          </>
        )}
        {status === "password" && (
          <>
            <CustomTextField
              label="New Password"
              variant="outlined"
              type="password"
              sx={{
                marginTop: "30px",
                width: "100%",
              }}
              value={password}
              onChange={passwordChangeHandler}
              error={passwordHasError}
              onBlur={passwordBlurHandler}
              helperText={passwordHasError && password === "" ? "Enter the password" : !validator.isStrongPassword(password, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
              }) ? "Password must be at least 8 characters, including an uppercase, lowercase, number, and symbol" : null}
            />
            <CustomTextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              sx={{
                marginTop: "30px",
                width: "100%",
              }}
              value={confirmPassword}
              onChange={confirmPasswordChangeHandler}
              error={confirmPasswordHasError}
              onBlur={confirmPasswordBlurHandler}
              helperText={confirmPasswordHasError ? "Ensure confirm password matches new password " : null}
            />
            <CustomButton
              variant="contained"
              customColor="white"
              customBgColor="#376FD0"
              custmstyle={{
                padding: "16px 7px",
                width: "100%",
                marginTop: "40px",
              }}
              type="submit"
              disabled={!passwordIsValid || !confirmPasswordIsValid}
              onClick={() => {
                handlePassword();
              }}
            >
              Change Password
            </CustomButton>
          </>
        )}
        {status == "success" && (
          <CustomButton
            variant="contained"
            customColor="white"
            customBgColor="#376FD0"
            custmstyle={{
              padding: "16px 7px",
              width: "100%",
              marginTop: "40px",
            }}
            type="submit"
            onClick={() => {
              handleLogin();
            }}
          >
            Login
          </CustomButton>
        )}
      </div>
    </>
  );
};

export default ForgotPswForm;

const SuccessSvg = () => {
  return (
    <svg
      width="120px"
      height="120px"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>web/md/svg/success popup</title>
      <g
        id="web/md/svg/success-popup"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(10, 10)"
          fill="#65A547"
          fill-rule="nonzero"
        >
          <path
            d="M50,0 C22.5,0 0,22.5 0,50 C0,77.5 22.5,100 50,100 C77.5,100 100,77.5 100,50 C100,22.5 77.5,0 50,0 L50,0 Z M40,75 L15,50 L22,43 L40,61 L78,23 L85,30 L40,75 L40,75 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
