import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, logout } from "../../services/authSlice/authSlice";
import { toast } from "react-toastify";


const LoginServices = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const LoginService = () =>
        useApiHttp({
            url: `marketing/login`,
            method: "POST",
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
                dispatch(login(data.data));
                navigate("/operations/");
            }
        });
    const SendOtpService = () =>
        useApiHttp({
            url: `marketing/forgot-password`,
            method: "POST",
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
            }
        });

    const VerifyOtpService = () =>
        useApiHttp({
            url: `marketing/verify-otp`,
            method: "POST",
            onSuccess: (data) => {

            }
        });
    const UpdatePasswordService = () =>
        useApiHttp({
            url: `marketing/change-password`,
            method: "POST",
            onSuccess: (data) => {

            }
        });

    const LogoutService = () =>
        useApiHttp({
            url: `marketing/logout`,
            method: "GET",
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
                dispatch(logout());
                navigate("/operations/login");
            }
        });


    return {
        LoginService,
        SendOtpService,
        VerifyOtpService,
        UpdatePasswordService,
        LogoutService,
    }

}

export default LoginServices