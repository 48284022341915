import React, { useEffect, useState } from "react"
import classes from "./ViewMyOrder.module.css"
import { useNavigate, useParams } from "react-router-dom"
import SubHeaderNewPage from "../../features/Header/SubHeaderNewPage"
import acknowlegment from "../../assets/image/svg/orderacknowledgement.svg"
import CustomButton from "../../components/UI/Button/Button"
import useApiHttp from "../../hooks/ues-http"
import CustomTable from "../../components/UI/Table/Table"
import CustomizedStepper from "../../components/UI/Stepper/StepperGreen"
import { toast, ToastContainer } from "react-toastify"
import { handleFileDownload } from "../../utils/helper"
import ComplaintModal from "../../features/RaiseComplaint/Modal/ComplaintModal"

const ViewMyOrder = ({ data }) => {
  const { token } = useParams()
  const navigator = useNavigate()
  const [orderDitails, setOrderDitails] = useState([])
  const [open, setOpen] = useState(false)

  console.log("orderDitails", orderDitails)

  const tabs = [
    { title: "My Orders", path: "/myorders" },
    { title: ">", path: `/view_my_order/${token}` },
    { title: `${orderDitails?.order_id}`, path: `/view_my_order/${token}` },
  ]

  const {
    isLoading: OrderLoading,
    success: OrderSucces,
    error: OrderError,
    sendRequest: OrderRequest,
  } = useApiHttp()

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const OrderRequestHandle = () => {
    OrderRequest(
      {
        url: `dealer/my-order/view`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        setOrderDitails(data.data)
      }
    )
  }
  const repeadOrderRequestHandle = () => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          setTimeout(() => {
            navigator("/shopnow")
          }, 1000)
        }
      }
    )
  }

  useEffect(() => {
    if (repeadOrderSucces !== "") {
      toast.success(repeadOrderSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (repeadOrderError !== "") {
      toast.error(repeadOrderError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [repeadOrderSucces, repeadOrderError])

  useEffect(() => {
    OrderRequestHandle()
  }, [token])

  const columns = [
    {
      name: "S.No",
      selector: row => row.sl_no,
      cell: row => <div className={classes.textCenter}>{row.sl_no}</div>,
      minWidth: "79px", // compact width
      maxWidth: "79px", // Optionally specify a maximum width
    },
    {
      name: "Product Code",
      selector: row => row.product_code,
      cell: row => <div className={classes.textLeft}>{row.product_code}</div>,
      minWidth: "109px", // Specify the minimum width for the column
      maxWidth: "109px", // Optionally specify a maximum width
    },
    {
      name: "Variant",
      selector: row => row.variant,
      cell: row => <div className={classes.textCenter}>{row.variant}</div>,
      minWidth: "109px", // Specify the minimum width for the column
      maxWidth: "109px", // Optionally specify a maximum width
    },
    {
      name: "Vertical",
      selector: row => row.variant,
      cell: row => <div className={classes.textCenter}>{row.vertical}</div>,
      minWidth: "109px", // Specify the minimum width for the column
      maxWidth: "109px", // Optionally specify a maximum width
    },
    {
      name: "Product Description",
      selector: row => row.name,
      cell: row => <div className={classes.textLeft}>{row.name}</div>,
      minWidth: "200px", // Specify the minimum width for the column
      maxWidth: "200px", // Optionally specify a maximum width
      wrap: true,
    },
    {
      name: "Order Qty",
      selector: row => row.quantity,
      cell: row => <div className={classes.textLeft}>{row.quantity}</div>,
      minWidth: "108px", // Specify the minimum width for the column
      maxWidth: "108px", // Optionally specify a maximum width
    },
    {
      name: "Pending Qty",
      selector: row => row.pending_quantity,
      cell: row => (
        <div className={classes.textLeft}>{row.pending_quantity}</div>
      ),
      minWidth: "106px", // Specify the minimum width for the column
      maxWidth: "114px", // Optionally specify a maximum width
    },
    {
      name: "Rate",
      selector: row => row.rate,
      cell: row => <div className={classes.textRight}>{row.rate}</div>,
      minWidth: "173px", // Specify the minimum width for the column
      maxWidth: "173px", // Optionally specify a maximum width
    },
    {
      name: "Required Date",
      selector: row => row.requested_date,
      cell: row => (
        <div className={classes.textCenter}>{row.requested_date}</div>
      ),
      minWidth: "160px", // Specify the minimum width for the column
      maxWidth: "160px", // Optionally specify a maximum width
    },
    {
      name: "Dispatched Date",
      selector: row => row.available_date,
      cell: row => (
        <div className={classes.textCenter}>{row.available_date}</div>
      ),
      minWidth: "160px", // Specify the minimum width for the column
      maxWidth: "160px", // Optionally specify a maximum width
    },
    {
      name: "Status",
      cell: row => (
        <p
          className={`${classes.lable} ${row.status === 0
              ? classes.Pending
              : row.status === 1
                ? classes.Acknowledged
                : row.status === 2
                  ? classes.Partially_Dispatched
                  : row.status === 3
                    ? classes.Dispatched
                    : classes.Delivered
            }`}
        >
          {row.status === 0
            ? "Pending"
            : row.status === 1
              ? "Acknowledged"
              : row.status === 2
                ? "Partially Dispatched"
                : row.status === 3
                  ? "Completed"
                  : "Delivered"}
        </p>
      ),
      minWidth: "136px", // Specify the minimum width for the column
      maxWidth: "136px", // Optionally specify a maximum width
    },
    {
      name: "Item Value",
      selector: row => row.item_amount,
      cell: row => <div className={classes.textRight}>{row.item_amount}</div>,
      minWidth: "196px", // Specify the minimum width for the column
      maxWidth: "196px", // Optionally specify a maximum width
    },
  ]

  const steps = [
    // "Pending",
    "Order Acknowledged",
    "Partially Dispatched",
    "Completed",
    // "Delivered",
  ]

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }
  const onConfirm = () => {
    setOpen(false) // Close the modal after confirming
  }

  return (
    <>
      <div className={classes.myProfile}>
        <SubHeaderNewPage tabs={tabs} />

        <div className={classes.mrg_top}>
          <div className={`${classes.ditails_box} ${classes.alignment}`}>
            <div className={classes.flex_cont}>
              <h3 className={classes.title}>{orderDitails?.order_id}</h3>
              {orderDitails?.order_status !== 0 &&
                orderDitails?.acknowledgement_document !== "" && (
                  <div
                    className={classes.downlode_compo}
                    onClick={() =>
                      handleFileDownload(
                        orderDitails?.acknowledgement_document,
                        orderDitails?.order_id.toString()
                      )
                    }
                  >
                    <img src={acknowlegment} alt="acknowlegment" />
                    <p>Download Achnowledgement</p>
                  </div>
                )}
            </div>
            <div className={classes.main_contact_persaon}>
              <div className={classes.contact_persaon}>
                <div className={classes.contact_persaon_ditails}>
                  <p>Shipping Address</p>
                  <h4>
                    {`${orderDitails?.shipping_address?.address_line_1}, ${orderDitails?.shipping_address?.address_line_2} ${orderDitails?.shipping_address?.city} ${orderDitails?.shipping_address?.state} ${orderDitails?.shipping_address?.pincode} ${orderDitails?.shipping_address?.country}. +${orderDitails?.shipping_address?.country_code} ${orderDitails?.shipping_address?.mobile_number}`}
                  </h4>
                </div>
                <div className={classes.contact_persaon_ditails}>
                  <p>Billing Address</p>
                  <h4>
                    {`${orderDitails?.billing_address?.address_line_1}, ${orderDitails?.billing_address?.address_line_2} ${orderDitails?.billing_address?.city} ${orderDitails?.billing_address?.state} ${orderDitails?.billing_address?.pincode} ${orderDitails?.billing_address?.country}. +${orderDitails?.billing_address?.country_code} ${orderDitails?.billing_address?.mobile_number}`}
                  </h4>
                </div>
                <div className={classes.contact_persaon_ditails}>
                  <p>SO Number</p>
                  <h4>{orderDitails?.so_number}</h4>
                </div>
                <div className={classes.contact_persaon_ditails}>
                  <p>Dealer Reference Number</p>
                  <h4>{orderDitails?.dealer_reference_number}</h4>
                </div>
                {/* <div className={classes.contact_persaon_ditails}>
                  <p>
                    {orderDitails?.order_status === 3
                      ? "Delivered Date"
                      : "Estimated Delivery Date"}
                  </p>
                  {orderDitails?.order_status === 3 ? (
                    <h4>
                      {orderDitails?.delivered_date
                        ? orderDitails?.delivered_date
                        : "-"}
                    </h4>
                  ) : (
                    <h4>
                      {orderDitails?.estimated_delivery_date
                        ? orderDitails?.estimated_delivery_date
                        : "-"}
                    </h4>
                  )}
                </div> */}
                <div className={classes.contact_persaon_ditails}>
                  <p>Item Total Value</p>
                  <h4>
                    ₹ {orderDitails?.total_amount}
                    {/* {Number(orderDitails?.total_amount).toLocaleString("en-IN")} */}
                  </h4>
                </div>
                {/* <div className={classes.contact_persaon_ditails}>
                  <p>PO number</p>
                  <h4>{orderDitails?.po_number}</h4>
                </div> */}
              </div>
              <div className={classes.right_side}>
                {orderDitails?.order_status === 3 && (
                  <CustomButton
                    variant="outlined"
                    customColor="#32349b"
                    custmstyle={{
                      padding: "10px 7px",
                      width: "200px",
                      marginTop: "20px",
                      borderRadius: "8px",
                      marginBottom: "1rem",
                    }}
                    onClick={() => repeadOrderRequestHandle()}
                  >
                    Repeat Order
                  </CustomButton>
                )}
                {orderDitails?.order_status === 3 && (
                  <div className={classes.contact_persaon_ditails}>
                    <p>
                      If you have an issue with this order,{" "}
                      <span className={classes.complaint} onClick={handleOpen}>
                        Raise a Complaint
                      </span>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {orderDitails?.order_status !== 4 && (
            <div className={`${classes.ditails_box} ${classes.alignment}`}>
              <p className={`${classes.stpper_title}`}>Track Order</p>
              {orderDitails?.is_acknowledged === 1 && (
                <div className={classes.stpper_sub_box}>
                  <p className={`${classes.stpper_sub_title}`}>
                    Order acknowledged on{" "}
                    {orderDitails?.order_acknowledged_date}
                  </p>
                </div>
              )}

              <div className={classes.stpper_box}>
                <CustomizedStepper
                  steps={steps}
                  activeStep={
                    orderDitails?.order_status
                      ? orderDitails?.order_status - 1
                      : 0
                  } // use minus 1 to show order acknowledged as 1st status
                />
              </div>
            </div>
          )}

          <CustomTable
            className={classes.table}
            data={orderDitails?.order_details}
            columns={columns}
            children={
              <div className={classes.header_table}>
                <h3> Order Details</h3>
              </div>
            }
            subFooterContent={
              <div className={classes.card_box_total}>
                <div
                  className={classes.total_card}
                  style={{ marginRight: "5rem", marginTop: "1rem" }}
                >
                  {/* <div className={classes.tax}>
                    <div className={classes.flex}>
                      <p>Order Total Value</p>
                      <p>
                        ₹{" "}
                        {orderDitails?.total_item_amount
                          ? (orderDitails?.total_item_amount).toLocaleString(
                              "en-IN"
                            )
                          : 0}
                      </p>
                    </div>
                    <div className={classes.flex}>
                      <p>
                        Total Tax ({orderDitails?.total_tax_percentage || 0}%)
                      </p>
                      <p>
                        ₹{" "}
                        {orderDitails?.total_tax_amount
                          ? (orderDitails?.total_tax_amount).toLocaleString(
                              "en-IN"
                            )
                          : 0}
                      </p>
                    </div>
                  </div> */}
                  <div className={classes.total}>
                    <div className={classes.flex}>
                      <p>Order Value</p>
                      <p>
                        {" "}
                        ₹{" "}
                        {orderDitails?.total_amount
                          ? (orderDitails?.total_amount).toLocaleString("en-IN")
                          : 0}
                      </p>
                    </div>
                    <div className={classes.flex_end}>
                      <span>{"(Excluding Taxes)"}</span>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
      {open && (
        <ComplaintModal
          open={open}
          onConfirm={onConfirm}
          handleClose={handleClose}
          po_number={orderDitails?.order_id}
        />
      )}

      <ToastContainer />
    </>
  )
}

export default ViewMyOrder
