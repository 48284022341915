import React, { useEffect, Suspense } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useLocation, useNavigate } from 'react-router-dom';


const WithAuthentication = (props) => {
    const { AuthCheckElement, checkType = true, ...otherProps } = props;
    const NewComp = () => {
        let token = secureLocalStorage.getItem("marketingBearerToken");
        let isValidMarketingUser = (token !== '' && token !== null && token !== undefined) ? true : false;
        const { pathname } = useLocation();
        const navigate = useNavigate();
        useEffect(() => {
            if (checkType === true) {
                if (pathname.includes('/operations') && !isValidMarketingUser) {
                    navigate("/operations/login")
                }
            } else {
                if (pathname.includes('/operations') && isValidMarketingUser) {
                    navigate("/operations/");
                }
            }

        }, [pathname, isValidMarketingUser])

        return checkType ? token ? <Suspense >{AuthCheckElement}</Suspense> : null : <Suspense >{AuthCheckElement}</Suspense>;

    }
    return <NewComp />
}

export default WithAuthentication