import { useState, useCallback } from 'react';
import secureLocalStorage from "react-secure-storage"
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'

const useAxiosApi = (incomingConfig) => {
    let apiCallConfig = incomingConfig || {};
    let stringifyBody = (apiCallConfig?.stringify !== undefined && apiCallConfig?.stringify !== '') ? apiCallConfig?.stringify : true;
    let onSuccessCallBack = apiCallConfig?.onSuccess ? apiCallConfig?.onSuccess : null;
    let onErrorCallBack = apiCallConfig?.onError ? apiCallConfig?.onError : null;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const AdminbearerToken = secureLocalStorage.getItem('marketingBearerToken');
    const sendRequest = useCallback(async (requestConfig, applyData = null, errorCallback = null) => {
        stringifyBody = (requestConfig?.stringify !== undefined && requestConfig?.stringify !== '') ? requestConfig?.stringify :  stringifyBody;
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        try {
            // const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/${requestConfig?.url ? requestConfig?.url : apiCallConfig?.url}`, {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_API_URL}/${requestConfig?.url ? requestConfig?.url : apiCallConfig?.url}`,
                method: requestConfig?.method ? requestConfig?.method : apiCallConfig?.method ? apiCallConfig?.method : 'GET',
                headers: AdminbearerToken
                    ? {
                        Authorization: `Bearer ${AdminbearerToken}`,
                        "Content-Type": "application/vnd.api+json",
                        "Accept": "application/vnd.api+json",
                        ...(requestConfig?.headers ? requestConfig?.headers : apiCallConfig?.headers ? apiCallConfig?.headers : {})
                    }
                    : {
                        "Content-Type": "application/vnd.api+json",
                        "Accept": "application/vnd.api+json",
                        ...(requestConfig?.headers ? requestConfig?.headers : apiCallConfig?.headers ? apiCallConfig?.headers : {})
                    },
                data: requestConfig?.body ? stringifyBody ? JSON.stringify(requestConfig.body) : requestConfig.body : apiCallConfig?.body ? stringifyBody ? JSON.stringify(apiCallConfig?.body) : apiCallConfig?.body : null,
                ...(apiCallConfig?.other_props || {}),
                ...(requestConfig?.other_props || {})
            });
            // console.log(response.ok);
            const data = await response.data;
            if (data.status_code === 200 || data.status_code === 201) {
                setSuccess(data.message);
                setIsSuccess(true);
                setIsError(false);
                onSuccessCallBack && onSuccessCallBack?.(data, response)
                applyData && applyData?.(data);
            }
            if (data.status_code !== 200 && data.status_code !== 201) {
                let errorData = {
                    response:{
                        data:{
                            status_code:data.status_code,
                            message:data.message
                        }
                    }
                }

                throw errorData;
                // throw data;
            }
            // onSuccessCallBack && onSuccessCallBack?.(data)
            // applyData && applyData?.(data);

        } catch (err) {
            
            setIsError(true);
            setIsSuccess(false);

            toast.error((err.response.data.message || 'Something went wrong!'), {
                position: "top-center"
            });

            if (err.response.data.status_code === 401) {
                secureLocalStorage.removeItem('marketingBearerToken')
                navigate('/operations/login')
            }
            onErrorCallBack && onErrorCallBack?.(err);
            errorCallback && errorCallback(err);

            setError(err.message || 'Something went wrong!');
        } finally {
            setIsLoading(false);

        }
    }, [AdminbearerToken, apiCallConfig?.url]);
    return {
        isLoading,
        isSuccess,
        isError,
        success,
        error,
        sendRequest,
    };
};
export default useAxiosApi;