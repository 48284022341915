import React from 'react'

const PersonalDetailsField = (props) => {
    const { fieldHeader, fieldDet } = props;


    return (
        <div>
            <p style={{ fontSize: "12px", ...(fieldHeader?.style || {}) }}>{fieldHeader?.name || "Field Name"}</p>
            <p style={{ fontSize: "14px", ...(fieldDet?.style || {}) }}>{fieldDet?.name || ''}</p>
        </div>
    )
}

export default PersonalDetailsField