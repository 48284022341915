import React, { useEffect, useState } from "react"
import classes from "./Searching.module.css"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"
import SearchComponent from "../../../components/UI/ShopnowSearch/Search"
import { useDispatch, useSelector } from "react-redux"
import {
  setSearchTerm,
  clearSuggestions,
  searchByItemCode,
  clearCategory,
  clearSubCategory,
  setCategory,
  setSubCategory,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"

const SingleSearching = ({ Loading }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)

  // Handle search input change
  const handleSearch = term => {
    if (term !== " ") {
      dispatch(searchByItemCode(term))
    }
    if (term === "") {
      dispatch(clearSuggestions())
    }
  }

  return (
    <div className={classes.container}>
      <div className={`${classes.search_box}`}>
        <SearchComponent
          placeholder="Search"
          fullWidth={true}
          value={data.searchbyitemcode}
          onChange={e => handleSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

export default SingleSearching
