import React, { useEffect, useState } from "react"
import classes from "./RecentOrders.module.css"
import OrderCard from "../../components/UI/Card/OrderCard"
import useApiHttp from "../../hooks/ues-http"
import { toast, ToastContainer } from "react-toastify"
import { useDispatch } from "react-redux"
import { fetchList } from "../../services/storeSlice/shopNowSlice"
const RecentOrders = () => {
  const dispatch = useDispatch()
  const [orderData, setOrderData] = useState([])

  const {
    isLoading: OrderCardLoading,
    success: OrderCardSucces,
    error: OrderCardError,
    sendRequest: OrderCardRequest,
  } = useApiHttp()
  const OrderCardHandleSelect = () => {
    OrderCardRequest(
      {
        url: `dealer/shop-now/recent-orders`,
        method: "POST",
        body: {
          type: "",
        },
      },
      data => {
        setOrderData(data.data)
      }
    )
  }
  useEffect(() => {
    OrderCardHandleSelect()
  }, [])

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const repeadOrderRequestHandle = token => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          dispatch(fetchList())
        }
      }
    )
  }
  // useEffect(() => {
  //   if (repeadOrderSucces !== "") {
  //     toast.success(repeadOrderSucces, {
  //       autoClose: 2000, // Close after 2 seconds
  //     })
  //   }
  //   if (repeadOrderError !== "") {
  //     toast.error(repeadOrderError, {
  //       autoClose: 2000, // Close after 2 seconds
  //     })
  //   }
  // }, [repeadOrderSucces, repeadOrderError])

  return (
    <div className={classes.recent_Orders}>
      <div className={classes.recent_Orders_list}>
        {orderData.map((item, index) => (
          <OrderCard
            status={item.order_status}
            order_date={item.order_date}
            etd={item.delivered_date}
            id={item.order_id}
            items={item.total_items}
            rate={item.total_amount}
            data={item}
            type="recent_order"
            cta={() => repeadOrderRequestHandle(item.token)}
            key={index}
          />
        ))}
      </div>
      <ToastContainer />
    </div>
  )
}

export default RecentOrders
