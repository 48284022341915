import useApiHttp from "../../hooks/use-http";
import useAxiosApi from "../../hooks/useAxiosApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const InventoryServices = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    const FetchCategories = () =>
        useApiHttp({
            url: `marketing/category-list`,
            // method: "POST",
        });

    const FetchSubCategories = () =>
        useApiHttp({
            url: `marketing/subcategory-list`,
            method: "POST",
        });

    const FetchProducts = () =>
        useApiHttp({
            url: `marketing/inventory-list`,
            method: "POST",
            // onSuccess: (data) => {
            //     toast.success((data.message || 'Success!'), {
            //         position: "top-center"
            //     });
            //     dispatch(login(data.data));
            //     navigate("/marketing/product");
            // }
        });

    const UpdateSingleProductStock = () =>
        useApiHttp({
            url: "marketing/update-stock",
            method: "POST",
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
            }

        });
    const UpdateProductStockCsv = () =>
        useAxiosApi({
            url: "marketing/inventory-bulk-stock-upload",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                // "Accept": "",
            },
            stringify: false,
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
            }

        })

    const DownloadTable = () =>
        useApiHttp({
            url: `marketing/inventory-list-download`,
            method: "POST",
            responseType: "binary",

        });



    return {
        FetchCategories,
        FetchSubCategories,
        FetchProducts,
        UpdateSingleProductStock,
        UpdateProductStockCsv,
        DownloadTable
    }

}

export default InventoryServices