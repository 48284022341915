import { styled, TextField } from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"


const StyledDatePicker = styled(DatePicker)({

})

const FromToDatePicker = props => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <StyledDatePicker
          {...props}
          sx={{
            // Set desired border radius
            ".MuiStack-root:has(&)": {
              paddingTop: "6px"
            }

          }}
          slotProps={{
            textField: {
              onBlur: props.onBlur || null,
              error: props.error,
              helperText: props.error ? props.helperText : null,
              sx: {
                width: "150px", // Custom width of DatePicker
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: props?.height ? props?.height : "40px", // Custom height of DatePicker
                },
                "& .MuiInputLabel-root": {
                  paddingBottom: "8px",
                  border: "1px solid black",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 12px) scale(1)",
                  transition: "all 0.3s ease",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  transform: "translate(14px, -6px) scale(0.75)",
                },
                "& .MuiInputLabel-root.MuiFormLabel-filled": {
                  transform: "translate(14px, -6px) scale(0.75)",
                },
                "& .MuiOutlinedInput-input": {
                  paddingTop: "12px",
                  paddingBottom: "8px",
                },
              },

              inputProps: {
                readOnly: true, // Prevent manual typing
              },
            },

          }}

        />
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default FromToDatePicker
