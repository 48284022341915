import React from "react"
import styled from "styled-components"
import { NavLink, useLocation } from "react-router-dom"

const TabContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  border-radius: 1px;
  overflow-x: scroll;
  margin-top: 23.5px;
  gap: 0.3rem;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 1rem;
  align-items: center;
  margin-top: 6rem;
  &::-webkit-scrollbar {
    display: none;
  }
`

const TabButton = styled.button`
  height: 100%;
  outline: none;
  cursor: pointer;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  border: none;
  font-size: 14px;
  align-items: center;
  position: relative;
  background: transparent;
  padding: 0;
  &:focus {
    outline: none;
  }
`

const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  margin: 0px 7px;
  font-size: 14px;
  font-family: ${props =>
    props.active ? "var(--font-semibold)" : "var(--font-regular)"};
  color: ${props => (props.active ? "var(--primary-blue)" : "#000")};
  transition: 0.2s;
  gap: 1rem;
`

export default function SubHeaderNewPage({ tabs }) {
  const location = useLocation()

  return (
    <TabContainer>
      {tabs.map((tab, index) => {
        const isActive = location.pathname.includes(
          tab.path.replace(":token", "")
        ) // Check if current path matches tab path
        return (
          <NavLink to={tab.path} key={index}>
            <TabButton>
              <Title active={isActive}>{tab.title}</Title>
            </TabButton>
          </NavLink>
        )
      })}
    </TabContainer>
  )
}
