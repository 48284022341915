import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const DealerServices = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();


    const FetchDealers = () =>
        useApiHttp({
            url: `marketing/dealer-list`,
            method: "POST",
            // onSuccess: (data) => {
            //     toast.success((data.message || 'Success!'), {
            //         position: "top-center"
            //     });
            //     dispatch(login(data.data));
            //     navigate("/marketing/product");
            // }
        });

    const DownloadTable = () =>
        useApiHttp({
            url: `marketing/dealer-list/download`,
            method: "POST",
            responseType: "binary",

        });



    return {

        FetchDealers,
        DownloadTable
    }

}

export default DealerServices