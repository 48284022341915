import React from "react"
import MyOrdersMain from "../../features/MyOrders/MyOrdersMain"

const MyOrders = () => {
  return (
    <div>
      <MyOrdersMain />
    </div>
  )
}

export default MyOrders
