import React, { useEffect, useState } from "react"
import classes from "./RecentOrders.module.css"
import CarouselContainer from "../../components/UI/Carousel/Carousel"
import OrderCard from "../../components/UI/Card/OrderCard"
import useApiHttp from "../../hooks/ues-http"
import { fetchList } from "../../services/storeSlice/shopNowSlice"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const RecentOrders = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState([])

  const {
    isLoading: OrderCardLoading,
    success: OrderCardSucces,
    error: OrderCardError,
    sendRequest: OrderCardRequest,
  } = useApiHttp()
  const OrderCardHandleSelect = () => {
    OrderCardRequest(
      {
        url: `dealer/shop-now/recent-orders`,
        method: "POST",
        body: {
          type: "home",
        },
      },
      data => {
        setOrderData(data.data)
      }
    )
  }
  useEffect(() => {
    OrderCardHandleSelect()
  }, [])

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const repeadOrderRequestHandle = token => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          dispatch(fetchList())
          navigate("/shopnow")
        }
      }
    )
  }
  useEffect(() => {
    if (repeadOrderSucces !== "") {
      toast.success(repeadOrderSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (repeadOrderError !== "") {
      toast.error(repeadOrderError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [repeadOrderSucces, repeadOrderError])

  return (
    <>
      {orderData.length > 0 && (
        <>
          <h3 className={classes.title}>Recent Orders</h3>
          <CarouselContainer
            responsive={responsive}
            className="recenOrder"
            itemClass={"carouselItem"}
            showArrows={true}
            data={orderData}
          >
            {orderData.map((item, index) => (
              <OrderCard
                status={item.order_status}
                order_date={item.order_date}
                etd={item.delivered_date}
                id={item.order_id}
                items={item.total_items}
                rate={item.total_amount}
                data={item}
                key={index}
                cta={() => repeadOrderRequestHandle(item.token)}
                resetAllData={() => OrderCardHandleSelect()}
                type="recent_order"
                page="home"
              />
            ))}
          </CarouselContainer>
        </>
      )}
    </>
  )
}

export default RecentOrders
