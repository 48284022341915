import React from "react"
import CsvDragAndDropUpload from "./CsvUpload"
import classes from "./CsvOrderPage.module.css"

const CsvOrderPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <div className={classes.drag_box}>
        <CsvDragAndDropUpload />
      </div>
    </div>
  )
}

export default CsvOrderPage
