import * as React from "react"
import { styled, alpha } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import EditIcon from "@mui/icons-material/Edit"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Profile from "../../../assets/image/svg/profile.svg"
import logout from "../../../assets/image/svg/logout.svg"
import { useDispatch } from "react-redux"
import { authActions } from "../../../services/storeSlice/authSlice"
import { useNavigate } from "react-router-dom"
import useApiHttp from "../../../hooks/ues-http"
import Swal from "sweetalert2"

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    padding: 10,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}))

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const userEmail = localStorage.getItem("userEmail")

  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { success: loggedOut, sendRequest: logOut } = useApiHttp()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const Logout = () => {
    setAnchorEl(null)
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out from your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire(
          "Logged out!",
          "You have been logged out successfully.",
          "success"
        ).then(() => {
          logOut(
            {
              url: `dealer/logout`,
              method: "GET",
            },
            () => {
              dispatch(authActions.logout())
              localStorage.removeItem("userEmail")
              localStorage.removeItem("userName")
              localStorage.removeItem("contactName")
              navigate("/login")
            }
          )
        })
      }
    })
  }
  const hadleProfile = () => {
    navigate("/myprofile")
    setAnchorEl(null)
  }
  return (
    <div>
      <p style={{ fontSize: "12px", fontWeight: "400" }}>Welcome back!</p>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          padding: "0px",
          background: "transparent",
          color: "#363636",
          textTransform: "inherit",
          fontFamily: "var(--font-Medium)",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        {userEmail || "Welcome"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={hadleProfile} disableRipple sx={{ gap: "1rem" }}>
          <img src={Profile} alt="Profile" />
          My Profile
        </MenuItem>
        <MenuItem onClick={Logout} disableRipple sx={{ gap: "1rem" }}>
          <img src={logout} alt="Profile" />
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
