import React from 'react'
import classes from './dealerList.module.css'
import DealerServices from './dealerList.service'
import { Box, Divider, Paper, Stack } from '@mui/material';
import CustomDataGrid from '../../components/UI/customDataGrid/CustomDataGrid';

const DealerList = () => {
    const { FetchDealers, DownloadTable } = DealerServices();
    const dealersListColumn = [
        {
            field: "slno",
            headerName: "S.No",
            sortable: false,
            width: 70,
        },
        {
            field: "dealer_id",
            headerName: "Dealer ID",
            sortable: false,
            width: 150,
            //   renderCell: (params) => (
            //     <span onClick={() => (viewProductDetail(params.row?.token))} className={classes.product_code_text}>
            //       {params.formattedValue}
            //     </span>
            //   )
        },
        {
            field: "dealer_name",
            headerName: "Dealer Name",
            sortable: true,
            width: 250,
        },
        {
            field: "contact_name",
            headerName: "Contact Person",
            sortable: false,
            width: 250,
        },
        {
            field: "mobile_number",
            headerName: "Mobile Number",
            sortable: false,
            width: 200,
        },
        {
            field: "alter_contact_number",
            headerName: "Alternate Number",
            sortable: false,
            width: 200,
        },
        {
            field: "email_address",
            headerName: "Email Address",
            sortable: false,
            width: 200,
            // renderCell: (params) => {
            //   const parsedDate = new Date(params?.row.applied_date);
            //   const day = parsedDate.getDate().toString().padStart(2, "0");
            //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
            //   const year = parsedDate.getFullYear();
            //   return `${day}/${month}/${year}`;
            // },
            // renderCell: (params) => dateConvert(params?.row.applied_date),
        },
        {
            field: "location",
            headerName: "Location",
            sortable: false,
            width: 250,
            //   renderCell: (params) => (
            //     <StatusWidgetLable class={params.row.status_color}>
            //       {params.formattedValue}
            //     </StatusWidgetLable>
            //   )
        },

    ];

    return (
        <>
            <Stack>
                <h6 className={classes.header_text_class}>Dealers Master</h6>
                <Divider sx={{ marginTop: "16px", marginBottom: "15px" }} />
            </Stack>
            <Box sx={{ width: '100%', mt: 2 }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    {/* <div className={classes.table_header_container}>
            <p className={classes.table_header_title}>Products List</p>
            <div className={classes.table_header_total_search}>
              <p className={classes.table_header_sub_title}>164 Products</p>
              <div className={classes.icons_container}>
                <img src={searchIcon} width={24} height={24} />
                <div className={classes.divider}></div>
                    <img src={downloadIcon} width={24} height={24}/>
              </div>
            </div>
          </div> */}
                    <CustomDataGrid
                        adornmentType={["search", "download_menu"]}
                        otherApiRequests={() => DownloadTable()}
                        postAPI={true}
                        pagination
                        ApiRequestBody={{

                        }}
                        columns={dealersListColumn}
                        ApiRequest={() => FetchDealers()}
                        typography={[
                            { type: "title", children: "Dealer List" },
                            { type: "caption", children: "Dealers" }
                        ]}
                    />
                </Paper>


            </Box>
        </>
    )
}

export default DealerList