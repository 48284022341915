import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import classes from "./customDataGrid.module.css";
// import {SearchField,} from "../formElements/formElements.component";
import searchIcon from '../../../assets/image/svg/order_search.svg'
import SearchExpand from "../SearchExpand/SearchExpand";
import MenuPopup from "../TableDownloadPopup/MenuPopup";


// import Loader from "../../layouts/loader/loader";

const CustomDataGrid = (props) => {
    const {
        titleView = true,
        columns,
        height,
        width,
        adornmentType = [],
        otherApiRequests,
        ApiRequest,
        ApiRequestBody = {},
        tableHasAdditionalData,
        tableDataKey,
        typography,
        keyName,
        pagination,
        postAPI = false,
        selectedTab,
        isStatusChanged,
        apiResponce,
        isServerSide = true,
        // sortModel,
    } = props;

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        root: {
            height: "100%",
            width: width || "100%",
        },
        fontSize: "14px",

        color: "#212121",
        fontFamily: "var(--font-regular)",
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        border: 0,

        "&::-webkit-scrollbar": {
            display: "block !important",
            width: "10px",
            height: "10px",
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(240, 240, 240, 0.8)",
            borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            // backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(240, 240, 240, 0.8)",
            borderRadius: "8px",
        },

        // "& .MuiDataGrid-columnHeaderTitleContainer": {
        //     boxShadow: "0px 10px 3px -2px rgba(255,255,255)"
        // },
        // "& .MuiDataGrid-row--borderBottom": {
        //     borderBottomColor: "transparent"
        // },
        "& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor": {
            borderBottom: "none"
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        // "& .MuiDataGrid-virtualScrollerContent":{

        // },
        "& .MuiDataGrid-columnHeaders": {
            borderRadius: "0",
            minHeight: "56px !important",
            padding: "16px 0px 16px ",
            backgroundColor: "#fff",
            borderTop: "1px solid #d8dbdd",
            borderBottom: "1px solid #d8dbdd",
            borderLeft: "1px solid #d8dbdd",
            borderRight: "1px solid #d8dbdd",
            // boxShadow: "0px 10px 3px -2px rgba(255,255,255)"

        },
        "& .MuiDataGrid-columnHeader": {
            borderRight: 0,
            height: "unset !important",
            lineHeight: 1.2,
            padding: 0,
        },
        "& .MuiDataGrid-cell--withRenderer:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
            border: "1px solid #d8dbdd",
            borderTop: "none",
            backgroundColor: "white",
            borderRadius: "0 0 8px 8px",
            minHeight: "56px",
        },
        // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        //   display:"unset"
        // },
        // "& .MuiDataGrid-root::-webkit-scrollbar": {
        //   display:"unset"
        // },
        "& .MuiDataGrid-virtualScrollerContent": {
            height: `${height ? height + "!important" : "auto"} `,
            maxHeight: "558px !important",
            // overflowY: "auto",
            // overflowY: "scroll"
        },

        "& .MuiDataGrid-row": {
            minHeight: "56px !important",
            maxHeight: "unset !important",
            borderBottom: "1px solid  #d8dbdd",
        },
        "& .MuiDataGrid-row:hover": {
            backgroundColor: "unset !important",
        },
        "& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type": {
            paddingLeft: "25px",
        },
        "& .MuiDataGrid-cell": {
            minHeight: "56px !important",
            padding: ".5rem .5rem .5rem 0.8rem ",
            border: "none",
            maxHeight: "unset !important",
        },
        "& .MuiDataGrid-cell.lengthy_data_cell": {
            whiteSpace: "normal !important",
        },
        "& .MuiDataGrid-cell:focus ,& .MuiDataGrid-columnHeaderTitleContainer:focus , & .MuiDataGrid-columnHeader:focus":
        {
            outline: "none",
        },

        "& .MuiPaginationItem-root": {
            borderRadius: 0,
        },

        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "var(--ffy-medium)",
            fontSize: "var(--fsz-xx-small)",
            borderLeft: "1px solid #d8dbdd",
            textTransform: "capitalize",
            paddingLeft: "9px",
        },
        "& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnHeaderTitle":
        {
            paddingLeft: "25px",
            borderLeft: "none",
        },
        "& .MuiDataGrid-footerContainer": {
            border: "none",
            display: pagination ? "block" : "none",
            backgroundColor: "var(--clr-white-200)",
        },
        /*filler takes space of height of datatgrid and fills it with a row of balance height
          which will affect no row and less row data*/
        '& .MuiDataGrid-filler': {
            border: 'none',  // Removes any unwanted borders on the filler
            backgroundColor: 'transparent',
            maxHeight: "62px !important",
            '--rowBorderColor': 'transparent !important' // Ensures no background overlap
        },
    }));

    const [searchValue, setSearchValue] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [sortModel, setSortModel] = useState([]);
    const [tabelDataValues, setTabelDataValues] = useState([]);

    const [dataCount, setDataCount] = useState(0);
    const { sendRequest: otherRequests } = otherApiRequests?.() || {}


    const handleApiDataChange = (resData) => {
        let tableData = [];
        if (postAPI && isServerSide) {
            setDataCount(resData?.data?.iTotalRecords)
            if (typeof apiResponce === "function" && resData) {
                apiResponce(resData);
            }
            if (tableHasAdditionalData) {

                tableData =
                    resData?.data?.[tableDataKey]?.map((item, i) => ({
                        ...item,
                        id: i + 1,
                    })) ||
                    // ?.filter((item) => {
                    //   // return item[keyName]
                    //   // ?.toLowerCase()
                    //   // ?.includes(searchValue.toLocaleLowerCase())
                    //   return Object.values(item).some((value) =>
                    //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                    //   );
                    // })
                    [];
                setTabelDataValues(tableData);
            } else {
                tableData =
                    resData?.data?.aaData?.map((item, i) => ({ ...item, id: i + 1 })) ||
                    // ?.filter((item) => {
                    //   // return item[keyName]
                    //   // ?.toLowerCase()
                    //   // ?.includes(searchValue.toLocaleLowerCase())
                    //   return Object.values(item).some((value) =>
                    //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                    //   );
                    // })
                    [];
                setTabelDataValues(tableData);
            }
        } else if (!postAPI && isServerSide) {
            setDataCount(resData?.data?.iTotalRecords)
            if (typeof apiResponce === "function" && resData) {
                apiResponce(resData);
            }
            tableData = resData?.data?.aaData?.map((item, i) => ({ ...item, id: i + 1 }))
                ?.filter((item) => {
                    // return item[keyName]
                    //   ?.toLowerCase()
                    //   ?.includes(searchValue.toLocaleLowerCase());
                    return Object.values(item).some((value) =>
                        String(value).toLowerCase().includes(searchValue.toLowerCase())
                    );
                }) || [];
            setTabelDataValues(tableData);
        } else if (!isServerSide) {
            setDataCount(resData?.data.length)
            if (typeof apiResponce === "function" && resData) {
                apiResponce(resData);
            }
            if (tableHasAdditionalData) {
                tableData =
                    resData?.data?.[tableDataKey]?.map((item, i) => ({
                        ...item,
                        id: i + 1,
                    })) ||
                    // ?.filter((item) => {
                    //   // return item[keyName]
                    //   // ?.toLowerCase()
                    //   // ?.includes(searchValue.toLocaleLowerCase())
                    //   return Object.values(item).some((value) =>
                    //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                    //   );
                    // })
                    [];
                setTabelDataValues(tableData);
            } else {
                tableData =
                    resData?.data?.map((item, i) => ({ ...item, id: i + 1 })) ||
                    // ?.filter((item) => {
                    //   // return item[keyName]
                    //   // ?.toLowerCase()
                    //   // ?.includes(searchValue.toLocaleLowerCase())
                    //   return Object.values(item).some((value) =>
                    //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                    //   );
                    // })
                    [];
                setTabelDataValues(tableData);
            }
        }

    }
    const handleSortModelChange = (model) => {
        setSortModel(model);
        setPaginationModel({
            pageSize: 10,
            page: 0,
        });
        const sortField = model[0]?.field;
        const sortDirection = model[0]?.sort;

        const obj = {
            ...ApiRequestBody,
            sort_column: sortField || "0",
            sort_dir: sortDirection || "desc",
            // month: selectedDate.$M ? selectedDate.$M + 1 : "",
            // year: selectedDate.$y ? selectedDate.$y : "",
            search_value: "",
            per_page: "10",
            page: "0",
        };
        if (postAPI) {
            getTableData({
                body: obj
            }, (resData) => {
                handleApiDataChange(resData)

            });
        }
    };

    const handleSearch = (searchValue) => {
        setPaginationModel({
            pageSize: 10,
            page: 0,
        });
        const searchWord = searchValue || "";
        const obj = {
            ...ApiRequestBody,
            search_value: searchWord,
            per_page: "10",
            page: "0",
            sort_column: "",
            sort_dir: "desc",
        };
        if (postAPI) {
            getTableData({
                body: obj
            }, (resData) => {
                handleApiDataChange(resData)

            });
            // mutate(obj, {
            //     onSuccess: (data) => {
            //         setDataCount(data?.data?.data?.iTotalRecords);
            //     },
            // });
        }
    };

    const handlePaginationChange = (e) => {
        const obj = {
            ...ApiRequestBody,
            search_value: searchValue,
            per_page: e.pageSize,
            page: e.page,
            sort_column: "",
            sort_dir: "desc",
        };
        if (postAPI) {
            getTableData({
                body: obj
            }, (resData) => {
                handleApiDataChange(resData)

            });
        }
    };


    const handleFileDownload = (url, fileName) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then((blob) => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", fileName); // Specify the file name
                link.setAttribute("target", "_self");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
            })
            .catch((error) => console.error("Error downloading file:", error));
    };

    const handleFileDownloadfromObject = (response, defaultFileName, fileType) => {
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract filename from Content-Disposition header
            const contentDisposition = response.headers.get('content-disposition');
            let fileName = `${defaultFileName}.${fileType}`; // Default file name with the correct extension

            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); // Clean up the link after download
            window.URL.revokeObjectURL(url);
        })
            .catch((error) => console.error("Error downloading file:", error));
    };



    const handleDownload = (type) => {
        let bodyObj = {
            ...ApiRequestBody,
            search_value: searchValue,
            per_page: 10,
            page: 0,
            sort_column: "",
            sort_dir: "desc",
        }
        let body = {
            ...bodyObj,
            "selected_tokens": [],
            "type": type, //pdf,csv,xsl

        }
        otherRequests?.({
            body: body
        }, (data) => {
            // handleFileDownload(data.data, typography[0].children)
            handleFileDownloadfromObject(data, typography[0].children, type)
        })
    }

    const Adornment = {
        search: (
            <SearchExpand
                onSearchChange={(word) => {
                    setSearchValue(word);
                    handleSearch(word)

                }}
            />
        ),
        download_menu: (
            <MenuPopup
                onClick={handleDownload}
            />
        )
    };

    // const { data = [], isLoading } = ApiRequest?.() || {};
    // const { sendRequest: getTableData } = ApiRequest?.() || {};
    const { sendRequest: getTableData } = ApiRequest?.() || {};
    // const {
    //     mutate,
    //     data: postApiData,
    //     isLoading: isLoadingPostApi,
    // } = ApiRequest?.() || {};

    useEffect(() => {

        let bodyObj = {
            ...ApiRequestBody,
            search_value: searchValue,
            per_page: 10,
            page: 0,
            sort_column: "",
            sort_dir: "desc",
        }
        setPaginationModel({
            pageSize: 10,
            page: 0,
        });
        setSortModel([]);
        setSearchValue("");
        if (postAPI) {
            getTableData({
                body: bodyObj
            }, (resData) => {
                handleApiDataChange(resData);
                // setDataCount(resData?.iTotalRecords)
                // if (typeof apiResponce === "function" && resData) {
                //     apiResponce(resData);
                // }

                // if (tableHasAdditionalData) {
                //     tableData =
                //     resData?.data?.[tableDataKey]?.map((item, i) => ({
                //             ...item,
                //             id: i + 1,
                //         })) ||
                //         // ?.filter((item) => {
                //         //   // return item[keyName]
                //         //   // ?.toLowerCase()
                //         //   // ?.includes(searchValue.toLocaleLowerCase())
                //         //   return Object.values(item).some((value) =>
                //         //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                //         //   );
                //         // })
                //         [];
                //     setTabelDataValues(tableData);
                // } else {
                //     tableData =
                //     resData?.aaData?.map((item, i) => ({ ...item, id: i + 1 })) ||
                //         // ?.filter((item) => {
                //         //   // return item[keyName]
                //         //   // ?.toLowerCase()
                //         //   // ?.includes(searchValue.toLocaleLowerCase())
                //         //   return Object.values(item).some((value) =>
                //         //     String(value).toLowerCase().includes(searchValue.toLowerCase())
                //         //   );
                //         // })
                //         [];
                //     setTabelDataValues(tableData);
                // }
            })

            // mutate(ApiRequestBody, {
            //     onSuccess: (data) => {
            //         setDataCount(data?.data?.data?.iTotalRecords);
            //         if (typeof apiResponce === "function" && data) {
            //             apiResponce(data);
            //         }
            //     },
            // });
        } else {
            // if (typeof apiResponce === "function") {
            //     apiResponce(data);
            // }
            getTableData({}, (resData) => {
                handleApiDataChange(resData);
                // setDataCount(resData?.iTotalRecords)
                // if (typeof apiResponce === "function" && resData) {
                //     apiResponce(resData);
                // }

                // tableData = resData?.aaData?.map((item, i) => ({ ...item, id: i + 1 }))
                //                 ?.filter((item) => {
                //                     // return item[keyName]
                //                     //   ?.toLowerCase()
                //                     //   ?.includes(searchValue.toLocaleLowerCase());
                //                     return Object.values(item).some((value) =>
                //                         String(value).toLowerCase().includes(searchValue.toLowerCase())
                //                     );
                //                 }) || [];
                //         setTabelDataValues(tableData);
            })
        }
    }, [selectedTab, isStatusChanged]);

    const renderTypography = (info) => {
        const { type, children, subChild } = info;
        const typography = {
            title: (
                <div>
                    <span
                        className={`tt-cpt ${classes.table_header_title}`}
                        style={{ fontSize: "20px", textTransform: "capitalize" }}
                        key={children}
                        children={children}
                    />{" "}
                    {subChild && (
                        <span
                            className="tt-cpt"
                            style={{ fontSize: "14px", textTransform: "capitalize" }}
                            key={children}
                            children={subChild}
                        />
                    )}
                </div>
            ),
            caption: (
                <span
                    className={`tt-cpt ${classes.table_header_sub_title}`}
                    key={children}
                    // children={`${postAPI
                    //     ? tableHasAdditionalData
                    //         ? postApiData?.data?.data?.iTotalRecords || 0
                    //         : Array.isArray(postApiData?.data?.data) || 0
                    //             ? postApiData?.data?.data?.iTotalRecords || 0
                    //             : 0
                    //     : data?.length || 0
                    //     }  ${children}`}
                    children={`${dataCount || 0}  ${children}`}
                />
            ),
            subTitle: <p className="tt-cpt" key={children} children={children} />,
        };

        return typography[type];
    };

    // useEffect(() => {
    //     let tableData = [];

    //     if (!postAPI) {
    //         tableData =
    //             data
    //                 ?.map((item, i) => ({ ...item, id: i + 1 }))
    //                 ?.filter((item) => {
    //                     // return item[keyName]
    //                     //   ?.toLowerCase()
    //                     //   ?.includes(searchValue.toLocaleLowerCase());
    //                     return Object.values(item).some((value) =>
    //                         String(value).toLowerCase().includes(searchValue.toLowerCase())
    //                     );
    //                 }) || [];
    //         setTabelDataValues(tableData);
    //     } else {
    //         if (tableHasAdditionalData) {
    //             tableData =
    //                 postApiData?.data?.data[tableDataKey]?.map((item, i) => ({
    //                     ...item,
    //                     id: i + 1,
    //                 })) ||
    //                 // ?.filter((item) => {
    //                 //   // return item[keyName]
    //                 //   // ?.toLowerCase()
    //                 //   // ?.includes(searchValue.toLocaleLowerCase())
    //                 //   return Object.values(item).some((value) =>
    //                 //     String(value).toLowerCase().includes(searchValue.toLowerCase())
    //                 //   );
    //                 // })
    //                 [];
    //             setTabelDataValues(tableData);
    //         } else {
    //             tableData =
    //                 postApiData?.data?.data?.map((item, i) => ({ ...item, id: i + 1 })) ||
    //                 // ?.filter((item) => {
    //                 //   // return item[keyName]
    //                 //   // ?.toLowerCase()
    //                 //   // ?.includes(searchValue.toLocaleLowerCase())
    //                 //   return Object.values(item).some((value) =>
    //                 //     String(value).toLowerCase().includes(searchValue.toLowerCase())
    //                 //   );
    //                 // })
    //                 [];
    //             setTabelDataValues(tableData);
    //         }
    //     }
    // }, [postApiData]);

    return (
        <>
            {/* <Loader loading={isLoading || isLoadingPostApi} /> */}
            <section className={classes.custom_datagrid} >
                {titleView && (
                    <div
                        className={`${classes.custom_datagrid_header}`}
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                        <div className={`${classes.custom_datagrid_heading}`}>
                            {typography.map((item) => renderTypography(item))}
                        </div>
                        <div className={classes.icons_container}>
                            {props?.optionalAdornment?.()}
                            {adornmentType.map((adornmentSelected, i) => (
                                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                                    {Adornment[adornmentSelected]}
                                    {i < adornmentType.length - 1 && (<div className={classes.divider}></div>)}
                                </div>
                            ))}
                        </div>
                        {/* {Adornment[adornmentType]} */}
                    </div>
                )}
                <StyledDataGrid
                    // autoHeight
                    columns={columns}
                    rows={tabelDataValues}
                    rowCount={dataCount}
                    slots={{
                        noRowsOverlay: () => (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }} >
                                <p className="tt-cpt fs-xs">
                                    {typography[0].children} not found!
                                </p>
                            </div>
                        ),
                    }}
                    rowSelection={false}
                    disableColumnMenu={true}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 25]}
                    sx={{
                        overflowX: "scroll",
                        maxHeight: "700px", // or use a percentage
                        overflowY: "auto",
                        minHeight: "200px",
                        // '.MuiDataGrid-filler': {
                        //     border: 'none',  // Removes any unwanted borders on the filler
                        //     backgroundColor: 'transparent',
                        //     maxHeight: "62px !important",
                        //     '--rowBorderColor': 'transparent !important' // Ensures no background overlap
                        // },
                    }}
                    //sort
                    sortingMode="server"
                    sortingOrder={["desc", "asc"]}
                    sortModel={sortModel}
                    onSortModelChange={(e) => handleSortModelChange(e)}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={(e) => {
                        setPaginationModel(e);
                        handlePaginationChange(e);
                    }}
                />

            </section>
        </>
    );
};

export default CustomDataGrid;
