import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';

export default function Breadcrumb({ id, order_type, fromDate, toDate }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumbs">
        <p style={{ color: "#376FD0", cursor: "pointer" }} onClick={() => {
          localStorage.setItem("from_navigation", true)
          navigate("/operations/order_management", { state: { navigate: fromDate, toDate: toDate, type: order_type } })
        }
        }>
          Order Management
        </p>
        <p style={{ color: "#376FD0", cursor: "pointer" }} onClick={() => {
          localStorage.setItem("from_navigation", true);
          navigate("/operations/order_management", { state: { fromDate: fromDate, toDate: toDate, type: order_type } }
          )
        }}>
          {order_type === "ongoing" ? "Ongoing" : "Closed"} Orders
        </p>
        <p style={{ color: "#B2B2B2" }}>
          {id}
        </p>
      </Breadcrumbs>
    </React.Fragment>
  );
}