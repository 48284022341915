import React, { useState, useEffect } from "react"
import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material"
import { useNavigate, useSearchParams, useNavigation } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"

import CustomButton from "../../../components/UI/Button/Button"
import PhoneNumInput from "../../../components/UI/PhoneNumInput/PhoneNumInput"
import useInput from "../../../utils/use-input"
import { validatePhoneNumber } from "../../../utils/validation"
import classes from "./LoginForm.module.css"
import useApiHttp from "../../../hooks/ues-http"
import { useDispatch } from "react-redux"
import { authActions } from "../../../services/storeSlice/authSlice"
import { isAuthenticated } from "../../../services/isAuthenticated"
import { toast, ToastContainer } from "react-toastify"

const LoginForm = () => {
  const navigate = useNavigate()
  const [checkValue, setCheckValue] = useState(false)
  const [value, setValue] = useState("")
  const dispatch = useDispatch()
  const { setMobileData } = authActions

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/")
    }
  }, [navigate])

  // Load remembered user on mount
  useEffect(() => {
    const rememberedUser = secureLocalStorage.getItem("rememberedUser")
    if (rememberedUser) {
      setValue(rememberedUser)
      setCheckValue(true)
    }
  }, [])

  const {
    value: enteredNum,
    rawPhone,
    dialCode,
    phoneIsValid: enteredNumIsValid,
    phoneHasError: enteredNumHasError,
    reactPhoneChangeHandler: phoneNumChangeHandler,
    inputBlurHandler: phoneNumBlurHandler,
    reset: resetPhoneNum,
  } = useInput(validatePhoneNumber)
  const {
    isLoading: sendOtpLoading,
    success: sendOtpSuccess,
    error: sendOtpError,
    sendRequest: sendOtp,
  } = useApiHttp()

  // Helper function to detect email or mobile type
  const getType = value => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(value) ? "email" : "mobile"
  }

  const handleLogin = e => {
    e.preventDefault()

    if (value) {
      const type = getType(value) // Check if email or mobile
      console.log("type", type)

      sendOtp(
        {
          url: `dealer/send-otp`,
          method: "POST",
          body: {
            type: type, // Set type as "email" or "mobile"
            mobile_number: value,
          },
        },
        data => {
          if (checkValue) {
            secureLocalStorage.setItem("rememberedUser", value)
          } else {
            secureLocalStorage.removeItem("rememberedUser")
          }
          dispatch(
            setMobileData({
              dialCode: String(dialCode),
              mobile: rawPhone,
              value: value,
              type: type,
            })
          )
          navigate("/otp")
        }
      )
    } else {
      // Handle invalid phone number error
      toast.error("Invalid input. Please enter a valid email or phone number.")
    }
  }

  // Display success/error messages for OTP
  useEffect(() => {
    if (sendOtpSuccess) {
      toast.success(sendOtpSuccess)
    }
    if (sendOtpError) {
      toast.error(sendOtpError)
    }
  }, [sendOtpSuccess, sendOtpError])

  const handleChange = event => {
    setCheckValue(event.target.checked)
  }

  return (
    <div className={classes.loginForm}>
      <div className={classes.loginHeader}>
        <h3 style={{ textAlign: "center" }}>Log In</h3>
        <p>Enter your email address or mobile number to continue</p>
      </div>
      <form onSubmit={handleLogin}>
        <TextField
          label="Email Address / mobile number"
          value={value}
          onChange={e => setValue(e.target.value)}
          color="primary"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "40px",
              padding: "0px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
            },
            "& .MuiInputBase-input": {
              padding: "8px 10px",
              fontSize: "14px",
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkValue}
                onChange={handleChange}
                name="rememberMe"
                sx={{
                  color: "var(--light-gray)",
                  "&.Mui-checked": {
                    color: "var(--primary-blue)",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 23,
                  },
                }}
              />
            }
            label="Remember Me"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "var(--text-color-dark)",
                fontSize: "13px",
                fontWeight: "400",
              },
            }}
          />
        </Stack>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{ padding: "7px 7px", width: "100%", marginTop: "15px" }}
          type="submit"
        >
          Login
        </CustomButton>
      </form>

      <ToastContainer />
    </div>
  )
}

export default LoginForm
