import React from 'react'

const UploadIcon = ({ colour = 'var(--secondary-dark)', width = "18px" }) => {
    return (
        <svg width={width} height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>web/dd/svg/upload</title>
            <g id="web/dd/svg/upload" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(3.1, 4.1)" fill="#000000" fillRule="nonzero">
                    <path d="M17.000045,8.88888889 C17.442123,8.88888889 17.8,9.24236537 17.8,9.67777778 L17.8,13.2333333 C17.8,14.6499396 16.6356346,15.8 15.200135,15.8 L2.60076496,15.8 C1.16542453,15.8 0.000899965846,14.6500077 0.000900242988,13.2340485 L0,9.67777778 C0,9.24236535 0.357877012,8.88888889 0.799955002,8.88888889 C1.24203299,8.88888889 1.59991,9.24236535 1.59991,9.67777778 L1.59998453,10.5169516 C1.59999632,10.5962608 1.60000934,10.677188 1.60002348,10.7591886 L1.60080999,13.2334147 C1.60080996,13.7790765 2.04947262,14.2222222 2.60076496,14.2222222 L15.200135,14.2222222 C15.7514747,14.2222222 16.20009,13.7791185 16.20009,13.2333333 L16.20009,9.67777778 C16.20009,9.24236537 16.557967,8.88888889 17.000045,8.88888889 Z M8.55278338,0.231489698 C8.86528878,-0.077165805 9.37245966,-0.0771628543 9.68496146,0.23149628 L13.5031105,4.00269627 C13.8149274,4.31067891 13.8149274,4.80949887 13.5031105,5.1174815 C13.1906062,5.4261432 12.6834301,5.4261432 12.3709258,5.1174815 L10.089096,2.86370975 L9.91882406,2.9348565 L9.91882406,11.4555556 C9.91882406,11.890968 9.56094702,12.2444444 9.11886906,12.2444444 C8.67679109,12.2444444 8.31891405,11.890968 8.31891405,11.4555556 L8.31891405,2.93482074 L8.14864299,2.86367316 L5.86671569,5.11748808 C5.55420765,5.42614615 5.04703157,5.42614025 4.73453093,5.11747493 C4.42271779,4.80948867 4.42272374,4.31066871 4.73454425,4.0026897 Z" id="Combined-Shape"
                        fill={colour}
                    >
                    </path>
                </g>
            </g>
        </svg>
    )
}

export default UploadIcon