import React, { useState } from "react"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import InputBase from "@mui/material/InputBase"
import Box from "@mui/material/Box"
import { styled } from "@mui/system"

// SearchBar component with smooth transitions
const SearchBar = styled(InputBase)({
  width: "100%",
  padding: "0 10px",
  transition: "width 0.4s ease, opacity 0.3s ease",
  opacity: 1,
  "&.hidden": {
    width: 0,
    opacity: 0,
  },
})

const SearchExpand = ({
  placeholder = "Search...", // Default placeholder
  searchIcon = <SearchIcon />, // Default search icon
  closeIcon = <CloseIcon />, // Default close icon
  onSearchChange, // Function to handle search value changes
  searchValue: externalSearchValue = "", // External search value (if needed)
  maxWidth = "400px", // Customize max width of the search bar
  borderColor = "#ccc", // Customize border color
}) => {
  // State to toggle search visibility
  const [showSearch, setShowSearch] = useState(false)
  // Internal state to capture the search value
  const [internalSearchValue, setInternalSearchValue] =
    useState(externalSearchValue)

  // Toggle search visibility
  const toggleSearch = () => {
    if (showSearch) {
      // Reset search value when closing
      setInternalSearchValue("") // Clear internal search value
      if (onSearchChange) {
        onSearchChange("") // Reset external search value
      }
    }
    setShowSearch(prev => !prev)
  }

  // Handle input change
  const handleSearchChange = e => {
    const value = e.target.value
    setInternalSearchValue(value) // Update internal state
    if (onSearchChange) {
      onSearchChange(value) // Trigger external callback
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!showSearch ? (
        <IconButton onClick={toggleSearch}>{searchIcon}</IconButton>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth, // Use maxWidth prop
            transition: "width 0.4s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              border: `1px solid ${borderColor}`, // Use borderColor prop
              borderRadius: "8px",
              padding: "4px",
              backgroundColor: "#fff",
              width: showSearch ? "100%" : "0", // Smoothly expand the width
              transition: "width 0.4s ease",
              overflow: "hidden", // Hide overflowing content when shrinking
            }}
          >
            {/* Render the search bar with smooth opacity and width transition */}
            <SearchBar
              className={showSearch ? "" : "hidden"}
              value={internalSearchValue} // Bind the value to the search state
              onChange={handleSearchChange} // Update state on input change
              placeholder={placeholder} // Use placeholder prop
              inputProps={{ "aria-label": "search" }}
            />
          </Box>
          <IconButton onClick={toggleSearch}>{closeIcon}</IconButton>
        </Box>
      )}
    </div>
  )
}

export default SearchExpand
