import React, { useEffect, useState } from "react"
import classes from "./ReportsMain.module.css"
import CustomTable from "../../components/UI/Table/TablePage"
import useApiHttp from "../../hooks/ues-http"
import SelectSmall from "../../components/UI/Dropdown/Select"
import SearchExpand from "../../components/UI/SearchExpand/SearchExpand"
import downlodeImge from "../../assets/image/svg/download.svg"
import reOrderIcon from "../../assets/image/svg/reorder.svg"
import dayjs from "dayjs"
import FromToDatePicker from "../../components/UI/DatePicker/FromToDatePicker"
import { currentDate, handleFileDownload } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import DropDownFile from "../../components/UI/DropDownFile/DropDownFile"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"
import CustomeSlecter from "../../components/UI/Dropdown/CustomeSlecter"

const ReportsMain = () => {
  const navigator = useNavigate()
  const [orderHistoryData, setOrderHistoryData] = useState([])

  const [sortDirectionData, setSortDirectionData] = useState("DESC")
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [column, setColumn] = useState(0)
  const [total, setTotal] = useState([])
  const [showTotal, setShowTotal] = useState(0)
  const [searchVlue, setSearchVlue] = useState("")

  const [fromDate, setFromDate] = useState(currentDate()) // Set initial from date
  const [toDate, setToDate] = useState(currentDate()) // Set initial to date
  const [status, setStatus] = useState({
    status_list: [
      {
        value: "ongoing",
        label: "Ongoing",
      },
      {
        value: "delivered",
        label: "Delivered",
      },
    ],
    selectedVal: "",
  }) // Set initial to date

  const {
    isLoading: OrderHistoryLoading,
    success: OrderHistorySucces,
    error: OrderHistoryError,
    sendRequest: OrderHistoryRequest,
  } = useApiHttp()
  const {
    isLoading: pdfLoading,
    success: pdfSucces,
    error: pdfError,
    sendRequest: pdfRequest,
  } = useApiHttp()

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const repeadOrderRequestHandle = token => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          setTimeout(() => {
            navigator("/shopnow")
          }, 1000)
        }
      }
    )
  }

  useEffect(() => {
    if (repeadOrderSucces !== "") {
      toast.success(repeadOrderSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (repeadOrderError !== "") {
      toast.error(repeadOrderError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [repeadOrderSucces, repeadOrderError])

  const OrderHistoryHandleSelect = () => {
    OrderHistoryRequest(
      {
        url: `dealer/my-order/report-list`,
        method: "POST",
        body: {
          from_date: fromDate,
          to_date: toDate,
          search_value: searchVlue,
          page: page,
          per_page: perPage,
          sort_dir: sortDirectionData,
          sort_column: column,
          status: status.selectedVal,
        },
      },
      data => {
        setOrderHistoryData(data.data.aaData)
        setTotal(data.data.iTotalRecords)
        setShowTotal(data.data.iTotalDisplayRecords)
      }
    )
  }

  useEffect(() => {
    OrderHistoryHandleSelect()
  }, [
    page,
    perPage,
    column,
    sortDirectionData,
    toDate,
    fromDate,
    searchVlue,
    status,
  ])

  const pdfHandleSelect = fileType => {
    const AdminbearerToken = secureLocalStorage.getItem("dealerBearerToken")

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_API_URL}/dealer/my-order/report-list-download`,
      data: {
        type: fileType,
        from_date: fromDate,
        to_date: toDate,
        search_value: searchVlue,
        page: page,
        per_page: perPage,
        sort_dir: sortDirectionData,
        sort_column: column,
        status: status.selectedVal,
      },
      headers: {
        Authorization: `Bearer ${AdminbearerToken}`,
      },
      responseType: "blob", // Important for binary data
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url

        // Extract the filename from the response headers (optional)
        const contentDisposition = response.headers["content-disposition"]
        let fileName = `downloaded_file.${fileType}` // default file name with correct extension

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        } else {
          // Fallback to using the fileType for naming the file
          fileName = `order_history_report.${fileType}`
        }

        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove() // cleanup
      })
      .catch(error => {
        console.error("Error downloading the file:", error)
      })
  }

  const statusCard = status => {
    return (
      <div className={classes.status_card_container}>
        {status === "Pending" ? (
          <p className={classes.pending}>{status}</p>
        ) : status === "Partially Dispatched" ? (
          <p className={classes.partially_dispatched}>{status}</p>
        ) : status === "Completed" ? (
          <p className={classes.dispatched}>{status}</p>
        ) : status === "Order Acknowledged" ? (
          <p className={classes.order_acknowledged}>{status}</p>
        ) : (
          status === "Delivered" && (
            <p className={classes.delivered}>{status}</p>
          )
        )}
      </div>
    )
  }

  const orderHistory = [
    {
      name: "S.No",
      selector: row => row.si_no,
      sortable: false,
      sortField: 0,
      wrap: true,
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      name: "Addison Reference No",
      selector: row => row.order_id,
      sortable: true,
      sortField: 1,
      minWidth: "160px",
      maxWidth: "160px",
    },
    // {
    //   name: "PO number",
    //   selector: row => row.po_number,
    //   sortable: true,
    //   sortField: 2,
    // },
    {
      name: "SO number",
      selector: row => row.so_number,
      // sortable: true,
      sortField: 2,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Ref number",
      selector: row => row.dealer_reference_number,
      // sortable: true,
      sortField: 3,
      wrap: true,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Order Date",
      selector: row => row.order_date,
      sortable: true,
      sortField: 4,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Product Code",
      selector: row => row.product_code,
      sortable: true,
      sortField: 5,
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      name: "Product Name",
      selector: row => row.product_name,
      sortable: true,
      sortField: 6,
      minWidth: "160px",
      maxWidth: "160px",
      wrap: true,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Variant",
      selector: row => row.variant,
      sortable: true,
      sortField: 7,
      minWidth: "160px",
      maxWidth: "160px",
    },

    {
      name: "Needed Date",
      selector: row => row.needed_date,
      sortable: true,
      sortField: 8,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Est.Delivery Date",
      selector: row => row.needed_date,
      sortable: true,
      sortField: 9,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Delivered Date",
      selector: row => row.delivery_date,
      sortable: true,
      sortField: 10,
      minWidth: "160px",
      maxWidth: "160px",
    },

    {
      name: "Item Price",
      selector: row => row.item_price,
      sortable: true,
      sortField: 11,
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: "Qty",
      selector: row => row.qty,
      // sortable: true,
      sortField: 12,
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      name: "Total Amt.",
      selector: row => row.total_amount,
      // sortable: true,
      sortField: 13,
      minWidth: "140px",
      maxWidth: "140px",
    },
    {
      name: "Status",
      cell: row => <>{statusCard(row?.status)}</>,
      // minWidth: "170px",
      // sortable: true,
      sortField: 14,
      minWidth: "200px",
      maxWidth: "200px",
    },
  ]

  const handleSort = async (column, sortDirection) => {
    setSortDirectionData(sortDirection || "desc")
    setColumn(column.sortField || 0)
    // console.log("column", sortDirection, column.sortField)
  }

  const handlePageChange = page => {
    setPage(page || 1)
    {
      page > 1 && localStorage.setItem("page", parseInt(page))
    }
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage || 10)
    {
      newPerPage > 10 &&
        localStorage.setItem("newPerPage", parseInt(newPerPage))
    }
  }
  const clearSate = () => {
    setFromDate("")
    setToDate("")
  }

  const handleFromDateChange = value => {
    const newFromDate = dayjs(value).format("YYYY-MM-DD")
    setFromDate(newFromDate)

    // Automatically set "toDate" to be the same or after "fromDate"
    if (dayjs(newFromDate).isAfter(toDate)) {
      setToDate(newFromDate)
    }
  }

  const handleToDateChange = value => {
    const newToDate = dayjs(value).format("YYYY-MM-DD")
    setToDate(newToDate)
  }

  return (
    <div className={classes.MyOrders}>
      <h3 className={classes.title}>Reports</h3>

      <div className={classes.table}>
        <CustomTable
          data={orderHistoryData}
          columns={orderHistory}
          loader={OrderHistoryLoading}
          onSort={handleSort}
          paginationTotalRows={total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          children={
            <div className={classes.header_table}>
              {/* <h3>Delivered Order History</h3> */}
              <div className={classes.header_table_box}>
                <div className={classes.header_table_left}>
                  <p className={classes.header_titel}>
                    {showTotal} Products Ordered
                  </p>

                  <FromToDatePicker
                    label={"From Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(fromDate)}
                    onChange={handleFromDateChange}
                    error={false}
                    width={"0px"}
                    height={"40px"}
                    borders={false}
                  />

                  {/* To Date Picker */}
                  <FromToDatePicker
                    label={"To Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(toDate)}
                    minDate={dayjs(fromDate)}
                    onChange={handleToDateChange}
                    disabled={!fromDate}
                    error={false}
                    borders={false}
                  />

                  {/* <a
                    style={{
                      textDecoration: "underline",
                      color: "#32349B",
                      fontSize: "16px",
                      fontFamily: "var(--font-bold)",
                      cursor: "pointer",
                    }}
                    onClick={clearSate}
                  >
                    Clear
                  </a> */}
                  <CustomeSlecter
                    data={status.status_list} // Provide an empty array as fallback
                    title={"Choose Status"}
                    // lable={"Status"}
                    width={180}
                    height={100}
                    value={status.selectedVal}
                    onChange={e =>
                      setStatus(prev => ({
                        ...prev,
                        selectedVal: e.target.value,
                      }))
                    }
                    // borders={true}
                  />
                </div>
                <div className={classes.header_table_right}>
                  <SearchExpand
                    placeholder="Search products..."
                    onSearchChange={value => setSearchVlue(value)}
                    maxWidth="500px"
                  />
                  <div className={classes.filters}></div>
                  <DropDownFile handleFileDownload={pdfHandleSelect} />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export default ReportsMain
