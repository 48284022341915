import { useEffect } from "react"
import classes from "./MainLayout.module.css"
import { Outlet, useLocation } from "react-router-dom"
import Header from "../features/Header/Header"
import Footers from "../features/Footer/Footers"
import SubHeader from "../features/Header/SubHeader"
import CustomizedStepper from "../components/UI/Stepper/Stepper"
import { useDispatch, useSelector } from "react-redux"
import Summary from "../features/Summary/Summary"
import { isAuthenticated } from "../services/isAuthenticated"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import {
  setProductsData,
  clearAllData,
  setSteperVlue,
  setShopNowActiveTab,
} from "../services/storeSlice/shopNowSlice"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"
import { resetTimer } from "../services/storeSlice/timerSlice"
import { toast, ToastContainer } from "react-toastify"

const tabs = [
  { title: "Home", path: "/" },
  { title: "Place Order", path: "/shopnow" },
  { title: "My Orders", path: "/myorders" },
  { title: "Raise Complaint", path: "/raisecomplaint" },
  { title: "Reports", path: "/reports" },
]
const steps = ["Place Order", "Address", "Order Summary"]

// Fetcher function for the API call
const AdminbearerToken = secureLocalStorage.getItem("dealerBearerToken")
const fetchProducts = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/products`,
    {
      headers: AdminbearerToken
        ? {
          Authorization: `Bearer ${AdminbearerToken}`,
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        }
        : {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        },
    }
  )

  return response.data
}

const MainLayout = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const setperStatus = useSelector(state => state?.shopNow?.setperStatus)
  const shopNowActiveTab = useSelector(
    state => state?.shopNow?.shopNowActiveTab
  )

  const { timeLeft, isActive } = useSelector(state => state.timer)

  // Redirect if not authenticated
  if (!isAuthenticated()) {
    navigate("/login")
  }

  // React Query for fetching products data
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["shopNowProducts"], // Unique key for the query
    queryFn: fetchProducts,
    staleTime: 5 * 60 * 1000, // Data considered fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Data remains in cache for 10 minutes
    refetchOnWindowFocus: false, // Avoid refetching when window is focused
  })

  // Dispatch the products data if available
  useEffect(() => {
    if (data) {
      dispatch(setProductsData(data?.data))
    }
  }, [data, dispatch])

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0)

    // Clear data and reset stepper if the user is not in the Shop Now flow
    if (pathname !== "/shopnow") {
      dispatch(clearAllData())
      if (setperStatus !== 0) {
        dispatch(setSteperVlue(0))
      }
      if (shopNowActiveTab !== 0) {
        dispatch(setShopNowActiveTab(0))
      }

      // const item = localStorage.getItem("shop_status")
      // if (item == 0) {
      //   navigate("/shopnow")
      // }
    }
  }, [pathname, setperStatus, dispatch])

  return (
    <>
      <Header />
      {(pathname === "/" ||
        pathname === "/shopnow" ||
        pathname === "/myorders" ||
        pathname === "/raisecomplaint" ||
        pathname === "/reports") && (
          <div className={classes.subheader}>
            <SubHeader tabs={tabs} />
          </div>
        )}
      {pathname === "/shopnow" && (
        <div className={classes.main_steper}>
          <div className={classes.steper_box}>
            <CustomizedStepper steps={steps} activeStep={setperStatus} />
          </div>
        </div>
      )}
      <main className={classes.main}>
        <Outlet />
      </main>
      {pathname === "/shopnow" && setperStatus === 0 && <Summary />}
      <Footers className={`${pathname === "/" ? "" : "position"}`} />
      {/* <ToastContainer /> */}
    </>
  )
}

export default MainLayout
