import React, { useEffect, useState } from "react"
import classes from "./MyProfile.module.css"
import SubHeaderNewPage from "../../features/Header/SubHeaderNewPage"
import useApiHttp from "../../hooks/ues-http"
const tabs = [
  { title: "Home", path: "/" },
  { title: ">", path: "/myprofile" },
  { title: "My Profile", path: "/myprofile" },
]

const MyProfile = () => {
  const { isLoading, success, error, sendRequest } = useApiHttp()

  const [data, setData] = useState()

  const searchingRequestData = () => {
    sendRequest(
      {
        url: `dealer/profile`,
      },
      data => {
        setData(data?.data)
      }
    )
  }

  useEffect(() => {
    searchingRequestData()
  }, [])
  return (
    <div className={classes.myProfile}>
      <SubHeaderNewPage tabs={tabs} />
      {!isLoading ? (
        <div className={classes.mrg_top}>
          <h3 className={classes.title}>My Profile</h3>
          <div className={`${classes.ditails_box} ${classes.alignment}`}>
            <div className={classes.ditails_contant}>
              <p className={classes.ditails_box_title}>Profile Information</p>
              <h3 className={classes.ditails_sub_title}>{data?.name}</h3>
              <p className={classes.ditails_box_title}>{data?.location}</p>
            </div>
            <div className={classes.main_contact_persaon}>
              <div className={classes.contact_persaon}>
                <div className={classes.contact_persaon_ditails}>
                  <p>Contact Person Name</p>
                  <h4>{data?.contact_name}</h4>
                </div>
                <div className={classes.contact_persaon_ditails}>
                  <p>Contact Person Mobile Number</p>
                  <h4>{data?.mobile_number}</h4>
                </div>
                <div className={classes.contact_persaon_ditails}>
                  <p>Contact Person Email Address</p>
                  <h4>{data?.email}</h4>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.ditails_box}>
            <p className={classes.ditails_box_title}>Saved Address</p>
            <div className={classes.ditails_addres_set}>
              {data?.addreses?.map((item, index) => (
                <div className={classes.addres_box}>
                  <h4>{item.name}</h4>
                  <p>
                    {item.address_line_1}, {item.address_line_2}
                    {item.city} {item.state} {item.pincode}, {item.country}.{" "}
                    {item.country_code} {item.mobile_number}
                  </p>
                  <p>
                    +{item.country_code} {item.mobile_number}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>loding......</p>
      )}
    </div>
  )
}

export default MyProfile
