import react, { useEffect, useState } from "react"
import classes from "./OrderDetails.module.css"
import { Button, FormControl, InputLabel, Menu, MenuItem, Select, TextField } from "@mui/material"
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useApiCall from "../../hooks/useApiCall";
import secureLocalStorage from "react-secure-storage";
import EnhancedOrdersTable from "./Components/OrderDetailsTable";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import downloadIcon from '../../assets/image/svg/download.svg'

export const OrderDetails = () => {

    const { id } = useParams();

    const location = useLocation();

    const { fromDate, toDate, active_orders, status_data } = location.state || {};

    const [data, setData] = useState(null);
    const [tableTrigger, settableTrigger] = useState(false);


    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        getDetails({
            url: "marketing/ongoing-closed-order-list-view",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
            },
            body: {
                "order_token": id
            }
        }, (response) => {
            setData(response.data)
        });
    }, [tableTrigger]);

    const statusCard = (status) => {
        return (
            <div className={classes.status_card_container}>
                {
                    status === "Pending" ?
                        <p className={classes.pending}>{status}</p>
                        :
                        status === "Partially Dispatched" ?
                            <p className={classes.partially_dispatched}>{status}</p>
                            :
                            status === "Completed" ?
                                <p className={classes.dispatched}>{status}</p>
                                :
                                status === "Order Acknowledged" ?
                                    <p className={classes.order_acknowledged}>{status}</p>
                                    :
                                    status === "Delivered" &&
                                    <p className={classes.delivered}>{status}</p>
                }
            </div>
        )
    }

    const { isLoading: isDownloadLoading, success: downloadSuccess, error: downloadError, code: downloadStatusCode, errorData: downloadErrorData, sendRequest: download } = useApiCall();

    const handleDownload = () => {
        download({
            url: "marketing/ongoing-closed-order-view-history-download",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
            },
            body: {
                "order_token": id
            },
            responseType: "binary",
        }, (data, response) => {
            // downloadFile(data.data);

            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            let defaultFileName = `order_details_${timestamp}`;
            downloadFilefromObject(response, defaultFileName, 'pdf')
        });
    }

    const downloadFile = async (pdfUrl) => {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        a.download = `order_details_${timestamp}.pdf`; // Specify the name for the downloaded file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Clean up the URL object
    };

    const downloadFilefromObject = (response, defaultFileName, fileType) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const link = document.createElement("a")
        link.href = url

        // Extract the filename from the response headers (optional)
        const contentDisposition = response.headers["content-disposition"]
        let fileName = `${defaultFileName}.${fileType}` // default file name with correct extension

        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
            if (fileNameMatch && fileNameMatch.length === 2) {
                fileName = fileNameMatch[1]
            }
        } else {

            // Fallback to using the fileType for naming the file
            fileName = `${defaultFileName}.${fileType}`
        }
        // link.setAttribute("download", fileName)
        link.download = fileName

        document.body.appendChild(link);
        link.click()
        link.remove() // cleanup
    };

    return (
        <div className={classes.container}>
            <p className={classes.title}>{data?.orderData[0].order_id}</p>
            <div className={classes.breadcrumb_container}>
                <Breadcrumb
                    fromDate={fromDate}
                    toDate={toDate}
                    order_type={data?.orderData[0].order_type}
                    id={data?.orderData[0].order_id}
                />
            </div>
            <div className={classes.divider}></div>
            <div className={classes.order_details_container}>
                <div className={classes.order_details_header}>
                    <div className={classes.title_download_container}>
                        <p className={classes.order_details_title}>{data?.orderData[0].order_id}</p>
                        <div className={classes.download_container} onClick={handleDownload}>
                            <img src={downloadIcon} width={24} height={24} />
                            <p className={classes.download_label}>Download Order Details</p>
                        </div>
                    </div>
                    <p className={classes.order_details_date}>{data?.orderData[0].order_date}</p>
                </div>
                <div className={classes.order_details_contents}>
                    <div className={classes.order_details_row}>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Dealer ID</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].dealer_id}</p>
                        </div>
                        {/* <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>PO Number</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].po_number}</p>
                        </div> */}
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Dealer Name</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].dealer_name}</p>
                        </div>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Status</p>
                            <p className={classes.order_details_value}>{statusCard(data?.orderData[0].status)}</p>
                        </div>
                    </div>
                    <div className={classes.order_details_row}>
                        {/* <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Estimated Delivery Date</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].delivered_date}</p>
                        </div> */}
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Total Items</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].total_product_quantity}</p>
                        </div>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Total Order Value</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].order_total_amount}</p>
                        </div>
                    </div>
                    <div className={classes.order_details_row}>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Shipping Address</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].shipping_address}</p>
                        </div>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}>Billing Address</p>
                            <p className={classes.order_details_value}>{data?.orderData[0].billing_address}</p>
                        </div>
                    </div>
                    <div className={classes.order_details_row}>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}></p>
                            <p className={classes.order_details_value}>{data?.orderData[0].shipping_mobile}</p>
                        </div>
                        <div className={classes.order_detail}>
                            <p className={classes.order_details_label}></p>
                            <p className={classes.order_details_value}>{data?.orderData[0].billing_mobile}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.table_container}>
                <EnhancedOrdersTable statusData={status_data} data={data?.product_details} orderToken={id} orderStatus={data?.orderData[0].status} initiateTrigger={settableTrigger} />
            </div>
        </div>
    )
}