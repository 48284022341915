import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage"
import { authActions } from "../../../dealer/services/storeSlice/authSlice";


const initialState = {
    isAuthenticated: false,
    name: "",
    userName: "",
    email: "",
    forgotEmail: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action) {
            const { access_token: token, name, email } = action.payload
            state.isAuthenticated = true;
            secureLocalStorage.setItem("marketingBearerToken", token);
            let userData = secureLocalStorage.getItem("marketingUserData");
            secureLocalStorage.setItem("marketingUserData", { ...userData, name: name, email: email });
            state.name = name;
            state.email = email;
        },
        setForgotEmail(state, action) {
            state.forgotEmail = action.payload
        },
        logout(state) {
            state.isAuthenticated = false;
            secureLocalStorage.removeItem("marketingBearerToken");
            // secureLocalStorage.removeItem("marketingUserData");
            let userData = secureLocalStorage.getItem("marketingUserData");
            secureLocalStorage.setItem("marketingUserData", { ...userData, name: "", email: "" });
        },
    }
});

export const { login, logout, setForgotEmail } = authSlice.actions

export default authSlice.reducer
