import React,{ useState } from 'react'
import { Navigate,Outlet } from 'react-router-dom'
import Sidebar from '../features/sideBar/Sidebar';
import Header from '../features/Header/Header';
import classes from './MainLayout.module.css'
// import './RootLayOut.css'
// import '../../../globalUi/globaladminUi.css'

const MainLayout = () => {
    const [isActives, setActives] = useState(false);

    // if (!isAuthenticated()) {
    //     return <Navigate to="/admin" />
    // }

    return (
        <div className={classes.main_layout}>
            <Sidebar />
            <section id={classes.content}>
                <Header />
                <main>
                    <Outlet/>
                </main>
            </section>

        </div>
    )
}

export default MainLayout