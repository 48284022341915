import React, { useState } from "react"
import classes from "./CsvUpload.module.css"
import { useDropzone } from "react-dropzone"
import axios from "axios"
import { Modal, Box, Button, Typography } from "@mui/material"
import secureLocalStorage from "react-secure-storage"
import EditableProductGrid from "./EditableProductGrid"
import csvimage from "../../../assets/image/svg/csvupload.svg"
import download from "../../../assets/image/svg/download_2.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  setCsvFailedProduct,
  setCsvProducts,
} from "../../../services/storeSlice/shopNowSlice"
import csverror from "../../../assets/image/svg/csverror.svg"
import tick from "../../../assets/image/svg/tick.svg"
import removered from "../../../assets/image/svg/removered.svg"

const CsvDragAndDropUpload = () => {
  const dispatch = useDispatch()
  const data = useSelector(item => item.shopNow)
  const products = data.csvProducts
  const failedProduct = data.csvFailedProduct

  const [error, setError] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const AdminbearerToken = secureLocalStorage.getItem("dealerBearerToken")
  // Handle CSV file upload to the API
  const handleUpload = file => {
    const formData = new FormData()
    formData.append("product_csv", file)

    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/upload-csv`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${AdminbearerToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        const data = response.data
        if (data.status_code === 200 && data.message === "success") {
          dispatch(setCsvProducts(data.data))
          setError(null)
        } else if (data.status_code === 200 && data.message === "failure") {
          dispatch(setCsvFailedProduct(data.data))
          setIsOpen(true)
        }
      })
      .catch(error => {
        console.log("CSV upload error:", error?.response?.data?.message)
        setError(
          error?.response?.data?.message ||
            "An error occurred while uploading the CSV."
        )
      })
  }

  // Dropzone setup for drag-and-drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv",
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0]
      if (file) {
        handleUpload(file)
      }
    },
  })

  const csvHandleDownload = () => {
    const AdminbearerToken = secureLocalStorage.getItem("dealerBearerToken")

    axios({
      method: "get", // Keep method as GET
      url: `${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/csv/download`,
      headers: {
        Authorization: `Bearer ${AdminbearerToken}`,
      },
      responseType: "blob", // Important for binary data
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url

        // Extract the filename from the response headers (optional)
        const contentDisposition = response.headers["content-disposition"]
        let fileName = "sample.csv" // Default file name for CSV download

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        }

        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove() // Cleanup
      })
      .catch(error => {
        console.error("Error downloading the file:", error)
      })
  }

  return (
    <>
      {!products ? (
        <div className={classes.upload_set}>
          <div>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                backgroundColor: isDragActive ? "#f0f0f0" : "#fff",
                cursor: "pointer",
                width: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <input {...getInputProps()} />
                <div className={classes.drop_set}>
                  <img src={csvimage} alt="csvimage" />
                  <p className={classes.drop_title}>
                    Upload CSV file to Generate Order
                  </p>
                  <p className={classes.drop_title_light}>
                    or drag and drop it here
                  </p>
                </div>
              </div>
            </Box>

            <div className={classes.downlod_csv}>
              <p>
                Please note that, your CSV data structure need to follow the
                sample CSV. You can download sample file here
              </p>
              <div
                className={classes.downlod_csv_button}
                onClick={csvHandleDownload}
              >
                <img src={download} alt="download" />
                <p>Download CSV</p>
              </div>
            </div>

            {/* Show error message if any */}
            {error && (
              <Typography color="error" mt={2}>
                {error}
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <EditableProductGrid products={products} />
        </div>
      )}

      {isOpen && (
        <Modal
          open={isOpen}
          onClose={false}
          aria-labelledby="popup-modal-title"
          aria-describedby="popup-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "55%",
              maxWidth: "550px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              padding: "30px 60px",
            }}
          >
            <Box id="popup-modal-description">
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img src={csverror} alt="csverror" />
                <p className={classes.title_alert}>CSV Error Alert!</p>
              </div>

              <p className={classes.sub_title} style={{ marginTop: "15px" }}>
                Error Importing CSV File
              </p>
              <div className={classes.item_check} style={{ marginTop: "12px" }}>
                <div
                  className={classes.item_check_set}
                  style={{ marginTop: "5px" }}
                >
                  <img src={tick} alt="csverror" />
                  <p className={classes.sub_title_alert}>
                    {failedProduct?.passed} out of {failedProduct?.total} item
                    codes successfully validated
                  </p>
                </div>
                <div
                  className={classes.item_check_set}
                  style={{ marginTop: "5px" }}
                >
                  <img src={removered} alt="csverror" />
                  <p className={classes.sub_title_alert}>
                    {failedProduct?.failed} out of {failedProduct?.total} item
                    codes were not imported due to incorrect data.
                  </p>
                </div>
              </div>

              <p
                className={classes.sub_title_alert}
                style={{ marginTop: "20px" }}
              >
                The following item codes are not valid and will be dropped if
                you proceed.{" "}
              </p>
              <div className={classes.iteme_code_box}>
                <h5>Item Code</h5>
                <p>{failedProduct?.failed_products.join(", ")}</p>
              </div>
            </Box>
            {/* Action Buttons */}
            <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  color: "#68686A",
                  fontSize: "14px",
                  fontFamily: "var(--font-bold)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setError(null)
                  setIsOpen(false)
                }}
              >
                Re-Upload
              </Button>
              <Button
                style={{
                  color: "#32349B",
                  fontSize: "14px",
                  fontFamily: "var(--font-bold)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  dispatch(setCsvProducts(failedProduct?.products))
                  setError(null)
                  setIsOpen(false)
                }}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default CsvDragAndDropUpload
