import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import profileicon from '../../../assets/image/svg/profile.svg'
import logouticon from '../../../assets/image/svg/logout.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classes from './profil.module.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import LoginServices from '../../../pages/Login/login.service'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import secureLocalStorage from 'react-secure-storage';
// import "react-toastify/dist/ReactToastify.css";



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { LogoutService } = LoginServices();
    const { sendRequest: logout } = LogoutService();
    let userData = secureLocalStorage.getItem('marketingUserData');
    const navigate = useNavigate();

    const logoutUser = () => {
        logout({}, () => {
            handleModalOpenClose();
        });

    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMyProfileClick = (e) => {
        handleClose();
        navigate('/operations/my_profile');

    }

    const [modalOpen, setmodalOpen] = useState(false);
    const handleModalOpenClose = (e, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setmodalOpen((prev) => !prev)
    }




    return (
        <div className={classes.profile}>
            <Stack direction="row" spacing={2}>
                <Avatar alt="Remy Sharp" src="" />
            </Stack>
            <div className={classes.profile_name}>
                <p>Welcome</p>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {userData?.name}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={onMyProfileClick} disableRipple className='profile_list'>
                        <img src={profileicon} alt="Profile" className={classes.menuicon} />
                        My Profile
                    </MenuItem>
                    <MenuItem disableRipple className='profile_list' onClick={handleModalOpenClose}>
                        <img src={logouticon} alt="Logout" className={classes.menuicon} />
                        Logout
                    </MenuItem>
                </StyledMenu>
                <Dialog
                    fullScreen={fullScreen}
                    open={modalOpen}
                    onClose={handleModalOpenClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle sx={{ color: "#DE4444" }} id="responsive-dialog-title">
                        LOGOUT?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleModalOpenClose}>
                            NO
                        </Button>
                        <Button sx={{ color: "#DE4444" }} onClick={logoutUser} autoFocus>
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
}