import React, { useState, useEffect } from 'react'
import classes from './dealerProductOrders.module.css'
import DealerProductOrdersServices from './dealerProductOrders.services';
import { Button, Divider, Paper, Stack } from '@mui/material';
import CustomeSlecter from '../../components/UI/Dropdown/CustomeSlecter';
import CustomButton from '../../components/UI/Button/Button';
import FromToDatePicker from '../../../marketing/components/UI/DatePicker/FromToDatePicker';
import dayjs from "dayjs"
import CustomDataGrid from '../../components/UI/customDataGrid/CustomDataGrid';
import styled from "styled-components"
import { useNavigate } from 'react-router-dom';
import MultiSelectWithSearch from '../../components/UI/Dropdown/MultiSelectWithSearch';
import MultiSelect from '../../components/UI/Dropdown/MultiSelect';


const StatusWidgetLable = styled.div.attrs(props => ({ className: classes[props.class] }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-regular);
color:#FFFFFF;
line-height:14px;
font-size:14px;
padding: 4px 10px;
border-radius: 3px;
`


const DealerProductOrders = () => {
    const {
        FetchAvailableDealers,
        FetchAvailableVerticals,
        FetchProductStatus,
        FetchProductWiseLists,
        DownloadTable
    } = DealerProductOrdersServices();
    const [activeOrders, setActiveOrders] = useState("ongoing");
    const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [hasValueChanged, sethasValueChanged] = useState(false);
    const [generateTable, setgenerateTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState({
        selectedVal: [],
        // status_list: [
        //     { label: "Pending", value: "0" },
        //     { label: "Order Acknowledged", value: "1" },
        //     { label: "Partially Dispatched", value: "2" },
        //     { label: "Dispatched", value: "3" },
        //     { label: "Delivered", value: "4" },
        // ]
        status_list: []
    });
    const [selectedVerticals, setSelectedVerticals] = useState({
        selectedVal: [],
        verticals_list: []
    });
    const [dealerName, setdealerName] = useState([]);
    const [availableDealers, setavailableDealers] = useState([]);

    const navigate = useNavigate();
    const { sendRequest: getDealers } = FetchAvailableDealers();
    const { sendRequest: getVerticals } = FetchAvailableVerticals();
    const { sendRequest: getStatus } = FetchProductStatus();



    useEffect(() => {
        getDealers({}, (data) => {
            let dealerList = data.data.map((item) => ({ label: item.name, value: item.token }))
            setavailableDealers(dealerList)
        })

        getVerticals({}, (data) => {
            let verticalsList = data.data.map((item) => ({ label: item.name, value: item.token, vertical_code: item.vertical_code }))
            setSelectedVerticals((curr) => ({ ...curr, verticals_list: verticalsList }))
        })

        getStatus({}, (data) => {
            let statusList = data.data.map((item) => ({ label: item.name, value: item.id }))
            setSelectedStatus((curr) => ({ ...curr, status_list: statusList }))
        })

    }, []);

    useEffect(() => {
        setgenerateTable((prev) => !prev)


    }, [activeOrders])


    const handleFromDateChange = value => {
        const newFromDate = dayjs(value).format("YYYY-MM-DD")
        setFromDate(newFromDate)

        // Automatically set "toDate" to be the same or after "fromDate"
        if (dayjs(newFromDate).isAfter(toDate)) {
            setToDate(newFromDate)
        }
    }

    const handleToDateChange = value => {
        const newToDate = dayjs(value).format("YYYY-MM-DD")
        setToDate(newToDate)
    }

    const handleGenerateClick = () => {
        // if (hasValueChanged) {
        setgenerateTable((prev) => !prev)
        // sethasValueChanged(false);
        // }
    }

    let onGoingTypoGraphy = [
        { type: "title", children: "Dealer Product-wise Orders" },
        { type: "caption", children: "products" }
    ];


    const moveToDetails = (complaintTok) => {
        navigate(`/operations/complaints/${complaintTok}`);
    }

    const dealerNameChange = (e, val) => {
        setdealerName(val)
    }

    const handleVerticalsChange = (e) => {
        let val = e.target.value
        setSelectedVerticals((curr) => ({ ...curr, selectedVal: typeof val === 'string' ? val.split(',') : val }))
    }

    const handleStatusChange = (e) => {
        let val = e.target.value
        setSelectedStatus((curr) => ({ ...curr, selectedVal: typeof val === 'string' ? val.split(',') : val }))
    }

    const columnData = [
        {
            field: "si_no",
            headerName: "S.No",
            sortable: false,
            width: 70,
        },
        {
            field: "product_code",
            headerName: "Product Code",
            sortable: true,
            width: 150,
            // renderCell: (params) => (
            //     <span onClick={() => moveToDetails(params?.row?.token)} className={classes.product_code_text}>
            //         {params.formattedValue}
            //     </span>
            // )
        },
        {
            field: "product_name",
            headerName: "Product Name",
            sortable: true,
            width: 270,
            // renderCell: (params) => (
            //     <span onClick={() => moveToDetails(params?.row?.token)} className={classes.product_code_text}>
            //         {params.formattedValue}
            //     </span>
            // )
        },
        {
            field: "variant",
            headerName: "Variant",
            sortable: true,
            width: 120,
            // renderCell: (params) => (
            //     <span onClick={() => moveToDetails(params?.row?.token)} className={classes.product_code_text}>
            //         {params.formattedValue}
            //     </span>
            // )
        },
        {
            field: "vertical",
            headerName: "Vertical",
            sortable: true,
            width: 200,
            // renderCell: (params) => (
            //     <span onClick={() => moveToDetails(params?.row?.token)} className={classes.product_code_text}>
            //         {params.formattedValue}
            //     </span>
            // )
        },
        {
            field: "dealer_name",
            headerName: "Dealer Name",
            sortable: true,
            width: 300,
        },
        {
            field: "order_id",
            headerName: "Addison Reference No",
            sortable: false,
            width: 150,
        },
        {
            field: "order_date",
            headerName: "Order Date",
            sortable: false,
            width: 120,
        },
        {
            field: "so_number",
            headerName: "SO No.",
            sortable: false,
            width: 180,
        },
        {
            field: "dealer_reference_number",
            headerName: "Ref. No.",
            sortable: false,
            width: 180,
        },
        {
            field: "needed_date",
            headerName: "Needed Date",
            sortable: true,
            width: 120,
            // renderCell: (params) => {
            //   const parsedDate = new Date(params?.row.applied_date);
            //   const day = parsedDate.getDate().toString().padStart(2, "0");
            //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
            //   const year = parsedDate.getFullYear();
            //   return `${day}/${month}/${year}`;
            // },
            // renderCell: (params) => dateConvert(params?.row.applied_date),
        },
        // {
        //     field: "estimate_delivery_date",
        //     headerName: "EDD",
        //     sortable: false,
        //     width: 120,
        // },
        {
            field: "delivery_date",
            headerName: "Delivered Date",
            sortable: false,
            width: 200,
        },
        {
            field: "item_price",
            headerName: "Item Price",
            sortable: false,
            width: 200,
        },
        {
            field: "qty",
            headerName: "Qty",
            sortable: false,
            width: 200,
        },
        {
            field: "total_amount",
            headerName: "Total Amount",
            sortable: false,
            width: 200,
        },
        {
            field: "status",
            headerName: "Status",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                let status = '';
                switch (params.value) {
                    case 'Pending':
                        status = 'pending';
                        break;
                    case 'Order Acknowledged':
                        status = 'order_acknowledged';
                        break;
                    case 'Partially Dispatched':
                        status = 'partially_dispatched';
                        break;
                    case 'Completed':
                        status = 'dispatched';
                        break;
                    case 'Delivered':
                        status = 'delivered';
                        break;

                    default:
                        status = 'pending';
                        break;
                }
                return (
                    <StatusWidgetLable class={status}>
                        {params.value}
                    </StatusWidgetLable>
                )
            }
        },
    ]


    return (
        <>
            <Stack>
                <h6 className={classes.header_text_class}>Productwise Orders</h6>
                <Divider sx={{ marginTop: "16px", marginBottom: "35px" }} />
            </Stack>
            {/* <div className={classes.button_container}>
                <Button
                    onClick={() => {
                        if (activeOrders !== "ongoing") {
                            setSelectedStatus((curr) => ({ ...curr, selectedVal: "" }))
                            setFromDate(dayjs().format("YYYY-MM-DD"))
                            setToDate(dayjs().format("YYYY-MM-DD"))
                            setActiveOrders("ongoing")
                        }
                    }}
                    className={activeOrders === "ongoing" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Ongoing Complaints
                </Button>
                <Button
                    onClick={() => {
                        if (activeOrders !== "closed") {
                            setFromDate(dayjs().format("YYYY-MM-DD"))
                            setToDate(dayjs().format("YYYY-MM-DD"))
                            setActiveOrders("closed")
                        }
                    }}
                    className={activeOrders === "closed" ? classes.buttonOngoing : classes.button_outlined}
                    variant="outlined"
                >
                    Closed Complaints
                </Button>
            </div> */}
            <Stack direction={"row"} gap={"10px"} width={"70%"} mt={1} alignItems={"center"} justifyContent={"flex-start"} flexWrap={"wrap"}>
                <FromToDatePicker
                    label={"From Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(fromDate) || null}
                    height={"56px"}
                    onChange={handleFromDateChange}
                />
                <FromToDatePicker
                    label={"To Date"}
                    format="DD-MM-YYYY"
                    height={"56px"}
                    value={dayjs(toDate)}
                    minDate={dayjs(fromDate)}
                    onChange={handleToDateChange}
                    disabled={!fromDate}
                />
                <MultiSelectWithSearch
                    options={availableDealers}
                    value={dealerName}
                    onChange={dealerNameChange}
                    emptyLabel={"Select Dealer"}
                    borderRadius={2}
                    customStyle={{
                        marginTop: "6px"
                    }}
                />
                {/* {activeOrders === 'ongoing' && (
                    <CustomeSlecter
                        data={selectedStatus.status_list}
                        title={"Choose Status"}
                        width={210}
                        value={selectedStatus.selectedVal}
                        selectSx={{
                            borderRadius: "10px"
                        }}
                        onChange={e => setSelectedStatus((curr) => ({ ...curr, selectedVal: e.target.value }))}

                    />
                )} */}
                <MultiSelect
                    options={selectedVerticals.verticals_list}
                    title={"Choose Verticals"}
                    value={selectedVerticals.selectedVal}
                    onChange={handleVerticalsChange}
                />
                <MultiSelect
                    options={selectedStatus.status_list}
                    title={"Choose Status"}
                    value={selectedStatus.selectedVal}
                    onChange={handleStatusChange}
                />

                <CustomButton
                    customColor="white"
                    customBgColor="#376FD0"
                    custmstyle={{
                        width: "170px",
                        minWidth: "20%",
                        borderRadius: "8px",
                        maxHeight: "56px",
                        padding: "16px"
                    }}
                    onClick={handleGenerateClick}
                >
                    Generate
                </CustomButton>
            </Stack>
            <Paper sx={{ width: '100%', mt: 3 }}>
                {activeOrders === 'ongoing' && (
                    <CustomDataGrid
                        adornmentType={["search", "download_menu"]}
                        // {...(activeOrders === 'ongoing' && { otherApiRequests: () => DownloadTable() })}
                        otherApiRequests={() => DownloadTable()}
                        postAPI={true}
                        pagination
                        ApiRequestBody={{
                            "from_date": fromDate,
                            "to_date": toDate,
                            "dealer_tokens": dealerName.length > 0 ? dealerName.map((x) => x.value) : "",
                            "vertical_tokens": selectedVerticals.selectedVal.length > 0 ? selectedVerticals.selectedVal : "",
                            "status": selectedStatus.selectedVal.length > 0 ? selectedStatus.selectedVal : ""
                        }}
                        columns={columnData}
                        ApiRequest={() => FetchProductWiseLists()}
                        typography={onGoingTypoGraphy}
                        isStatusChanged={generateTable}
                        selectedTab={activeOrders}
                    />
                )}
                {/* {activeOrders === 'closed' && (
                    <>
                        <CustomDataGrid
                            adornmentType={["search"]}
                            postAPI={true}
                            pagination
                            ApiRequestBody={{
                                "from_date": fromDate,
                                "to_date": toDate,
                            }}
                            columns={columnData}
                            ApiRequest={() => FetchClosedComplaints()}
                            typography={closedComplaintsTypoGraphy}
                            isStatusChanged={generateTable}
                            selectedTab={activeOrders}
                        />
                    </>

                )} */}

            </Paper>
        </>
    )
}

export default DealerProductOrders