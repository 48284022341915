import React, { useEffect } from "react"
import HomeContant from "../../features/Home/HomeContant"
import { useNavigate } from "react-router-dom"

const Home = () => {
  return (
    <div style={{ marginTop: "23.5px" }}>
      <HomeContant />
    </div>
  )
}

export default Home
