import { TextField } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyleTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "44px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 12px) scale(1)",
    transition: "all 0.3s ease",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
  "& .MuiInputLabel-root.MuiFormLabel-filled": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
});

const CustomTextField = (props) => {
  return <StyleTextField {...props} />;
};

export default CustomTextField;
