import * as React from 'react';
import classes from './Textarea.module.css'
import { styled } from '@mui/system';

export default function MuiTextarea(props) {
  const [lengthperText, setlengthperText] = React.useState(`0/${props?.maxLength}`);

  const handleOnChange = (e) => {
    if (props?.onChange) props.onChange(e);
    if (props?.maxLength) {
      let currentValLength = e.target.value.length;
      let maxLength = Number(props.maxLength);
      const charLength = Math.min(maxLength, currentValLength);
      setlengthperText(`${charLength}/${maxLength}`)
    }
  }

  return (

    <div className={classes.textarea_box} style={props?.sx}>
      <label htmlFor=''>{props.label}</label>
      <textarea
        style={{ ...props?.textAreaSx }}
        maxLength={props.maxLength}
        placeholder={props.placeholder || "Type Message..."}
        name={props.name}
        value={props.value}
        onChange={handleOnChange} rows={props.rows || 8} cols={props.columns || 10} />
      {props.maxLength && <p className={classes.texalign_end}>{`${lengthperText}`}</p>}
    </div>

  );
}
