import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Stack, Typography } from "@mui/material";
import classes from "./Product.module.css";
import CustomeSlecter from "../../components/UI/Dropdown/CustomeSlecter";
import CustomButton from "../../components/UI/Button/Button";
// import downloadIcon from '../../../assets/image/svg/download.svg'
import searchIcon from '../../assets/image/svg/order_search.svg'
import CustomDataGrid from "../../components/UI/customDataGrid/CustomDataGrid";
import ProductServices from "./product.service";
import styled from "styled-components"
import ViewModal from '../../../dealer/components/UI/viewModal/ViewModal';

const StatusWidgetLable = styled.div.attrs(props => ({ className: classes[props.class] }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-regular);
color:#FFFFFF;
line-height:14px;
font-size:14px;
padding: 4px 10px;
border-radius: 3px;
`


const Product = () => {
  const { FetchCategories, FetchSubCategories, FetchProducts, ViewSingleProduct, DownloadTable } = ProductServices();
  const { sendRequest: getCategories } = FetchCategories();
  const { sendRequest: getSubCategories } = FetchSubCategories();
  const { sendRequest: getProducts } = FetchProducts();
  const { sendRequest: getProductDetail } = ViewSingleProduct();
  const [categoriesDet, setCategoriesDet] = useState({ categories_list: [], selectedVal: "" });
  const [subCategoriesDet, setSubCategoriesDet] = useState({ sub_categories_list: [], selectedVal: "" });
  const [hasValueChanged, sethasValueChanged] = useState(false);
  const [generateTable, setgenerateTable] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalData, setModalData] = useState({});


  useEffect(() => {
    getCategories({}, (data) => {
      setCategoriesDet((curr) => ({ ...curr, categories_list: data.data.map((cat) => ({ value: cat.token, label: cat.name })) }))

    })
  }, []);

  useEffect(() => {
    getSubCategories({
      body: {
        "category_token": categoriesDet.selectedVal
      }
    }, (data) => {
      setSubCategoriesDet({ selectedVal: "", sub_categories_list: data.data.map((subCat) => ({ value: subCat.token, label: subCat.name })) })

    })
  }, [categoriesDet.selectedVal]);

  useEffect(() => {
    sethasValueChanged(true);
  }, [categoriesDet.selectedVal, subCategoriesDet.selectedVal]);

  const handleGenerateClick = () => {
    if (hasValueChanged) {
      setgenerateTable((prev) => !prev)
      sethasValueChanged(false);
    }
  }

  const viewProductDetail = (tok) => {
    getProductDetail({
      body: {
        "product_token": tok
      }
    }, (data) => {
      console.log("det", data?.data);
      setModalData(data?.data[0])
      setModalOpen(true);
    })
  }

  const productsColumn = [
    {
      field: "si_no",
      headerName: "S.No",
      sortable: false,
      width: 70,
    },
    {
      field: "product_code",
      headerName: "Product Id",
      sortable: true,
      width: 150,
      renderCell: (params) => (
        <span onClick={() => (viewProductDetail(params.row?.token))} className={classes.product_code_text}>
          {params.formattedValue}
        </span>
      )
    },
    {
      field: "variant",
      headerName: "Variant",
      sortable: false,
      width: 90,
    },
    {
      field: "vertical",
      headerName: "Vertical",
      sortable: false,
      width: 200,
    },
    {
      field: "product_name",
      headerName: "Product Description",
      sortable: false,
      width: 300,
    },
    {
      field: "category_name",
      headerName: "Category",
      sortable: false,
      width: 150,
    },
    {
      field: "sub_category_name",
      headerName: "Sub-Category",
      sortable: false,
      width: 200,
    },
    {
      field: "amount",
      headerName: "Item Rate",
      sortable: true,
      width: 150,
      // renderCell: (params) => {
      //   const parsedDate = new Date(params?.row.applied_date);
      //   const day = parsedDate.getDate().toString().padStart(2, "0");
      //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      //   const year = parsedDate.getFullYear();
      //   return `${day}/${month}/${year}`;
      // },
      // renderCell: (params) => dateConvert(params?.row.applied_date),
    },
    {
      field: "available",
      headerName: "Availability",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <StatusWidgetLable class={params.row.status_color}>
          {params.formattedValue}
        </StatusWidgetLable>
      )
    },

  ];


  const RenderModalContent = (data) => (
    <Box>


      <div
        style={{ marginTop: "20px" }}
      >
        <span className={classes.modal_prod_id}>
          Product ID: {data?.product_code}
        </span>
        <span className={classes.modal_prod_id} style={{ marginLeft: "5px" }}>
          Variant: {data?.variant}
        </span>
        <Stack direction={"row"} justifyContent={"space-between"} mt={1}>
          <h4
            className={classes.modal_prod_desc}
          >
            {data?.name}
          </h4>
          <StatusWidgetLable class={data?.status_color}>
            {data?.status}
          </StatusWidgetLable>
        </Stack>
        <span
          className={classes.modal_prod_material_desc}
        >
          {data?.material_name} | {data?.coating_name}
        </span>
        <div className={classes.modal_dash_border} />

      </div>
      <div style={{ marginTop: "20px" }}>
        <h4
          style={{ color: "#212121", fontFamily: "var(--font-semibold)", fontWeight: "600", fontSize: "20px" }}
        >
          Item Details
        </h4>
        <Stack display={"grid"} gridTemplateColumns={"1fr 1fr"} rowGap={4} mt={3}>
          <div>
            <p className={classes.modal_prod_meta_data_header}>Category</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.category_name}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>Sub Category</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.subcategory_name}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>Material</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.material_name}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>Finish</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.coating_name}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>MOQ</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.moq}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>MRP</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.mrp_price}</p>
          </div>
          <div>
            <p className={classes.modal_prod_meta_data_header}>Item Rate</p>
            <p className={classes.modal_prod_meta_data_val}>{data?.amount}</p>
          </div>
        </Stack>

      </div>

    </Box>
  )

  return (
    <>
      <Stack>
        <h6 className={classes.header_text_class}>Product Master</h6>
        <Divider sx={{ marginTop: "16px", marginBottom: "35px" }} />
      </Stack>
      <Stack direction={"row"} gap={"10px"} width={"70%"} justifyContent={"flex-start"} flexWrap={"wrap"}>
        <CustomeSlecter
          data={categoriesDet.categories_list}
          title={"Category"}
          width={200}
          value={categoriesDet.selectedVal}
          selectSx={{
            borderRadius: "10px"
          }}
          onChange={e => setCategoriesDet((curr) => ({ ...curr, selectedVal: e.target.value }))}
        />
        <CustomeSlecter
          data={subCategoriesDet.sub_categories_list}
          title={"Sub-Category"}
          width={200}
          value={subCategoriesDet.selectedVal}
          selectSx={{
            borderRadius: "10px"
          }}
          onChange={e => setSubCategoriesDet((curr) => ({ ...curr, selectedVal: e.target.value }))}

        />
        <CustomButton
          customColor="white"
          customBgColor="#376FD0"
          custmstyle={{
            width: "170px",
            minWidth: "20%",
            borderRadius: "8px"
          }}
          onClick={handleGenerateClick}
        >
          Generate
        </CustomButton>
      </Stack>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          {/* <div className={classes.table_header_container}>
            <p className={classes.table_header_title}>Products List</p>
            <div className={classes.table_header_total_search}>
              <p className={classes.table_header_sub_title}>164 Products</p>
              <div className={classes.icons_container}>
                <img src={searchIcon} width={24} height={24} />
                <div className={classes.divider}></div>
                    <img src={downloadIcon} width={24} height={24}/>
              </div>
            </div>
          </div> */}
          <CustomDataGrid
            adornmentType={["search", "download_menu"]}
            otherApiRequests={() => DownloadTable()}
            postAPI={true}
            pagination
            ApiRequestBody={{
              "category_token": categoriesDet.selectedVal,
              "sub_category_token": subCategoriesDet.selectedVal
            }}
            columns={productsColumn}
            ApiRequest={() => FetchProducts()}
            typography={[
              { type: "title", children: "Products List" },
              { type: "caption", children: "Products" }
            ]}
            isStatusChanged={generateTable}
          />
        </Paper>

        <ViewModal
          modalOpen={modalOpen}
          trigger={setModalOpen}
          modalData={modalData}
          modalContent={RenderModalContent}
          title={"Product Details"}
        />
      </Box>
    </>
  );
};
export default Product;
