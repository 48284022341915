import { Typography } from "@mui/material"
import React from "react"
import classes from "./singleOrderEstimate.module.css"

const SingleOrderEstimate = props => {
  const { partNo, name, reqDate, vertical } = props
  return (
    <div className={classes.single_order_product_container}>
      <div className={classes.single_order_product_div}>
        <div className={classes.single_order_desc_div}>
          <div className={classes.lable_flex}>
            <h5 className={classes.single_order_no}>{partNo || "-"}</h5>
            <p className="vertical color_bule">{vertical}</p>
          </div>
          <p className={classes.single_order_specs}>{name || "-"}</p>
        </div>
        <Typography
          sx={{
            fontFamily: "var(--font-semibold)",
            color: "var(--text-color-dark)",
            fontSize: "14px",
          }}
          id="modal-modal-title"
          variant="h6"
          component="h3"
        >
          {reqDate || "-"}
        </Typography>
      </div>
      <div className={classes.single_order_under_line}></div>
    </div>
  )
}

export default SingleOrderEstimate
