import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../services/storeSlice/authSlice";
import sopeNowSlice from "../services/storeSlice/shopNowSlice";
import summaryDataSice from "../services/summarySlice/summaryDataSlice";
import stockFilterSlice from "../services/storeSlice/stockFilterSlice";
import timerReducer from "../services/storeSlice/timerSlice";
import scrollReducer from "../services/storeSlice/scrollResetSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    shopNow: sopeNowSlice,
    summaryData: summaryDataSice,
    stockData: stockFilterSlice,
    timer: timerReducer,
    scroll: scrollReducer,
  },
});

export default store;
