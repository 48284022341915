import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CustomFormControl = styled(FormControl)(({ theme, legendWidth, legendContent }) => ({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2, // Adjust the border thickness when focused
        },
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
        transform: 'translate(14px, -10px) scale(0.75)', // Adjust label transformation to create space for larger text
    },
    '& .MuiOutlinedInput-notchedOutline': {
        // Add extra space based on label text length
        '& legend': {
            width: legendWidth ? `${legendWidth}px` : `calc(${legendContent.length - 1}ch - 2px )`,// Ensure the legend matches the text width dynamically
        },
    },
}));

function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

const MultiSelect = (props) => {
    const { options, value, onChange, title = "Select", minWidth = 210, borderRadius = 2, legendWidth } = props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange?.(event);
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <CustomFormControl sx={{ m: 1, minWidth, maxWidth: "316px" }} legendContent={title}>
                <InputLabel id="select-label">{title}</InputLabel>
                <Select

                    labelId="select-label"
                    multiple
                    // displayEmpty
                    value={value}
                    // value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={options.find((x) => x.value === value).label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    sx={{
                        borderRadius,

                    }}
                >
                    {options.map((item) => (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                        // style={getStyles(name, personName, theme)}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </CustomFormControl>
        </div>
    );
}

export default MultiSelect