import { useState, useCallback } from 'react';
import axios from 'axios'; // Import Axios
import secureLocalStorage from "react-secure-storage"
import { useNavigate } from 'react-router-dom';

const useApiHttp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);
    const [errorData, setErrorData] = useState(null);
    const navigate = useNavigate();


    const sendRequest = useCallback(async (requestConfig, applyData = null) => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        try {
            const response = await axios({
                method: requestConfig.method ? requestConfig.method : 'GET',
                url: process.env.REACT_APP_BASE_API_URL + "/" + requestConfig.url,
                headers: requestConfig.headers
                    ? requestConfig.headers
                    : {
                        'Content-Type': 'application/json',
                    },
                data: requestConfig.body ? requestConfig.body : null,
                ...(requestConfig?.responseType === 'binary' ? { responseType: "blob" } : {})
            });

            const data = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                setSuccess(data?.message);
            }
            if (response?.status !== 200 && response?.status !== 201) {
                throw new Error(data?.message);
            }

            setCode(response?.status)

            applyData && applyData(data, response);

        } catch (err) {
            if (err?.response?.status === 401) {
                secureLocalStorage.removeItem('marketingBearerToken')
                navigate('/operations/login')
            }

            setCode(err?.response?.status)
            setErrorData(err?.response?.data)
            setError(err.response?.data?.message || 'Something went wrong!');
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading,
        success,
        error,
        code,
        errorData,
        sendRequest,
    };
};

export default useApiHttp;
