import { Stack, Breadcrumbs, Typography, Divider, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './myProfile.module.css'
import PersonalDetailsField from '../../components/UI/PersonalDetailsViewField/PersonalDetailsField'
import ProfileServices from './myProfile.service'

const MyProfile = () => {
    const { FetchProfileDetails } = ProfileServices();
    const { sendRequest: fetchProfileDetails } = FetchProfileDetails();
    const [profileDetails, setprofileDetails] = useState({});

    const personalDataFieldStyle = {
        color: "#212121",
        fontFamily: "var(--font-regular)",
        fontWeight: "400"
    };
    useEffect(() => {
        fetchProfileDetails(null, (data) => {
            setprofileDetails(data.data);

        })
    }, [fetchProfileDetails])


    return (
        <>
            <Stack>
                <h6 className={classes.header_text_class}>My Profile</h6>
                {/* <span style={{ color: "var(--light-gray)", fontFamily: "var(--font-regular)", fontSize: "12px", fontWeight: "400" }}><Link style={{ color: "#376FD0" }} to={"/marketing/"}>Home</Link> / My Profile</span> */}
                <Breadcrumbs sx={{ color: "var(--light-gray)", fontFamily: "var(--font-regular)", fontSize: "12px", fontWeight: "400" }} aria-label="breadcrumb">
                    <Link style={{ color: "#376FD0" }} to={"/operations/"}>Home</Link>
                    <Typography fontSize={"12px"} >My Profile</Typography>
                </Breadcrumbs>
                <Divider sx={{ marginTop: "16px" }} />
            </Stack>
            <Paper sx={{ padding: "20px", marginTop: "25px" }}>
                <h6 style={{ marginTop: "8px" }} className={classes.header_text_class}>
                    {profileDetails.name}
                </h6>
                <p style={{ color: "#B2B2B2", fontFamily: "var(--font-regular)", fontSize: "12px" }}>
                    {`+${profileDetails.country_code} ${profileDetails.mobile_number}`}
                </p>
                <Divider sx={{ borderColor: "#D8DBDD", marginTop: "16px", width: '50%', borderStyle: 'dashed', borderBottomWidth: "0.12rem", borderDashArray: '8, 4', }} />
                <Stack sx={{ marginTop: "20px" }}>
                    <h6 className={classes.prof_det_header_text}>Profile Details</h6>
                    <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr", marginTop: "inherit" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", rowGap: "25px" }}>
                            <PersonalDetailsField
                                fieldHeader={{ name: "Name", style: personalDataFieldStyle }}
                                fieldDet={{ name: profileDetails.name, style: personalDataFieldStyle }}
                            />
                            <PersonalDetailsField
                                fieldHeader={{ name: "Email", style: personalDataFieldStyle }}
                                fieldDet={{ name: profileDetails.email, style: personalDataFieldStyle }}
                            />
                            <PersonalDetailsField
                                fieldHeader={{ name: "Contact Number", style: personalDataFieldStyle }}
                                fieldDet={{ name: `+${profileDetails.country_code} ${profileDetails.mobile_number}`, style: personalDataFieldStyle }}
                            />
                            <PersonalDetailsField
                                fieldHeader={{ name: "Role/Designation", style: personalDataFieldStyle }}
                                fieldDet={{ name: profileDetails.designation, style: personalDataFieldStyle }}
                            />

                        </div>
                    </div>
                </Stack>
            </Paper>
        </>
    )
}

export default MyProfile