import React, { useEffect, useState } from 'react'

import Image from '../../components/UI/Image/Image'
// import menu from '../../assets/PNG/menu.png'
// import logo from '../../assets/SVG/Dashboard/Logo.svg'
import logo from '../../assets/image/png/nav_menu_logo@2x.png'
import { Link, NavLink } from 'react-router-dom'
import classes from './sidebar.module.css'
import productMaster from '../../assets/image/svg/product master.svg'
import dealerMaster from '../../assets/image/svg/dealer master.svg'
import orderManagement from '../../assets/image/svg/order management.svg'
import complaintManagement from '../../assets/image/svg/complaint management.svg'
import inventoryMaster from '../../assets/image/svg/inventory master.svg'
import dealerOrders from '../../assets/image/svg/dealer orders.svg'
import { Box } from '@mui/material'


const Sidebar = (props) => {

    const [sideMenuItems, setSideMenuItems] = useState([
        {
            redirect_url: "product",
            name: "Product Master",
            active_image: productMaster,
            inactive_image: productMaster

        }, {
            redirect_url: "inventory",
            name: "Inventory Master",
            active_image: inventoryMaster,
            inactive_image: inventoryMaster

        }, {
            redirect_url: "dealer_list",
            name: "Dealer Master",
            active_image: dealerMaster,
            inactive_image: dealerMaster

        }, {

            redirect_url: "product_orders",
            name: "Dealer Product Orders",
            active_image: dealerOrders,
            inactive_image: dealerOrders

        },
        {
            redirect_url: "order_management",
            name: "Order Management",
            active_image: orderManagement,
            inactive_image: orderManagement

        }, {
            redirect_url: "complaints",
            name: "Complaint Management",
            active_image: complaintManagement,
            inactive_image: complaintManagement

        }
    ]);

    // console.log("menuItems", sideMenuItems);





    return (
        <section id={classes.sidebar}>
            {/* <div><img onClick={onClick} src={"menu"} alt="menu" style={{ width: '31px' }} className='close' /></div> */}
            <Box sx={{ textAlign: "center", padding: "10px" }}>

                <Link to={"/operations/"}>
                    <Image width={"120px"} height={"65px"} src={logo} alt="logo" className='brand_img' />

                </Link>
            </Box>

            <h5 className={classes.sidebar_dasboard_text}>ADDISON MANAGEMENT</h5>

            <div className={classes.sidebar_links_container}>



                {sideMenuItems.map((sidebar, index) => (
                    <NavLink to={`/operations/${sidebar.redirect_url}`} key={index} exact={true}>
                        {({ isActive }) => (

                            <div className={`${classes.sidebar_text_container} ${isActive ? classes.active : ""}`}>


                                <img src={isActive ? `${sidebar?.active_image}` : `${sidebar?.inactive_image}`} alt={sidebar.name} />
                                <span className="text">{sidebar.name}</span>
                            </div>


                        )}
                    </NavLink>

                ))}
            </div>




        </section>
    )
}

export default Sidebar