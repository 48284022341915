import React, { useState } from "react"
import Popup from "../../Modal/Modal"
import classes from "./LightboxImgPdf.module.css"

const FileViewerLightbox = ({ fileUrl }) => {
  const [isOpen, setIsOpen] = useState(false)

  // Check if the file is an image
  const isImageFile = url => {
    return /\.(jpeg|jpg|gif|png|bmp)$/i.test(url)
  }

  // Check if the file is a document (PDF)
  const isDocumentFile = url => {
    return /\.(pdf)$/i.test(url)
  }

  const handleOpenLightbox = () => {
    setIsOpen(true)
  }

  const handleCloseLightbox = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <button className={classes.view_btn} onClick={handleOpenLightbox}>
        View Attachment
      </button>

      {/* {isOpen && isImageFile(fileUrl) && (
        <Lightbox mainSrc={fileUrl} onCloseRequest={handleCloseLightbox} />
      )} */}
      {isOpen && isImageFile(fileUrl) && (
        <div className={classes.modal} onClick={handleCloseLightbox}>
          <span className={classes.close}>&times;</span>
          <img
            className={classes["modal-content"]}
            src={fileUrl}
            alt={"imge"}
          />
        </div>
      )}

      {isOpen && isDocumentFile(fileUrl) && (
        <Popup
          open={isOpen}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "90vh",
            maxWidth: "1000px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
          height={{ height: "89%" }}
          onClose={handleCloseLightbox}
        >
          <iframe
            style={{ height: "76vh" }}
            src={fileUrl}
            width="100%"
            height="100%"
            title="PDF Document"
          />
        </Popup>
      )}
    </div>
  )
}
export default FileViewerLightbox
