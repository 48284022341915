import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const ComplaintServices = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const FetchOngoingComplaints = (type) =>
        useApiHttp({
            url: `marketing/complaint-list/ongoing`,
            method: "POST",
        });

    const FetchClosedComplaints = () =>
        useApiHttp({
            url: `marketing/complaint-list/closed`,
            method: "POST",
        });

    const ViewComplaints = () =>
        useApiHttp({
            url: `marketing/complaint-view`,
            method: "POST",
            // onSuccess: (data) => {
            //     toast.success((data.message || 'Success!'), {
            //         position: "top-center"
            //     });
            //     dispatch(login(data.data));
            //     navigate("/marketing/product");
            // }
            onError: () => {
                navigate('/operations/complaints')
            }
        });
    const ComplaintStatusUpdate = () =>
        useApiHttp({
            url: `marketing/complaint-view/update-status`,
            method: "POST",
            onSuccess: (data) => {
                toast.success((data.message || 'Success!'), {
                    position: "top-center"
                });
            }

        });

    const DownloadTable = () =>
        useApiHttp({
            url: `marketing/complaint-list/ongoing/download`,
            method: "POST",
            responseType: "binary",

        });



    return {
        FetchOngoingComplaints,
        FetchClosedComplaints,
        ViewComplaints,
        ComplaintStatusUpdate,
        DownloadTable
    }

}

export default ComplaintServices