import React, { useEffect, useState } from "react"
import {
  Button,
  TextField,
  Typography,
  Modal,
  Box,
  Stack,
  Input,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Radio,
  Autocomplete,
} from "@mui/material"
import classes from "./ComplaintModal.module.css"
import UploadIcon from "../../../components/UI/svgComponents/UploadIcon"
import CheckBox from "../../../components/UI/Checkbox/CheckBox"
import MuiTextarea from "../../../components/UI/Inputs/TextArea/TextAreaInput"
import useApiHttp from "../../../hooks/ues-http"
import close from "../../../assets/image/svg/remove.svg"
import tick from "../../../assets/image/svg/tick.svg"
import { uploadFile } from "../../../utils/file-upload"
import { toast, ToastContainer } from "react-toastify"
import Loding from "../../../components/UI/Loding/Loding"

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "550px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
  borderRadius: "8px",
  maxHeight: "95vh",
  overflowY: "auto",
}

const ComplaintModal = ({
  open,
  handleClose,
  onConfirm,
  onClose,
  po_number = "",
}) => {
  const [poNumber, setPoNumber] = useState(po_number ? po_number : "")
  console.log("poNumber", poNumber)

  const [orderData, setOrderData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [complaintDetails, setComplaintDetails] = useState("")
  const [productList, setProductList] = useState([])
  const [selectedFile, setSelectedFile] = useState(null) // Store the selected file
  const [errorFileType, setErrorFileType] = useState("") // Store any error related to file type
  const [fileUplodLoding, setFileUplodLoding] = useState(false) // Store any error related to file type
  const [imagePreview, setImagePreview] = useState("") // For image preview
  const [inputError, setInputError] = useState("")

  const orderOptions = orderData.map(order => order.order_id)

  const complaintOptions = {
    // Commercial: ["Short supply", "Rate issue", "Wrong Item"],
    Commercial: ["Rate issue", "Wrong Item"],
    Technical: ["Broken", "Performance Issue"],
  }
  const [complaintType, setComplaintType] = useState(
    Object.keys(complaintOptions)[0]
  ) // Default to the first type
  const [complaintReason, setComplaintReason] = useState("")

  const {
    isLoading: complaintListLoading,
    success: complaintListSucces,
    error: complaintListError,
    sendRequest: complaintListRequest,
  } = useApiHttp()
  const {
    isLoading: createcomplaintLoading,
    success: createcomplaintSucces,
    error: createcomplaintError,
    sendRequest: createcomplaintRequest,
  } = useApiHttp()
  // raise - complaint / order - ids
  const {
    isLoading: raisecomplaintLoading,
    success: raisecomplaintSucces,
    error: raisecomplaintError,
    sendRequest: raisecomplaintRequest,
  } = useApiHttp()

  const complaintListRequestHandle = number => {
    complaintListRequest(
      {
        url: `dealer/raise-complaint/order/products`,
        method: "POST",
        body: {
          order_id: number,
        },
      },
      data => {
        setProductList(data.data)
      }
    )
  }

  const raisecomplaintRequestdata = () => {
    raisecomplaintRequest(
      {
        url: `dealer/raise-complaint/order-ids`,
        method: "POST",
      },
      data => {
        setOrderData(data.data)
        console.log("complaint", data.data)
      }
    )
  }

  useEffect(() => {
    if (poNumber) {
      complaintListRequestHandle(poNumber)
    }
  }, [poNumber])

  useEffect(() => {
    raisecomplaintRequestdata()
  }, [])

  useEffect(() => {
    if (complaintListError !== "") {
      toast.error(complaintListError, {})
    }
  }, [complaintListError])

  // Function to handle checkbox toggle
  const handleCheckboxToggle = item => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem !== item)
      )
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }
  const handleTextareaChange = event => {
    if (event && event.target) {
      setComplaintDetails(event.target.value)
    }
  }

  const handleComplaintTypeChange = event => {
    setComplaintType(event.target.value)
    setComplaintReason("") // Reset complaint reason when type changes
  }

  const handleComplaintReasonChange = event => {
    setComplaintReason(event.target.value)
  }

  const handleFileChange = e => {
    const file = e.target.files[0] // Get the first selected file

    if (file) {
      // Allowed types: jpeg, png, jpg, pdf
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ]

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        setErrorFileType(
          "Invalid file type! Please select a JPEG, PNG, or PDF."
        )
        setSelectedFile(null)
        setImagePreview("")
        return
      }

      // Check if the file size is less than or equal to 2MB
      const maxSize = 2 * 1024 * 1024 // 2MB in bytes
      if (file.size > maxSize) {
        setErrorFileType("File size exceeds 2MB! Please select a smaller file.")
        setSelectedFile(null)
        setImagePreview("")
        return
      }

      // If everything is valid, set the file and clear the error
      setSelectedFile(file)
      setErrorFileType("")

      // If it's an image, create a preview
      if (file.type.startsWith("image/")) {
        const reader = new FileReader()
        reader.onload = e => {
          setImagePreview(e.target.result) // Set the preview URL
        }
        reader.readAsDataURL(file)
      } else {
        // If it's a PDF, clear any previous image preview
        setImagePreview("pdf")
      }
    }
  }
  const createcomplaintRequestHandle = data => {
    createcomplaintRequest(
      {
        url: `dealer/raise-complaint/create`,
        method: "POST",
        body: data,
      },
      data => {
        if (data.status_code === 200) {
          setImagePreview("")
          setSelectedItems([])
          setPoNumber(po_number ? po_number : "")
          setComplaintDetails("")
        }
      }
    )
  }

  useEffect(() => {
    if (createcomplaintSucces !== "") {
      toast.success(createcomplaintSucces, {})
    }
    if (createcomplaintError !== "") {
      toast.error(createcomplaintError, {})
    }
  }, [createcomplaintSucces, createcomplaintError])

  const handleSubmit = async () => {
    // if (!poNumber.trim()) {
    //   setInputError("Please provide a valid Order number.")
    //   return
    // }
    if (!poNumber) {
      setInputError("Please provide a valid Order number.")
      return
    }
    if (!complaintReason.trim()) {
      setInputError("Complaint details cannot be empty.")
      return
    }

    if (selectedItems.length === 0) {
      setInputError("Please select at least one product.")
      return
    }

    if (!selectedFile) {
      setInputError("Please upload a file.")
      return
    }

    try {
      if (selectedFile) {
        setFileUplodLoding(true)
        var uploadResponse = await uploadFile(selectedFile)
      }

      setFileUplodLoding(false)
      // If file upload is successful, you can proceed with further form submission
      const formData = {
        order_id: poNumber,
        order_detail_tokens: selectedItems,
        description: complaintDetails,
        type: complaintType,
        subtype: complaintReason,
        attachment: uploadResponse || "",
      }
      createcomplaintRequestHandle(formData)
      setTimeout(() => {
        onConfirm && onConfirm()
      }, 3000)
    } catch (error) {
      console.error("Error during file upload:", error)
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose ? onClose : false}
        aria-labelledby="raise-new-complaint-title"
        aria-describedby="raise-new-complaint-description"
      >
        <Box sx={modalStyle}>
          <Typography
            sx={{
              fontFamily: "var(--font-bold)",
              color: "#000000",
              fontSize: "24px",
              textAlign: "center",
              mb: 1,
            }}
            id="raise-new-complaint-title"
            variant="h6"
            component="h2"
          >
            Raise New Complaint
          </Typography>
          {po_number ? (
            <TextField
              label="Order Number"
              value={poNumber}
              onChange={e => setPoNumber(e.target.value)} // Updating state with input value
              disabled={po_number ? true : false}
              color="primary"
              fullWidth
              sx={{
                mt: 2, // Adding margin-top to space the element
                "& .MuiOutlinedInput-root": {
                  height: "40px", // Adjust the height of the entire input field
                  padding: "0px", // Remove default padding
                },
                "& .MuiInputLabel-root": {
                  fontSize: "14px", // Adjust font size of the label if needed
                },
                "& .MuiInputBase-input": {
                  padding: "8px 10px", // Set padding inside the input area
                  fontSize: "14px", // Adjust the font size for the input text
                },
              }}
              InputLabelProps={{
                shrink: true, // Optional: If you want the label to always appear above the input
              }}
            />
          ) : (
            <Autocomplete
              disablePortal
              options={orderOptions}
              value={poNumber}
              onChange={(event, newValue) => setPoNumber(newValue)}
              renderInput={params => (
                <TextField {...params} label="Addison Reference No" />
              )}
            />
          )}
          {complaintListSucces && (
            <>
              <Typography
                id="raise-new-complaint-description"
                sx={{
                  mt: 2,
                  mb: 1,
                  fontFamily: "var(--font-Medium)",
                  color: "#68686A",
                  fontSize: "12px",
                }}
              >
                Choose Products
              </Typography>
              <div className={classes.check_box}>
                {productList.map((item, index) => (
                  <CheckBox
                    key={index}
                    label={
                      <>
                        {item.product_code}{" "}
                        <span style={{ fontFamily: "var(--font-regular)" }}>
                          (Variant: {item.variant})
                        </span>
                      </>
                    }
                    subLabel={item.name}
                    checked={selectedItems.includes(item.token)}
                    onChange={() => handleCheckboxToggle(item.token)}
                  />
                ))}
              </div>
            </>
          )}
          <div className={classes.flex_container}>
            {/* <MuiTextarea
              sx={{ width: "100%" }}
              label="Complaint Details"
              value={complaintDetails}
              onChange={handleTextareaChange} // Correctly handles the value update
              // maxLength={200} // Example max length
            /> */}
            {/* Radio buttons for complaint type */}
            <div style={{ minWidth: "255px" }}>
              <RadioGroup
                value={complaintType}
                onChange={handleComplaintTypeChange}
                style={{ flexDirection: "row", fontSize: "13px" }}
              >
                {Object.keys(complaintOptions).map(type => (
                  <FormControlLabel
                    key={type}
                    value={type}
                    control={<Radio />}
                    label={type}
                    style={{ fontSize: "12px" }}
                  />
                ))}
              </RadioGroup>

              {/* Dropdown for complaint reason based on selected type */}
              <Select
                fullWidth
                value={complaintReason}
                onChange={handleComplaintReasonChange}
                displayEmpty
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Complaint Details
                </MenuItem>

                {complaintOptions[complaintType].map(reason => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>

            </div>

            <div style={{ width: "363px" }}>
              <Box className={classes.upload_box}>
                {!imagePreview ? (
                  <label htmlFor="file-upload">
                    <div className={classes.upload_input}>
                      <UploadIcon />
                      <p>Upload Your Attachment (.jpg, .png, .jpeg, .pdf)</p>
                      <Input
                        type="file"
                        id="file-upload"
                        accept=".jpg, .png, .jpeg, .pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                  </label>
                ) : (
                  <div className={classes.imagePreview}>
                    <img
                      src={close}
                      alt="close"
                      className={classes.imagePreview_close}
                      onClick={() => {
                        setImagePreview("")
                        setSelectedFile(null)
                      }}
                    />
                    {/* Conditionally render image preview or PDF icon */}
                    {selectedFile && selectedFile.type.startsWith("image/") ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <div className={classes.pdfPreview}>
                        <img
                          src={tick} // Use a PDF icon if available
                          alt="PDF Preview"
                          style={{
                            width: "60px",
                            maxWidth: "200px",
                            height: "auto",
                          }}
                        />
                        <p>Fille uploaeded successfully.</p>
                      </div>
                    )}
                  </div>
                )}

                {fileUplodLoding && <Loding />}
              </Box>
              {/* Always show the uploaded file name */}
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Uploaded file: {selectedFile.name}
                </Typography>
              )}
              {errorFileType && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {errorFileType}
                </Typography>
              )}
            </div>
          </div>
          {inputError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {inputError}
            </Typography>
          )}
          <MuiTextarea
            sx={{ width: "100%", marginTop: "45px" }}
            label="Complaint Remarks"
            value={complaintDetails}
            onChange={handleTextareaChange} // Correctly handles the value update
          // maxLength={200} // Example max length
          />
          {/* Action Buttons */}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                color: "#68686A",
                fontSize: "14px",
                fontFamily: "var(--font-bold)",
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleClose()
                setImagePreview("")
                setSelectedItems([])
                setPoNumber(po_number ? po_number : "")
                setComplaintDetails("")
                setInputError("")
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                color: "#32349B",
                fontSize: "14px",
                fontFamily: "var(--font-bold)",
                textTransform: "capitalize",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  )
}

export default ComplaintModal
