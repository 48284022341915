import React, { useState } from "react"
import classes from "./OngoingOrders.module.css"
import OnGoingOrdersCard from "./OnGoingOrdersCard"

const OngoingOrders = () => {
  const [value, setValue] = useState(0)
  return (
    <div className={classes.ongingOrder}>
      <p className={classes.title}>Ongoing Orders</p>
      <p className={classes.subTitle}>{value.length} Orders</p>
      <div className={classes.recentOrder}>
        <OnGoingOrdersCard setValue={setValue} />
      </div>
    </div>
  )
}

export default OngoingOrders
