import React from "react"
import classes from "./RsizewindowAlert.module.css"
import logo from "../../src/modules/dealer/assets/image/png/Logowindow.png"
import baner from "../../src/modules/dealer/assets/image/png/Web Mockup@2x.png"
const RsizewindowAlert = () => {
  return (
    <div className={classes.window_alert}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={classes.baner_logo}>
        <img src={baner} alt="baner" />
      </div>
      <div className={classes.contant}>
        <p className={classes.titel}>ADOS - Addison Dealer Ordering System</p>
        <p className={classes.sub_titel}>
          This website is optimized for larger screens only and is{" "}
          <b>not compatible with mobile devices or smaller screens.</b> For the
          best experience, please access it one a computer.
        </p>
      </div>
    </div>
  )
}

export default RsizewindowAlert
