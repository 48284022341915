import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledEngineProvider } from '@mui/material/styles';


const MultiSelectWithSearch = (props) => {
    const { value, options, emptyLabel = "Select", minWidth = 200, borderRadius = 0 } = props;
    return (

        <Autocomplete
            multiple
            disableCloseOnSelect
            options={options}
            value={value}
            onChange={(event, newValue) => {
                //   setValue(newValue);
                props?.onChange?.(event, newValue)
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label && option.value === value.value} // Custom equality check
            id='search-select'
            sx={{
                minWidth: minWidth,
                ...(props?.customStyle || {}),
                maxWidth: "416px",
                "& fieldset": {
                    borderRadius: borderRadius
                }
            }}
            renderInput={(params) => <TextField {...params} label={emptyLabel} />}
        />
    )
}

export default MultiSelectWithSearch