import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updatesummarydata: []
};
const summaryDataSice = createSlice({
  name: "summaryData",
  initialState,
  reducers: {
    updateSummary: (state, action) => {
      state.updatesummarydata = action.payload;
    },
  },
});

export const { updateSummary } = summaryDataSice.actions;

export default summaryDataSice.reducer;
