import React from "react"
import classes from "./FooterBanner.module.css"
import CustomButton from "../../components/UI/Button/Button"
import { useNavigate } from "react-router-dom"
const FooterBanner = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.footer}>
      <div className={classes.footer_box}>
        <h3 className={classes.footer_box_titel}>
          Have grievances or issues with the product/orders?
        </h3>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{
            padding: "2px 12px",
            with: "114px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
          onClick={() => navigate("/raisecomplaint")}
        >
          Get Support
        </CustomButton>
      </div>
    </div>
  )
}

export default FooterBanner
