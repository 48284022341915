import React, { useState } from 'react';

const FileViewer = ({ fileUrl }) => {

    // Function to check if the file is an image
    const isImageFile = (url) => {
        return /\.(jpeg|jpg|gif|png|bmp)$/i.test(url);
    };

    // Function to check if the file is a document (e.g., PDF)
    const isDocumentFile = (url) => {
        return /\.(pdf|doc|docx)$/i.test(url);
    };


    return (
        <>

            <div>
                {isImageFile(fileUrl) && (
                    <img
                        src={fileUrl}
                        alt="Image Preview"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                )}

                {isDocumentFile(fileUrl) && (
                    <iframe
                        src={fileUrl}
                        title="Document Viewer"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                    />
                )}

                {!isImageFile(fileUrl) && !isDocumentFile(fileUrl) && (
                    <p>Unsupported file type</p>
                )}
            </div>

        </>
    );
};
export default FileViewer; 